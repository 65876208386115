import React, { Component } from 'react'
import LoadMask from 'Utils/LoadMask/LoadMask';
import FincaForm from './FincaForm';
import CardEmpty from '../../../../Utils/Cards/CardEmpty';
import CardFormNoBorder from '../../../../Utils/Cards/CardFormNoBorder';
import Tabs, { TabPane } from 'rc-tabs';
import FincaAnnualProjections from './AnnualProjections/AnnualProjectionsFinca'
import ProductionLatexTableAnnual from './AnnualProjections/ProductionLatexTableAnnual';
import ProjectionsLatexTableAnnual from './AnnualProjections/ProjectionsLatexTableAnnual';
export default class FincaEditSummary extends Component {

    state = {
        open: false,
    };
    componentDidMount() {
        if (this.props.tab === 'PROYECCIONES_ANUALES') {
            this.props.summary_annual_list()
        } else {
            this.props.getDollar()
            this.props.read()

        }
    }
    openModalCategory = () => {

        this.setState({ open: true });

    };
    closeModalCategory = () => {
        this.setState({ open: false });

    };

    actualizar = (data) => {
        this.props.editar(1, data);
    };

    getPermissions = () => {
        const { getMe } = this.props;
        getMe();
    }

    updateAnnual = (data) => {
        this.props.edit_annual(data);
    };

    render() {
        const { tab, setTab } = this.props;
        const { updateData, loader, item } = this.props;


        return (
            <div className="mb-4 col-12">
                <CardFormNoBorder
                    icon="Side_activos/finca.svg"
                    titulo="PRESUPUESTO DE FINCA"
                    subtitulo="RESUMEN"
                    subtituloForm={"VER"}
                    col="12"
                    noShadow
                >

                </CardFormNoBorder>
                <Tabs
                    defaultActiveKey={tab}
                    onChange={tab => setTab(tab)}
                    animated={{ tabPane: true, inkBar: true }}
                >
                    <TabPane tab='EN CURSO' key="EN_CURSO">
                        <LoadMask loading={loader} blur>
                            <FincaForm
                                onSubmit={this.actualizar}
                                updateData={updateData}
                                item={item}
                                editando={true}
                                permisos={this.getPermissions}
                                openModal={this.openModalCategory}
                                closeModal={this.closeModalCategory}
                                open={this.state.open}
                                {...this.props}
                            />
                        </LoadMask>
                    </TabPane>
                    <TabPane tab='PROYECCIONES ANUALES' key="PROYECCIONES_ANUALES">
                        <CardEmpty noShadow
                            isMarginButtom>
                            <div className="col-md-3 p-0 mt-2">
                                <div className="md-12">
                                    <h6 className="mb-4"><strong>KILOGRAMOS PROYECTADOS</strong></h6>
                                </div>
                            </div>
                            <div className='mb-4'>
                                <FincaAnnualProjections
                                    {...this.props}
                                    onSubmit={this.updateAnnual}
                                />
                            </div>
                        </CardEmpty>
                        <CardEmpty noShadow isMarginButtom>
                            <div className="col-md-3 p-0 mt-2">
                                <div className="md-12">
                                    <h6 className="mb-4"><strong>PRESUPUESTO DE PRODUCCIÓN DE FINCA</strong></h6>
                                </div>
                            </div>
                            <ProductionLatexTableAnnual {...this.props} />
                        </CardEmpty>
                        <CardEmpty noShadow isMarginButtom>
                            <div className="col-md-3 p-0 mt-2">
                                <div className="md-12">
                                    <h6 className="mb-4"><strong>CRECIMIENTO PRODUCCIÓN DE FINCA</strong></h6>
                                </div>
                            </div>
                            <ProjectionsLatexTableAnnual {...this.props} onSubmit={this.updateAnnual}/>
                        </CardEmpty>
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}

