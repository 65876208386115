import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm } from 'redux-form';
import { renderField } from '../../../../Utils/renderField/renderField';
let PortForm = props => {
    const { handleSubmit } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="d-flex justify-content-center">
                    <br />
                    <br />
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="name" className="m-0">NOMBRE</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="name" component={renderField} type="text" placeholder='Nombre' className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="address" className="m-0">DIRECCIÓN</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="address" component={renderField} type="text" placeholder='Dirección' className="form-control" />
                        </div>
                    </div>
                </div>

                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/ports">CANCELAR</Link>

                    <button type="submit" className="btn btn-primary">GUARDAR</button>
                </div>
            </div>
        </form>
    )
};
PortForm = reduxForm({
    form: 'EditarPortForm',
    validate: data => {
        return validate(data, {
            'name': validators.exists()('Campo requerido.'),
            'address': validators.exists()('Campo requerido.'),
        })
    }
})
    (PortForm);
export const PortUpdateForm = reduxForm({
    form: 'EditarPortForm',
    validate: data => {
        return validate(data, {
            'name': validators.exists()('Campo requerido.'),
            'address': validators.exists()('Campo requerido.'),
        })
        
    },
    asyncBlurFields: []
})(PortForm);

export default PortForm
