import { connect } from 'react-redux';
import {actions} from '../../../../../../redux/modules/admin/ports';
import {getMe} from "../../../../../../redux/modules/cuenta/login";
import CreatePort from './CreatePort';

const mstp = state => {
    return {...state.ports}
};

const mdtp = {
    ...actions, getMe
};

export default connect(mstp, mdtp)(CreatePort)
