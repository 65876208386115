import { connect } from 'react-redux';
import { actions } from '../../../../../../redux/modules/debt_management/debt_management';
import DeptManagementSummary from "./SummaryDeptManagement";


const ms2p = (state) => {
  return {
    ...state.debt_management,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(DeptManagementSummary);
