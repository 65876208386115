import React from 'react';
import { Field, FieldArray } from 'redux-form';
import { renderDollar, renderFieldDisabled, renderDollarDisabled } from '../../../../../Utils/renderField/renderField';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { months } from '../../../../../Utils/months';
import { isArray } from 'lodash';

const TYPE_OF_ROW_MAPPING = {
    AUTHORIZED: 10,
    AVAILABLE: 11,
    INITIAL_BALANCE: 12,
    REPAYMENTS: 13,
    FINAL_BALANCE: 14,
    INCREASE: 15,
};

const TableRow = ({ system, index, fields, data }) => {
    const isEditRow = [TYPE_OF_ROW_MAPPING.REPAYMENTS, TYPE_OF_ROW_MAPPING.INCREASE].includes(fields.get(index).type_of_row);
    if (data.years_list && isArray(data.years_list) && data.years_list.length === 0) {
        return <div>
            <h1> Cargando data</h1>
        </div>
        }
    return (
        <React.Fragment key={index}>
            <tr key={`system-${index}`}
                style={isEditRow ? { backgroundColor: "#F0FFC7" } : {}}
            >
                <td className="text-center sin-borde-top" style={{ width: "15%" }}>
                    <Field
                        name={`${system}.name`}
                        type="text"
                        component={renderFieldDisabled}
                        className="form-control"
                        placeholder='Nuevo'
                    />
                </td>
                {data.years_list.map((year) => (
                    <td key={year} style={{ width: "17%" }}>
                        <Field
                            name={`${system}.${year}`}
                            type="text"
                            component={isEditRow ? renderDollar : renderDollarDisabled}
                            className="form-control"
                            placeholder='$ 0.00'
                        />
                    </td>
                ))}
            </tr>
        </React.Fragment>
    );
};

const GenericTable = ({ fields, data  }) => {
    const calculateTotalForYear = () => {
        const allFields = fields.getAll() || [];

        // Encontrar las filas relevantes
        const authorizedRow = allFields.find(row => row.type_of_row === TYPE_OF_ROW_MAPPING.AUTHORIZED);
        const initialBalanceRow = allFields.find(row => row.type_of_row === TYPE_OF_ROW_MAPPING.INITIAL_BALANCE);
        const repaymentsRow = allFields.find(row => row.type_of_row === TYPE_OF_ROW_MAPPING.REPAYMENTS);
        const finalBalanceRow = allFields.find(row => row.type_of_row === TYPE_OF_ROW_MAPPING.FINAL_BALANCE);
        const availableRow = allFields.find(row => row.type_of_row === TYPE_OF_ROW_MAPPING.AVAILABLE);
        const increaseRow = allFields.find(row => row.type_of_row === TYPE_OF_ROW_MAPPING.INCREASE);

        // Iterar sobre los meses para calcular los valores
        data.years_list.forEach((year, index) => {
            if (authorizedRow && initialBalanceRow && repaymentsRow && finalBalanceRow && availableRow && increaseRow) {
                const authorized = parseFloat(authorizedRow[year]) || 0;
                const initialBalance = parseFloat(initialBalanceRow[year]) || 0;
                const repayments = parseFloat(repaymentsRow[year]) || 0;
                const increase = parseFloat(increaseRow[year]) || 0;

                const finalBalance = initialBalance - repayments;

                // Si es el primer año, el saldo inicial es el ingresado por el usuario.
                if (index !== 0) {
                    // Para los demás meses, INITIAL_BALANCE = FINAL_BALANCE del mes anterior + INCREASE del mes anterior
                    const previousYear = data.years_list[index - 1];
                    initialBalanceRow[year] = (parseFloat(finalBalanceRow[previousYear]) || 0) + (parseFloat(increaseRow[previousYear]) || 0);
                    availableRow[year] = authorized - initialBalanceRow[year];

                } else {
                    availableRow[year] = authorized - initialBalance;
                }

                finalBalanceRow[year] = finalBalance;
            }
        });

    };
    const columnas = data.years_list && Array.isArray(data.years_list) && data.years_list.length > 0 ? (
        data.years_list.map((year, index) => (
            <th key={index + 1} style={{ width: "17%" }}>
                {year}
            </th>
        ))
    ) : null;

    if (data.years_list && Array.isArray(data.years_list) && data.years_list.length > 0){
        <div>
            <h5> Cargando data</h5>
        </div>
    }

    data.years_list.map((year, idx) => (
        calculateTotalForYear(year)
    ));

    return (
        <div className=" col-sm-12 form-group np-r p-0">
            <div className='scroll-container'>
                <div className='scroll-content-annual'>
                    <div className="react-bs-container-header table-header-wrapper">
                        <table className="table mb-0 text-center">
                            <thead>
                                <tr>
                                    <th style={{ width: "15%" }}>MES</th>
                                    {columnas ? columnas : <th colSpan="5">No hay datos disponibles</th>}
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="tabla-con-borde">
                        <table className="table table-sm  m-0 table-striped">
                            <tbody style={{ backgroundColor: "#F0FFC7" }}>
                                {fields.map((system, index) =>
                                    <TableRow
                                        key={index}
                                        system={system}
                                        index={index}
                                        fields={fields}
                                        data={data}
                                    />)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

const CreditLineTable = ({ fieldName, debtManagement, data }) => {
    return (
        <div>
            <FieldArray
                name={`${fieldName}`}
                component={GenericTable}
                debtManagement={debtManagement}
                data= {data}
            />
        </div>
    );
}

const formName = 'AnnualDebtManagementForm';


const mapStateToProps = (state) => ({
    debtManagement: formValueSelector(formName)(state, 'details') || [],
});

export default connect(mapStateToProps)(CreditLineTable);                                                                                        