import React, { Component } from 'react';
import Card from 'Utils/Cards/Card';
import Listado from './Listado';
import ListadoAnnual from './ListadoAnnual';
import VatDragModal from './Modal/VatDragModal';
import Tabs, { TabPane } from "rc-tabs";
import CardEmpty from '../../../../Utils/Cards/CardEmpty';

class TaxCreditListSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            initialValues: {}
        };
    }


    componentDidMount() {
        if (this.props.tab === 'EN_CURSO') {
            this.props.read();
        } else {
            this.props.readAnnual();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.tab !== prevProps.tab) {
            if (this.props.tab === 'EN_CURSO') {
                this.props.read();
            } else {
                this.props.readAnnual();
            }
        }
    }

    openModal = () => {

        this.setState({ open: true });

    };

    closeModal = () => {
        this.setState({ open: false });

    };
    actualizarModal = (data) => {
        this.props.editarModal(data.id, data)
        this.closeModal()
    }

    openAndConsultModal = () => {
        this.props.getVatDragConfig();
        this.openModal()
    }


    render() {
        const { data, loader, readShow, tab, setTab } = this.props;

        return (
            <div className="mb-4 col-12">
                <Card
                    noShadow
                    icon="Side_activos/creditofiscal.svg"
                    titulo="CREDITO FISCAL"
                    subtitulo="RESUMEN"
                    noBorder
                >
                    <Tabs
                        defaultActiveKey={tab}
                        activeKey={tab}
                        onChange={tab => setTab(tab)}
                        animated={{ tabPane: true, inkBar: true }}
                    >

                        <TabPane tab="EN CURSO" key="EN_CURSO">
                            <CardEmpty noShadow>
                                <div className="row col-md-12 p-0 mb-4 mt-4">
                                    <div className="col-md-6">
                                        <h4 className="mr-2 titulo_principal">CREDITO FISCAL</h4>
                                    </div>
                                    <div className="col-md-6 d-grid gap-2 d-md-flex justify-content-md-end">
                                        <button className="btn btn-light btn-sm justify-content-end agregar-tab-generica" onClick={() => this.openAndConsultModal()} >CONFIGURACIÓN</button>
                                    </div>
                                </div>
                                <Listado data={data} loader={loader} readShow={readShow} {...this.props} />
                            </CardEmpty>
                        </TabPane>

                        <TabPane tab="PROYECCIONES ANUALES" key="PROYECCIONES_ANUALES">
                            <CardEmpty
                                noShadow
                            >
                                <div className="row col-md-12 p-0 mb-4 mt-4">
                                    <div className="col-md-6">
                                        <h4 className="mr-2 titulo_principal">CREDITO FISCAL</h4>
                                    </div>
                                </div>
                                <ListadoAnnual data={data} loader={loader} readShow={readShow} {...this.props} />
                            </CardEmpty>
                        </TabPane>
                    </Tabs>

                </Card>
                <VatDragModal
                    onSubmit={this.actualizarModal}
                    open={this.state.open}
                    dollarExchange={data.dollar_exchange}
                    closeModal={this.closeModal}
                    change={this.props.change}
                    {...this.props}
                />
            </div>
        );
    }
}

export default TaxCreditListSummary;
