import React from 'react';
import { months } from '../../../../../../Utils/months';

const LatexTable = props => {
    const rowDescriptionClassName = "text-left table-fixed-cell-left-0 bg-white text-nowrap";

    return (
        <div className='reports__table-bg'>
            <div className='reports-table__container'>
                <table className="table mb-0 text-center min-w-500 " >
                    <thead >
                        <tr>
                            <th rowSpan={2} className="text-left table-fixed-cell-left-0 tr--border-top tr--border-left td--shadow-right">&nbsp;</th>
                            {months.map((_, index) => (
                                <th colSpan={3} className={`th--border ${index === 0 && 'tr--no-border-left'}`}>{index + 1}</th>
                            )
                            )}
                        </tr>
                        <tr className='tr--no-radius tr--border-top'>
                            {months.map((_, index) => (
                                <React.Fragment key={index}>
                                    <th className={index > 0 && 'tr--border-left'}>Kgs.</th>
                                    <th>Costo</th>
                                    <th className='tr--border-right'>Valor total</th>
                                </React.Fragment>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='tr--border-top'>
                            <td className={rowDescriptionClassName}>Entradas MP a producción</td>
                            {months.map((_, index) => (
                                <React.Fragment key={index}>
                                    <td>1312312313</td>
                                    <td>1312312313</td>
                                    <td>1312312313</td>
                                </React.Fragment>
                            ))}
                        </tr>
                        <tr>
                            <td className={rowDescriptionClassName}>(+) Gtos. de compra de MP</td>
                            {months.map((_, index) => (
                                <React.Fragment key={index}>
                                    <td></td>
                                    <td>1312312313</td>
                                    <td>1312312313</td>
                                </React.Fragment>
                            ))}
                        </tr>
                        <tr className='tr--border-top celda-totales'>
                            <td className={rowDescriptionClassName + ' celda-totales'}>Sub-total materia prima</td>
                            {months.map((_, index) => (
                                <React.Fragment key={index}>
                                    <td>1312312313</td>
                                    <td>1312312313</td>
                                    <td>1312312313</td>
                                </React.Fragment>
                            ))}
                        </tr>
                        <tr>
                            <td className={rowDescriptionClassName}>(-) SKIM</td>
                            {months.map((_, index) => (
                                <React.Fragment key={index}>
                                    <td>1312312313</td>
                                    <td>1312312313</td>
                                    <td>1312312313</td>
                                </React.Fragment>
                            ))}
                        </tr>
                        <tr>
                            <td className={rowDescriptionClassName}>(-) Coágulo lavado de pilas/centrífugas</td>
                            {months.map((_, index) => (
                                <React.Fragment key={index}>
                                    <td>1312312313</td>
                                    <td>-</td>
                                    <td>-</td>
                                </React.Fragment>
                            ))}
                        </tr>
                        <tr>
                            <td className={rowDescriptionClassName}>(-) Coágulo de trampas</td>
                            {months.map((_, index) => (
                                <React.Fragment key={index}>
                                    <td>1312312313</td>
                                    <td>-</td>
                                    <td>-</td>
                                </React.Fragment>
                            ))}
                        </tr>
                        <tr className='tr--border-top celda-totales'>
                            <td className={rowDescriptionClassName + ' celda-totales'}>(=) MP transf. En PP</td>
                            {months.map((_, index) => (
                                <React.Fragment key={index}>
                                    <td>1312312313</td>
                                    <td>1312312313</td>
                                    <td>1312312313</td>
                                </React.Fragment>
                            ))}
                        </tr>
                        <tr>
                            <td className={rowDescriptionClassName}>(+) Cto. De Proceso</td>
                            {months.map((_, index) => (
                                <React.Fragment key={index}>
                                    <td></td>
                                    <td>1312312313</td>
                                    <td></td>
                                </React.Fragment>
                            ))}
                        </tr>
                        <tr className='tr--border-top celda-totales'>
                            <td className={rowDescriptionClassName + ' celda-totales'}>(=) PP costeado</td>
                            {months.map((_, index) => (
                                <React.Fragment key={index}>
                                    <td>1312312313</td>
                                    <td>1312312313</td>
                                    <td>1312312313</td>
                                </React.Fragment>
                            ))}
                        </tr>
                        <tr>
                            <td className={rowDescriptionClassName}>(+) Inv. Inicial de PP</td>
                            {months.map((_, index) => (
                                <React.Fragment key={index}>
                                    <td>1312312313</td>
                                    <td>1312312313</td>
                                    <td>1312312313</td>
                                </React.Fragment>
                            ))}
                        </tr>
                        <tr className='tr--border-top celda-totales'>
                            <td className={rowDescriptionClassName + ' celda-totales'}>(=) Disponible para Vta</td>
                            {months.map((_, index) => (
                                <React.Fragment key={index}>
                                    <td>1312312313</td>
                                    <td>1312312313</td>
                                    <td>1312312313</td>
                                </React.Fragment>
                            ))}
                        </tr>
                        <tr>
                            <td className={rowDescriptionClassName}>(-) Coágulos centrifugado</td>
                            {months.map((_, index) => (
                                <React.Fragment key={index}>
                                    <td>1312312313</td>
                                    <td>-</td>
                                    <td>-</td>
                                </React.Fragment>
                            ))}
                        </tr>
                        <tr>
                            <td className={rowDescriptionClassName}>(-) Perdida</td>
                            {months.map((_, index) => (
                                <React.Fragment key={index}>
                                    <td>1312312313</td>
                                    <td>-</td>
                                    <td>-</td>
                                </React.Fragment>
                            ))}
                        </tr>
                        <tr className="celda-totales">
                            <td className={rowDescriptionClassName + ' celda-totales'}>(=) Inv. Final de PP</td>
                            {months.map((_, index) => (
                                <React.Fragment key={index}>
                                    <td>1312312313</td>
                                    <td>1312312313</td>
                                    <td>1312312313</td>
                                </React.Fragment>
                            ))}
                        </tr>
                        <tr className='tr--border-top celda-totales'>
                            <td className={rowDescriptionClassName + ' celda-totales'}>(=) Costo Vta. Del Mes</td>
                            {months.map((_, index) => (
                                <React.Fragment key={index}>
                                    <td>1312312313</td>
                                    <td>1312312313</td>
                                    <td>1312312313</td>
                                </React.Fragment>
                            ))}
                        </tr>
                        <tr className='tr--border-top celda-totales'>
                            <td className={rowDescriptionClassName + ' celda-totales'}>(=) Prod. Termin. Final</td>
                            {months.map((_, index) => (
                                <React.Fragment key={index}>
                                    <td>1312312313</td>
                                    <td>1312312313</td>
                                    <td>1312312313</td>
                                </React.Fragment>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default LatexTable;
