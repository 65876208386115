import { api } from "api";
import React, { Component } from "react";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";
// import AsyncSelect from "react-select/lib/Async";
import { Link } from "react-router-dom";

const logo = require("assets/img/logo.png");
const defaultAvatar = require("assets/img/avatar-usuario.png");
const vector = require("assets/img/icons/Deslizar/Vector.svg")

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = { dropdownOpen: false };
    }

    toggle = () => {
        this.setState({ dropdownOpen: !this.state.dropdownOpen });
    };

    options = [
        { value: "Spring", label: "Spring" },
        { value: "Summer", label: "Summer" },
        { value: "Autumn", label: "Autumn" },
        { value: "Winter", label: "Winter" },
    ];

    render() {
        const { navToggle, logOut, user } = this.props;

        return (
            <nav className="align-items-stretch flex-md-nowrap p-0 navbar navbar-light">
                <div className="main-navbar__search w-100 d-none d-md-flex d-lg-flex">
                    <img src={logo} className="main-navbar__logo" width="10%" alt="" />
                </div>
                <ul className="flex-row navbar-nav">
                    <Dropdown
                        isOpen={this.state.dropdownOpen}
                        toggle={this.toggle}
                    >
                        <DropdownToggle
                            color="light"
                            style={{
                                display: "flex",
                                width: "180px",
                                alignItems: "center",
                                border: "2px solid #606060",
                                backgroundColor: "transparent",
                            }}
                            className="d-flex justify-content-between align-items-center"
                        >
                            <div className="d-flex align-items-center">
                                <img
                                    className="user-avatar rounded-circle mr-3"
                                    src={
                                        user && user.avatar
                                            ? user.avatar
                                            : defaultAvatar
                                    }
                                    alt="User"
                                />
                                <span style={{
                                    color: "#606060",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                }}
                                    className="d-md-inline-block mr-3">
                                    {user.username}
                                </span>
                            </div>
                            <span className="d-md-inline-block">
                                <img src={vector} />
                            </span>
                        </DropdownToggle>
                        <DropdownMenu
                            style={{
                                width: "177px"
                            }}
                        >
                            <div className="br-top"></div>
                            <Link
                                tabIndex="0"
                                to="/user-profile"
                                className="no-underline"
                            >
                                <DropdownItem>PERFIL</DropdownItem>
                            </Link>
                            <DropdownItem onClick={logOut}>
                                <a tabIndex="0" href="/">
                                    CERRAR SESIÓN
                                </a>
                            </DropdownItem>
                            <div className="br-top"></div>
                        </DropdownMenu>
                    </Dropdown>
                </ul>
                <nav className="nav">
                    <a
                        className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-inline d-lg-none text-center"
                        onClick={navToggle}
                    >
                        <i className="material-icons"></i>
                    </a>
                </nav>
            </nav>
        );
    }
}

export default Navbar;
