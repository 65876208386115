import { connect } from 'react-redux';
import { actions } from '../../../../../../redux/modules/shopping/shopping';
import { getMe } from "../../../../../../redux/modules/cuenta/login";
import ShoppingEdit from './ShoppingEdit';

const mstp = state => {
    return {...state.shopping}
};

const mdtp = {
    ...actions, getMe
};

export default connect(mstp, mdtp)(ShoppingEdit)
