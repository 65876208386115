import React from 'react';
import { Modal } from 'react-responsive-modal';
import { TableHeaderColumn } from 'react-bootstrap-table';
import Grid from '../../../../../Utils/Grid';
import { months } from '../../../../../Utils/months';
import { Field, reduxForm } from 'redux-form';
import { renderFieldDisabled, renderPercentageDisabled, renderTypeOfDebtDisabled } from '../../../../../Utils/renderField/renderField';
const AnnualModal = props => {
    const { open,
        closeModal }
        = props

    const { loader, onSortChange, data_modal } = props;


    if (!data_modal || !data_modal.years_list || !Array.isArray(data_modal.years_list)) {
        if (loader) {
            return <div>Cargando datos...</div>;
        }
        return <div></div>;
    }

    const formatDollar = (cell, row) => {
        if (cell && cell !== '--') {
            const formattedValue = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(cell);
            return formattedValue;
        }
        return '$ 0.00';
    };
    const columnaClaseTotal = (cell, row) => {
        if (row.name === 'Total') {
            return 'celda-totales'; // Esta es la clase CSS que necesitarás definir
        }
        return '';
    };

    const columnas = data_modal.years_list.map((year, index) => (
        <TableHeaderColumn
            key={index}
            width='110'
            dataField={year}
            dataSort
            dataFormat={formatDollar}
            columnClassName={columnaClaseTotal}
        >
            {year}
        </TableHeaderColumn>
    ));
    const TYPE_OF_DEBT_LABELS = {
        10: 'LÍNEA DE CRÉDITO',
        20: 'PAGARÉ',
        30: 'CRÉDITO BANCARIO',
        40: 'ANTICIPO DE VENTAS'
    };
    return (
        <Modal
            open={open}
            onClose={closeModal}
            center
            classNames={{
                overlayAnimationIn: 'customEnterOverlayAnimation',
                overlayAnimationOut: 'customLeaveOverlayAnimation',
                modalAnimationIn: 'customEnterModalAnimation',
                modalAnimationOut: 'customLeaveModalAnimation',
                modal: 'customModalSizeLatex',
            }}
        >

            <div >
                <div className="pb-4 titulo-modal d-flex justify-content-center">
                    <div className='col-md-12 p-0 mt-2 text-center'>
                        <h4>RESUMEN DE DEUDA</h4>
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-between">
                    <div className="form-group mr-3 flex-grow-1">
                        <label htmlFor="name" className="m-0">Tipo de crédito</label>
                        <Field
                            name={`type_of_debt`}
                            component={renderTypeOfDebtDisabled}
                            width="100%"
                            labels={TYPE_OF_DEBT_LABELS}
                            applyStyles={true}
                            isTextarea={false}
                        />
                    </div>
                    <div className="form-group mr-3 flex-grow-1">
                        <label htmlFor="name" className="m-0">Nombre del presupuesto</label>
                        <Field
                            name={`name`}
                            component={renderFieldDisabled}
                            width="100%"
                            applyStyles={false}
                            isTextarea={false}
                        />
                    </div>
                    <div className="form-group mr-3 flex-grow-1">
                        <label htmlFor="name" className="m-0">Tasa</label>
                        <Field
                            name={`parameters.interest_rate`}
                            component={renderPercentageDisabled}
                            width="100%"
                            style={{ fontWeight: "bold" }}
                            applyStyles={false}
                            isTextarea={false}
                        />
                    </div>
                    <div className="form-group flex-grow-1">
                        <label htmlFor="name" className="m-0">Días de plazo</label>
                        <Field
                            name={`parameters.days_of_term`}
                            component={renderFieldDisabled}
                            width="100%"
                            applyStyles={false}
                            isTextarea={false}
                        />
                    </div>
                </div>
                <div>
                    <Grid
                        hover
                        striped
                        data={data_modal}
                        loading={loader}
                        onSortChange={onSortChange}
                        pagination={false}
                        className="pb-3"

                    >
                        <TableHeaderColumn
                            dataField="name"
                            width='180'
                            dataSort
                            isKey
                            columnClassName={columnaClaseTotal}
                        >
                            RUBRO
                        </TableHeaderColumn>
                        {columnas}
                    </Grid>
                </div>
            </div>
        </Modal>
    );
};


const ConnectedAnnualModal = AnnualModal;

export default reduxForm({
    form: 'ModalSummary'
})(ConnectedAnnualModal);
