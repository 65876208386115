import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import RecoverPasswordForm from './RecoverPasswordForm';
// import './change.css';
import LoadMask from "Utils/LoadMask/LoadMask";

class ChangePassword extends Component {
    render() {
        const { sendRecover, loader } = this.props;
        return (
            <div className="reset-password__container">
                <div className="login-wrapper">
                    <LoadMask loading={loader} light>
                        <RecoverPasswordForm onSubmit={sendRecover} />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default ChangePassword;
