import React, { useState, useEffect } from 'react';
import Card from 'Utils/Cards/Card';
import Listado from './Listado';
import ConnectedRecipeModal from './Modal/RecipeModal';
import ListadoCosto from '../../Summary/List/ListadoCosto';

const LogisticsAndExportList = (props) => {
    const { read, crear, editar, data, loader, readEdit, readShow, data_modal, reset } = props;
    const { results } = data_modal;
    const [open, setOpen] = useState(false);
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        readShow();
    }, [readShow]);

    const openModal = () => {
        setOpen(true);
        setInitialValues({});
    };

    const closeModal = () => {
        setOpen(false);
        setInitialValues({});
    };

    const handleAddCategory = (data, reset) => {

        if (data.id) {
            editar(data.id, data);
            closeModal();
            reset();
        } else {
            crear(data)
            closeModal();
            reset();
        }

    };

    return (
        <div className="mb-4 col-12">
            <Card
                noShadow
                icon="Side_activos/Admin.svg"
                titulo="LOGÍSTICA Y EXPORTACIÓN"
                subtitulo="CONFIGURACIÓN"
            >
                <div className="row col-md-12 p-0">
                    <div className="col-md-6">
                        <h4 className="mr-2 titulo_principal">LOGÍSTICA Y EXPORTACIÓN</h4>
                    </div>
                </div>
                <Listado data={data} loader={loader} openModal={openModal} readEdit={readEdit} reset={reset} setInitialValues={setInitialValues} {...props} />
                <div className="row col-md-12 p-0">
                    <div className="col-md-6">
                        <h4 className="mr-2 titulo_principal">COSTO POR KILOGRAMO POR PUERTO DE SALIDA</h4>
                    </div>
                </div>
                <ListadoCosto data={data} loader={loader} readShow={readShow} {...props} />

            </Card>

            <ConnectedRecipeModal
                open={open}
                dollarExchange={data.dollar_exchange}
                closeModal={closeModal}
                handleAddCategory={handleAddCategory}
                initialValues={results ? results : initialValues}
                change={props.change}
                {...props}
            />

        </div>
    );
};

export default LogisticsAndExportList;