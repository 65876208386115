import React from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";
import { months } from '../../../../Utils/months';
import GridEmpty from '../../../../Utils/Grid/GridEmpty';
import { columnaClaseFija, columnaFijaTotales } from '../../../../../../utility/staticColumns';
const List = props => {
    const { data, loader, onSortChange, currency } = props;

    const dataExportable = data && data.investments && Array.isArray(data.investments.results) ? data.investments.results : [];
    const formatearNumero = (dato) => {
        if (dato === null || dato === undefined || isNaN(dato)) {
            return {color:'red'};
        }

        

        const formatoMoneda = new Intl.NumberFormat('es-GT', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });
        const numero = Number(dato);
        
        return currency === 'USD' ? "-$ " + formatoMoneda.format(numero) :"-Q " + formatoMoneda.format(numero);
    }
    const columnaClaseTotal = (cell, row) => {
        if (row.name === 'TOTALES') {
            return 'celda-totales';
        }
        return '';
    };

    const meses = months
    const columnas = meses.map((mes, index) => (
        <TableHeaderColumn
            key={index}
            dataField={mes}
            dataSort
            dataFormat={formatearNumero}
            columnClassName={columnaClaseTotal}
            width='130'
            tdStyle={{ color:'red' }}
        >
            {index + 1}
        </TableHeaderColumn>
    ));


    return (
        <GridEmpty
            hover
            striped
            data={dataExportable}
            loading={loader}
            onSortChange={onSortChange}
            pagination={false}
            className="pb-3"
        >

            <TableHeaderColumn
                dataField="name"
                isKey={true}
                dataSort
                className="table-fixed-cell-left-0"
                columnClassName={columnaClaseFija}
                width='170'
                tdStyle={{ whiteSpace: 'normal', color:'red' }}
            >
            FLUJO DE INVERSIÓN
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="total"
                dataSort
                dataFormat={formatearNumero}
                width='160'
                className="table-fixed-cell-left-1"
                columnClassName={columnaFijaTotales}
                tdStyle={{  color:'red' }}
            >
                Total
            </TableHeaderColumn>
            {columnas}

        </GridEmpty>
    );
};
export default List;