import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
import { initialize as initializeForm } from 'redux-form';
import { api } from "api";
import Swal from 'sweetalert2';
const alerta = require('assets/img/icons/activo.png');   

let url = 'investments'
let returnUrlConfig = '/inversiones/configuration'
// ------------------------------------
// Constants
// ------------------------------------
const LOADER = 'INVESTMENTS_LOADER';
const DATA = 'INVESTMENTS_DATA';
const ITEM_DATA = 'INVESTMENTS_ITEM';
const TAB_EN_CURSO = 'INVESTMENTS_TAB_EN_CURSO';
const PAGE_EN_CURSO = 'INVESTMENTS_PAGE_EN_CURSO';
const PAGE_ANUALES = 'INVESTMENTS_PAGE_ANUALES';
const YEARS = 'YEARS';

// -----------------------------------
// Pure Actions
// -----------------------------------
const setLoader = loader => ({
    type: LOADER,
    loader,
});
const setData = data => ({
    type: DATA,
    data,
});
const setItem = item => ({
    type: ITEM_DATA,
    item,
});
const setYears = year => ({
    type: YEARS,
    year,
});


const confirm_administrative_save = (dispatch) => {
    const SwalMod = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-primary btn-modal',
            content: '',
            title: 'darck',
            popup: 'custom-modal-size',
        },
        buttonsStyling: false,
        showCloseButton: true,
        focusConfirm: false,
        padding: "1em",
    });

    SwalMod.fire({
        title: 'FELICIDADES',
        text: 'Tu formulario se ha realizado de forma exitosa',
        imageUrl: alerta,
        imageAlt: 'Alerta',
        confirmButtonText: 'CONTINUAR',
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            dispatch(push(returnUrlConfig));
        }
        dispatch(push(returnUrlConfig));
    });
}

// -----------------------------------
// Actions
// -----------------------------------


const read = id => (dispatch) => {
    dispatch(setLoader(true));
    const params = {}
    params.id = id
    api.get(`${url}/get_investment_detail`,params).then((response) => {
        dispatch(setData(response.results));
        dispatch(setItem(response.dollar_exchange));
        dispatch(initializeForm('InvestmentsForm', response.results));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const readYearForCreate = id => (dispatch) => {
    dispatch(setLoader(true));
    const params = {}
    params.id = id
    api.get(`${url}/get_years_create`,params).then((response) => {
        dispatch(setYears(response.years_list));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const show = id => (dispatch) => {
    dispatch(setLoader(true));
    const params = {}
    params.id = id
    api.get(`${url}/get_investment_detail`,params).then((response) => {
        dispatch(setItem(response.dollar_exchange));
        dispatch(setData(response.results));
        dispatch(initializeForm('InvestmentsinProgressShow', response.results));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const getDollar = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${url}/get_dollar_exchange`).then((response) => {
        dispatch(setItem(response.dollar_exchange));
        dispatch(initializeForm('InvestmentsForm', response.results));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const getInvestmentsDetail = () => ( dispatch ) => {
    dispatch(setLoader(true));
    api.get(`${url}/get_investmets`).then((response) => {
        dispatch(setData(response));
        dispatch(setItem(response.dollar_exchange));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id,data) => (dispatch) => {
        dispatch(setLoader(true));
    api.put(`${url}/${id}`,data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push(returnUrlConfig));
    }).catch(() => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error en la edición', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}`,data).then(() => {
        confirm_administrative_save(dispatch);
    }).catch((error) => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error en la creación', 'ERROR', 3000);
        console.log(error,'Error')
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const create_annual = (data) => (dispatch) => {    
    dispatch(setLoader(true));
    api.post(`${url}/annual_create_investment`, data).then(() => {
        NotificationManager.success('Registro creado con exito', 'Éxito', 3000);
        dispatch(push('/inversiones/summary'));
        dispatch(read_annual())
    }).catch(() => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error en la creación', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${url}/${id}`).then(() => {
        dispatch(getInvestmentsDetail());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch((error) => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar_annual = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${url}/${id}/delete_annual`).then(() => {
        dispatch(read_annual())
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch((error) => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const read_annual = id => (dispatch) => {
    dispatch(setLoader(true));
    const params = {}
    params.id = id
    api.get(`${url}/get_investment_summary`,params).then((response) => {
        dispatch(setItem(response.dollar_exchange));
        dispatch(setData(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar_investments_annual = id => (dispatch) => {
    dispatch(setLoader(true));
    const params = {}
    params.id = id
    api.get(`${url}/${id}/get_investment_summary_by_id`).then((response) => {
        dispatch(setData(response));
        dispatch(setYears(response.years_list));
        dispatch(initializeForm('InvestmentsForm', response.results));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const actualizar_annual = (data) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}/annual_update_investment`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push('/inversiones/summary'));
        dispatch(read_annual())
    }).catch(() => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error en la edición', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

//  ----------------
// Administrativo Resumen
// -----------------

const setTab = (tab) => (dispatch) => {
    dispatch(setLoader(true));
    dispatch({ type: TAB_EN_CURSO, tab });
    if (tab === "EN_CURSO") {
        dispatch(getInvestmentsDetail())
    } else {
        dispatch(read_annual())
    }
};

export const actions = {
    getInvestmentsDetail,
    editar,
    crear,
    read,
    eliminar,
    getDollar,
    setTab,
    show,
    readYearForCreate,
    create_annual,
    read_annual,
    editar_investments_annual,
    actualizar_annual,
    eliminar_annual
};
// -----------------------------------
// Reducers
// -----------------------------------
const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },

    [PAGE_EN_CURSO]: (state, { page_activas }) => {
        return {
            ...state,
            page_activas,
        };
    },
    [PAGE_ANUALES]: (state, { page_finalizadas }) => {
        return {
            ...state,
            page_finalizadas,
        };
    },

    [TAB_EN_CURSO]: (state, { tab }) => {
        return {
            ...state,
            tab,
        };
    },
    [YEARS]: (state, { year }) => {
        return {
            ...state,
            year,
        };
    },
    
};
export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    administrativos_en_curso: {
        results: [],
        count: 0,
    },
    administrativos_anuales: {
        results: [],
        count: 0,
    },
    page_en_curso: 1,
    page_anuales: 1,
    item: {},
    tab: 'EN_CURSO',
    year: []
    
};
export default handleActions(reducers, initialState)