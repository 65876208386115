import React, { Component } from 'react';
import Card from 'Utils/Cards/Card';
import Listado from './Listado';
import ListadoCosto from './ListadoCosto';
import CardEmpty from '../../../../Utils/Cards/CardEmpty';
import CardFormNoBorder from '../../../../Utils/Cards/CardFormNoBorder';
import Tabs, { TabPane } from 'rc-tabs';
import AnnualProjectionsLogistics from './AnnualProjections/AnnualProjectionsLogistics';

class LogisticsAndExportListSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            initialValues: {}
        };
    }

    componentDidMount() {
        if (this.props.tab === 'EN_CURSO') {
            this.props.read();
        }else{
            this.props.summary_annual_list();
        }

    }

    render() {
        const { data, loader, readShow, tab, setTab } = this.props;
        const currentYear = new Date().getFullYear();
        const nextYear = currentYear + 1;

        return (
            <div className="mb-4 col-12">
                <CardFormNoBorder
                    noShadow
                    icon="Side_activos/Admin.svg"
                    titulo="LOGÍSTICA Y EXPORTACIÓN"
                    subtitulo="RESUMEN"
                >
                </CardFormNoBorder>

                <Tabs
                    defaultActiveKey={tab}
                    onChange={tab => setTab(tab)}
                    animated={{ tabPane: true, inkBar: true }}
                >
                    <TabPane tab={`Año ${nextYear}`} key="EN_CURSO">
                        <CardEmpty noShadow
                            isMarginButtom>
                            <div className="row col-md-12 p-0">
                                <div className="col-md-6">
                                    <h4 className="mr-2 titulo_principal">LOGÍSTICA Y EXPORTACIÓN</h4>
                                </div>
                            </div>
                            <Listado data={data} loader={loader} readShow={readShow} {...this.props} />
                            <div className="row col-md-12 p-0">
                                <div className="col-md-6">
                                    <h4 className="mr-2 titulo_principal">COSTO POR KILOGRAMO POR PUERTO DE SALIDA</h4>
                                </div>
                            </div>
                            <ListadoCosto data={data} loader={loader} openModal={this.openModal} readShow={readShow} {...this.props} />
                        </CardEmpty>
                    </TabPane>

                    <TabPane tab='PROYECCIONES ANUALES' key="PROYECCIONES_ANUALES">
                        <CardEmpty noShadow
                            isMarginButtom>
                            <AnnualProjectionsLogistics  {...this.props} />
                        </CardEmpty>
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}

export default LogisticsAndExportListSummary;
