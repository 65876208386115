import React from 'react';
import { reduxForm, formValueSelector } from 'redux-form';
import Variables from './Variables'
import IvaCredit from './IvaCredit'
import TablaCompras from './Compras'
import Tabs, { TabPane } from "rc-tabs";
import Precios from './Precio'
import CardEmpty from 'Utils/Cards/CardEmpty';
import ProduccionFinca from './ProduccionFinca'
import ProjectionsLatexTableAnnual from './ProjectionsLatexTableAnnual';
import YearChange from './YearChange';
import TaxISR from './TaxISR';
import Swal from 'sweetalert2';
const alerta = require('assets/img/icons/warning.png');
import validate from "./validate"
import { connect } from 'react-redux';
import Card from 'Utils/Cards/Card';

export let ParametrosGenerales = props => {
  const { handleSubmit, data, updateParameters, setTab, tab } = props;

  const submitHandle = (formData) => {
    if (tab === 'YEAR_CHANGE') {

    } else {
      update_parameters(formData)
    }

  }
  const update_parameters = (formData) => {
    const SwalMod = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary btn-modal',
        cancelButton: 'btn btn-outline-dark mr-5 btn-modal',
        content: '',
        title: 'darck',
        popup: 'custom-modal-size',
      },
      buttonsStyling: false,
      showCloseButton: true,
      focusConfirm: false,
      focusCancel: false,
      padding: "1em",
    });

    SwalMod.fire({
      title: 'CONFIRMACIÓN',
      text: '¿Estás seguro de confirmar todos los cambios?',
      imageUrl: alerta,
      imageAlt: 'Alerta',
      showCancelButton: true,
      confirmButtonText: 'GUARDAR',
      cancelButtonText: 'CANCELAR',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        updateParameters(data.id, formData, tab)
      }
    });
  }

  return (
    <form onSubmit={handleSubmit(submitHandle)}>
      <div className="mb-4 col-12">
        <Card noShadow
          icon="configuraciones_activo.png"
          titulo="Parámetros de sensibilidad"
          subtitulo="Inicio"
          noBorder>
          <Tabs
            defaultActiveKey={tab}
            activeKey={tab}
            onChange={tab => setTab(tab)}
            animated={{ tabPane: true, inkBar: true }}
          >
            <TabPane tab="PRODUCCIÓN FINCA" key="PRODUCCION_FINCA">
              <CardEmpty noShadow isMarginButtom>
                <ProduccionFinca {...props} />
              </CardEmpty>
            </TabPane>
            <TabPane tab="PRECIOS" key="PRECIOS">
              <CardEmpty noShadow isMarginButtom>
                <Precios {...props} />
              </CardEmpty>
            </TabPane>
            <TabPane tab="COMPRAS" key="COMPRAS">
              <CardEmpty noShadow isMarginButtom>
                <TablaCompras {...props} />
              </CardEmpty>
            </TabPane>
            <TabPane tab="VARIABLES" key="VARIABLES">
              <CardEmpty noShadow isMarginButtom>
                <Variables {...props} />
              </CardEmpty>
            </TabPane>
            <TabPane tab="CRÉDITO FISCAL" key="CREDITO_FISCAL">
              <CardEmpty noShadow isMarginButtom>
                <IvaCredit {...props} />
              </CardEmpty>
            </TabPane>
            <TabPane tab="PROYECCIONES ANUALES" key="PROYECCIONES_ANUALES">
              <CardEmpty noShadow isMarginButtom>
                <ProjectionsLatexTableAnnual {...props} />
              </CardEmpty>
            </TabPane>
            <TabPane tab="ISR" key="ISR">
              <CardEmpty noShadow isMarginButtom>
                <TaxISR {...props} />
                <br />
              </CardEmpty>
            </TabPane>
            <TabPane tab="CAMBIO DE AÑO" key="YEAR_CHANGE">
              <CardEmpty noShadow isMarginButtom>
                <YearChange {...props} />
                <br />
              </CardEmpty>
            </TabPane>
          </Tabs>
        </Card>

        {tab === 'YEAR_CHANGE' ? (
          null
        ) : (
          <div className='buttons-box mt-5'>
            <button type="submit" className="btn btn-primary">
              ACTUALIZAR
            </button>
          </div>
        )}
      </div>
    </form>
  );
};

const formName = 'ParametrosGenerales';
const selector = formValueSelector(formName);

const mapStateToProps = (state) => {
  const parameterTaxCredit = selector(state, 'parameter_tax_credit') || {};
  let dataForm = {}
  if (state && state.form && state.form.ParametrosGenerales && state.form.ParametrosGenerales.values) {
    dataForm = state.form.ParametrosGenerales.values
  }
  return {
    parameterTaxCredit,
    dataForm
  };
};

ParametrosGenerales = reduxForm({
  form: formName,
  validate,
})(ParametrosGenerales);

ParametrosGenerales = connect(mapStateToProps)(ParametrosGenerales);

export default ParametrosGenerales;