import React from 'react';
import { Field, FieldArray } from 'redux-form';
import { renderDollar, renderFieldDisabled, renderDollarDisabled } from '../../../../Utils/renderField/renderField';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { months } from '../../../../Utils/months';

const TYPE_OF_ROW_MAPPING = {
    AUTHORIZED: 10,
    AVAILABLE: 11,
    INITIAL_BALANCE: 12,
    REPAYMENTS: 13,
    FINAL_BALANCE: 14,
    INCREASE: 15,
};

const TableRow = ({ system, index, fields }) => {
    const isEditRow = [TYPE_OF_ROW_MAPPING.REPAYMENTS, TYPE_OF_ROW_MAPPING.INCREASE].includes(fields.get(index).type_of_row);

    return (
        <React.Fragment key={index}>
            <tr key={`system-${index}`}
                style={isEditRow ? { backgroundColor: "#F0FFC7" } : {}}
            >
                <td className="text-center sin-borde-top" style={{ width: "10%" }}>
                    <Field
                        name={`${system}.name`}
                        type="text"
                        component={renderFieldDisabled}
                        className="form-control"
                        placeholder='Nuevo'
                    />
                </td>
                {months.map((month) => (
                    <td key={month} style={{ width: "6.9%" }}>
                        <Field
                            name={`${system}.${month}`}
                            type="text"
                            component={isEditRow ? renderDollar : renderDollarDisabled}
                            className="form-control"
                            placeholder='$ 0.00'
                        />
                    </td>
                ))}
            </tr>
        </React.Fragment>
    );
};

const GenericTable = ({ fields }) => {
    const calculateTotalForMonth = () => {
        const allFields = fields.getAll() || [];

        // Encontrar las filas relevantes
        const authorizedRow = allFields.find(row => row.type_of_row === TYPE_OF_ROW_MAPPING.AUTHORIZED);
        const initialBalanceRow = allFields.find(row => row.type_of_row === TYPE_OF_ROW_MAPPING.INITIAL_BALANCE);
        const repaymentsRow = allFields.find(row => row.type_of_row === TYPE_OF_ROW_MAPPING.REPAYMENTS);
        const finalBalanceRow = allFields.find(row => row.type_of_row === TYPE_OF_ROW_MAPPING.FINAL_BALANCE);
        const availableRow = allFields.find(row => row.type_of_row === TYPE_OF_ROW_MAPPING.AVAILABLE);
        const increaseRow = allFields.find(row => row.type_of_row === TYPE_OF_ROW_MAPPING.INCREASE);

        // Iterar sobre los meses para calcular los valores
        months.forEach((month, index) => {
            if (authorizedRow && initialBalanceRow && repaymentsRow && finalBalanceRow && availableRow && increaseRow) {
                const authorized = parseFloat(authorizedRow[month]) || 0;
                const initialBalance = parseFloat(initialBalanceRow[month]) || 0;
                const repayments = parseFloat(repaymentsRow[month]) || 0;
                const increase = parseFloat(increaseRow[month]) || 0;

                const finalBalance = initialBalance - repayments;

                // Si es enero, el saldo inicial es el ingresado por el usuario.
                if (month !== 'enero') {
                    // Para los demás meses, INITIAL_BALANCE = FINAL_BALANCE del mes anterior + INCREASE del mes anterior
                    const previousMonth = months[index - 1];
                    initialBalanceRow[month] = (parseFloat(finalBalanceRow[previousMonth]) || 0) + (parseFloat(increaseRow[previousMonth]) || 0);
                    availableRow[month] = authorized - initialBalanceRow[month];

                } else {
                    availableRow[month] = authorized - initialBalance;
                }

                finalBalanceRow[month] = finalBalance;
            }
        });

    };
    const columnas = Array.from({ length: 12 }, (_, index) => (
        <th key={index} style={{ width: "6.9%" }}>{index + 1}</th>
    ));

    months.map((month, idx) => (
        calculateTotalForMonth(month)
    ));

    return (
        <div className=" col-sm-12 form-group np-r p-0">
            <div className='scroll-container'>
                <div className='scroll-content-sales'>
                    <div className="react-bs-container-header table-header-wrapper">
                        <table className="table mb-0 text-center">
                            <thead>
                                <tr>
                                    <th style={{ width: "10%" }}>MES</th>
                                    {columnas.map(columna => columna)}
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="tabla-con-borde">
                        <table className="table table-sm  m-0 table-striped">
                            <tbody style={{ backgroundColor: "#F0FFC7" }}>
                                {fields.map((system, index) =>
                                    <TableRow
                                        key={index}
                                        system={system}
                                        index={index}
                                        fields={fields}
                                    />)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

const CreditLineTable = ({ fieldName, debtManagement }) => {
    return (
        <div>
            <FieldArray
                name={`${fieldName}`}
                component={GenericTable}
                debtManagement={debtManagement}
            />
        </div>
    );
}

const formName = 'DebtManagementForm';


const mapStateToProps = (state) => ({
    debtManagement: formValueSelector(formName)(state, 'debt_management_budget_detai') || [],
});

export default connect(mapStateToProps)(CreditLineTable);                                                                                        