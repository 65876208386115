import React from 'react';
import CardForm from '../../../../Utils/Cards/CardForm';
import { reduxForm } from 'redux-form';
import SalesTable from "./SalesTable"
import validate from './validate';
import Alerta from '../../../../Utils/Alerta/Alerta';

let SalesForm = props => {
    const { handleSubmit, change, regresar } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className='col-sm-12 p-0'>
                <CardForm
                    icon="Side_activos/ventas.svg"
                    titulo="PRESUPUESTO DE VENTAS"
                    subtitulo="CONFIGURACIÓN"
                    subtituloForm="DETALLE"
                    col="12"
                    noShadow
                >
                    <div className="row justify-content-center col-12 p-0">
                        <div className="text-center">
                            <Alerta 
                                texto={'Los meses se muestran en unidad de contenedor'}
                                className="large-alert-text" // Aplica la clase CSS personalizada
                            />
                        </div>
                    </div>
                    <div className="row col-md-12 p-0 mb-4">
                        <div className="col-md-6">
                            <h4 className="mr-2">PRONOSTICO DE VENTAS CONSOLIDADO</h4>
                        </div>
                    </div>
                    <SalesTable fieldName='generic_row_sales' change={change} />
                </CardForm>
                <div className='buttons-box mt-5'>
                    <button className='btn btn-outline-dark mr-3 p-2 btn-modal' type='button' onClick={regresar}>REGRESAR</button>
                    <button type="submit" className="btn btn-primary m-4 btn-modal">
                        CONFIRMAR
                    </button>
                </div>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'SalesForm',
    validate
})(SalesForm);