import React from "react";
import { months } from "../../../../../Utils/months";

export const getComprasKgSecosEquivalentesSolidos = (comprasData) => {
    try {
        const { kg_proyectados, sales_data_solido, parameters_data } = comprasData;

        const { produces_latex } = parameters_data;

        // primer cálculo
        const salesMeses = months.reduce((obj, mes) => {
            if (produces_latex) {
                obj[mes] = sales_data_solido[mes] - (kg_proyectados[mes] * 0.1)
            } else {
                obj[mes] = sales_data_solido[mes]
            }
            return obj;
        }, {});

        // // segundo cálculo
        // const drcMeses = months.reduce((obj, mes) => {
        //     obj[mes] = salesMeses[mes] / (drcData[mes] / 100);
        //     return obj;
        // }, {});

        return salesMeses;
    } catch (error) {
        console.log('error', error);
        return [];
    }
}

export const getComprasKgSecosEquivalentesLatex = (comprasData) => {
    try {
        const { kg_proyectados, sales_data_latex, parameters_data } = comprasData;

        const { produces_latex } = parameters_data;

        // primer cálculo
        const salesMeses = months.reduce((obj, mes) => {
            if (produces_latex) {
                obj[mes] = sales_data_latex[mes] - (kg_proyectados[mes] * 0.9)
            } else {
                obj[mes] = sales_data_latex[mes]
            }
            return obj;
        }, {});

        return salesMeses;
    } catch (error) {
        console.log('error', error);
        return [];
    }
}


