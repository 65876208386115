require('./LoadMask.css');
import classnames from 'classnames';
import React, {Component} from 'react';
import {Triangle, TailSpin} from 'react-loader-spinner';


class LoadMask extends Component {

  static defaultProps = {
    radius: false,
    dark: false,
    blur: false,
    light: false,
  };

  render() {
    const {
      children, radius, dark, light, blur, loading, type = "Triangle", 
      styleCustom = {}
    } = this.props;

    return (
      <div className="load-mask" style={styleCustom}>
        {loading && (
          <div
            className={classnames('loader-container', {
              radius,
              dark,
              light,
            })}
            style={styleCustom}
          >
            {type === 'TailSpin' &&
            <TailSpin
              height="100"
              width="100"
              color="#3AC0FF"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={loading}
            />
            }
            {type === 'Triangle' &&
            <Triangle
              height="100"
              width="100"
              color="#3AC0FF"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={loading}
            />
            }
          </div>
        )}
        <div
          className={classnames('load-mask-content', {
            loading,
            blur,
          })}
        >
          {children}
        </div>
      </div>
    );
  }
}

export default LoadMask;