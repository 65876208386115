import React, { Component, useEffect } from "react";
import ParametrosGenerales from './Parametros/ParametrosGenerales';

const Home = (props) => {
    const { me, getParameters, tab} = props;
    useEffect(() => {
        getParameters({tab: tab });
    }, []);
    return (
        <div className="mb-4 col-12">
            <ParametrosGenerales {...props} />
        </div>
    );
};

export default Home;