import React, { useState } from 'react';
import { Field, FieldArray } from 'redux-form';
import { renderFieldDisabled, renderDollarDisabled, renderQuetzalesDisabled } from '../../../../../Utils/renderField/renderField';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';


const applyGrowthToProjections = (categories, projections) => {

    if (!categories || !Array.isArray(categories.generic_row) || categories.generic_row.length === 0) {
        console.error("El dataObject no es válido o no contiene un generic_row válido.");
        return [];
    }

    return categories.generic_row.map(genericRow => {
        projections.forEach((projection, index) => {
            const currentYear = projection.year.toString();
            const isInitialYear = index === 0;

            if (genericRow[currentYear] !== undefined) {
                if (!isInitialYear) {
                    const previousYear = projections[index - 1].year.toString();
                    const growthMultiplier = 1 + (projection.growth_percentage_latex_table / 100);
                    let growthMultiplierLabor = 1; 
                    let growthMultiplierInflation = 1;
                    if (genericRow.is_mano_de_obra === true) {
                        growthMultiplierLabor = 1 + (projection.percentage_of_labor / 100);
                    }else {
                        growthMultiplierInflation = 1 + (projection.inflation_percentage / 100)
                    }
                    genericRow[currentYear] = genericRow[previousYear] * growthMultiplier * growthMultiplierLabor * growthMultiplierInflation;
                }
            }
        });
        return genericRow;
    });
};
const TableRow = props => {
    const { system, index, fields, dollar_exchange, kg_proyectados, years  } = props;


    const kg_proyectados_data = kg_proyectados.values.projections || [];

    const calculateTotalForYear = (row, year, isDollar) => {
        let value = parseFloat(row[year]) || 0;

        const projectionData = kg_proyectados_data.find(projection => projection.year === year);
        if (projectionData) {
            value /= projectionData.value_final;
        }
        if (row.is_dollar && dollar_exchange) {
            value *= dollar_exchange;
        }
        if (isDollar && dollar_exchange) {
            value /= dollar_exchange;
        }

        return value;
    };


    return (
        <React.Fragment key={index}>
            <tr key={`system-${index}`}>
                <td className="text-center sin-borde-top custom-cell" style={{ width: "10%" }}>
                    <Field
                        name={`${system}.name`}
                        type="text"
                        component={renderFieldDisabled}
                        className="form-control"
                    />
                    Costo por Kg
                </td>
                {years.map((year, yearIndex) => (
                    <td key={year} style={{ width: "6.9%" }} className="custom-cell text-center">
                        <Field
                            name={`${system}.${year}`}
                            type="text"
                            decimalScale={0}
                            component={fields.get(index).is_dollar ? renderDollarDisabled : renderQuetzalesDisabled}
                            className="form-control"
                        />
                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(calculateTotalForYear(fields.get(index), year, true))}
                    </td>
                ))}
            </tr>
        </React.Fragment>
    );
};

const GenericTable = props => {
    const { fields, item, values_kg, years } = props;
    const dollar_exchange = item;

    const calculateTotalForYear = (year, isDollar, isKg) => {
        const allFields = fields.getAll() || [];
        let total = allFields.reduce((total, system) => {
            let value = parseFloat(system[year]) || 0;

            if (system.is_dollar) {
                value *= dollar_exchange;
            }

            return total + value;
        }, 0);
        if (values_kg && values_kg.values && values_kg.values.projections && isKg) {
            const projectionData = values_kg.values.projections.find(projection => projection.year === year);
            if (projectionData) {
                total /= projectionData.value_final;
            }
        }
        if (isDollar && dollar_exchange) {
            total /= dollar_exchange;
        }

        return total;
    };

    const columnas = years.map((year, index) => (
        <th key={index} style={{ width: "6.9%" }}>{year}</th>
    ));

    return (
        <div className="col-sm-12 form-group np-r p-0">
            <div className='scroll-container'>
                <div className='scroll-content-annual'>
                    <div className="react-bs-container-header table-header-wrapper">
                        <table className="table mb-0 text-center">
                            <thead>
                                <tr>
                                    <th style={{ width: "10%" }}>CONCEPTO</th>
                                    {columnas}
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="tabla-con-borde">
                        <table className="table table-sm  m-0 table-striped">
                            <tbody style={{ backgroundColor: "#F0FFC7" }}>
                                {fields.map((system, index) => (
                                    <TableRow
                                        key={index}
                                        system={system}
                                        index={index}
                                        fields={fields}
                                        dollar_exchange={dollar_exchange}
                                        kg_proyectados={values_kg}
                                        years={years}
                                    />
                                ))}

                                <tr style={{ backgroundColor: "#F0FFC7", borderBottom: '1px solid #035B2F', borderTop: '1px solid #035B2F' }}>
                                    <td className="custom-cell text-center">TOTALES Q</td>
                                    {years.map((year, idx) => (
                                        <td key={idx} className="custom-cell text-center">
                                            {new Intl.NumberFormat('es-GT', { style: 'currency', currency: 'GTQ' }).format(calculateTotalForYear(year))}
                                        </td>
                                    ))}
                                </tr>
                                <tr style={{ backgroundColor: "#F0FFC7", borderBottom: '1px solid #035B2F', borderTop: '1px solid #035B2F' }}>
                                    <td className="custom-cell text-center">TOTALES $</td>
                                    {years.map((year, idx) => (
                                        <td key={idx} className="custom-cell text-center">
                                            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(calculateTotalForYear(year, true))}
                                        </td>
                                    ))}
                                </tr>
                                <tr style={{ backgroundColor: "#F0FFC7" }}>
                                    <td className="custom-cell text-center">Totales costo por kg</td>
                                    {years.map((year, idx) => (
                                        <td key={idx} className="custom-cell text-center">
                                            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(calculateTotalForYear(year, true, true,))}
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

const ProductionLatexTableAnnual = props => {
    const years = props.data.results.years  || [];
    const { categoryBudget , projections } = props
    applyGrowthToProjections(categoryBudget, projections)
    return (
        <div>
            <FieldArray
                name='budget_finca_latex_table_annual.generic_row'
                component={GenericTable}
                props={{ ...props, years }}
            />
        </div>
    );
}

const formName = 'FincaForm';

const selector = formValueSelector(formName);

const mapStateToProps = (state) => {
    const categoryBudget = selector(state, 'budget_finca_latex_table_annual') || [];
    const projections = selector(state, 'projections') || [];
    const values_kg = state.form.FincaForm || {};
    return { values_kg, categoryBudget, projections  };
};

export default connect(mapStateToProps)(
    reduxForm({
        form: formName,
    })(ProductionLatexTableAnnual)
);
