import React from 'react';
import CardEmpty from 'Utils/Cards/CardEmpty';
import { Field } from 'redux-form';
import { renderDollar, renderNumberPercentage } from '../../Utils/renderField/renderField';
import { RenderDollar } from '../../Utils/renderField/renderReadField';

const ComportamientoCreditoFiscal = props => {
    return (
        <div>
            <h5 className='fn-info-sb'>OTRAS VARIABLES</h5>
            <span className='fn-info-sb'>Variable por centrifugación</span>
            <div className='hr-info'>
                <hr />
            </div>
            <div className='fn-info'>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="credito5" className="m-0">RENDIMIENTO DE CENTRIFUGADO</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="var_yield" component={renderNumberPercentage} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">SKIM</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="var_skim" component={renderNumberPercentage} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">PÉRDIDA ESTIMADA EN CENTRIFUGACIÓN</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="var_lost" component={renderNumberPercentage} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
            </div>
            <span className='fn-info-sb'>Variable por manejo</span>
            <div className='hr-info'>
                <hr />
            </div>
            <div className='fn-info'>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="var_clot_wash" className="m-0">COÁGULO DE LAVADO DE PILAS Y CENTRIFUGAS</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="var_clot_wash" component={renderNumberPercentage} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">COÁGULO DE TRAMPAS</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="var_clot_traps" component={renderNumberPercentage} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">COÁGULO DE LATEX CENTRIFUGADO</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="var_clot_centrifuged" component={renderNumberPercentage} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
            </div>
            <span className='fn-info-sb'>Variables extras</span>
            <div className='hr-info'>
                <hr />
            </div>
            <div className='fn-info'>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="latex_makeup_price" className="m-0">PRECIO MAQUILA DE LATEX</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="latex_makeup_price" component={renderDollar} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="latex_makeup_price" className="m-0">PRECIO MAQUILA DE SOLIDOS/SKIM</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="solid_makeup_price" component={renderDollar} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-4 p-0">
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ComportamientoCreditoFiscal;