import { connect } from 'react-redux';
import { actions } from '../../../../../../redux/modules/reports/costoMP';
import { getMe } from "../../../../../../redux/modules/cuenta/login";
import CostoMateriaPrimaReport from './CostoMateriaPrimaReport';

const mstp = state => {
    return { ...state.costoMP }
};

const mdtp = {
    ...actions, getMe
};

export default connect(mstp, mdtp)(CostoMateriaPrimaReport)
