import React from 'react';
import { Route, Switch, } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

import { Login, Profile, Registro } from './common/components/LoginRegister';
import Home from './common/components/Home';
import ProtectedRoute from './ProtectedRoute';
import Examples from './common/components/Examples/Basic';
import NotFound from './common/components/layout/NotFound/NotFound';
import sendRecoverPassword from './common/components/LoginRegister/RecoverPassword/SendRecovery';
import PassRecover from './common/components/LoginRegister/ChangePassword/';
//
import '../assets/fonts/fonts.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Grids from "./common/components/Examples/Grids";
import Notificaciones from './common/components/Examples/Notificaciones';
import ExampleTabs from './common/components/Examples/Tabs/Tabs';
// Se quedan
//Usuarios
import { CrearUsuario, UsuariosList } from './common/components/App/Usuarios'
//Clientes
import { CustomerList, CreateCustomer } from './common/components/App/Admin/Customer';
//Puetos
import { PortList, CreatePort } from './common/components/App/Admin/DepurePort';
//Empaques
import { PackingList, CreatePacking } from './common/components/App/Admin/Packing';
// Transporte
import { TransportList, CreateTransport } from './common/components/App/Admin/Transport';
//EMAILS NOTIFICACIONES
import { EmailsCrear, EmailsListar } from './common/components/App/NotifiEmails';
// Roles
import { RolesCrear, RolesListar } from "./common/components/App/Roles";
// Administrativos
import { AdministrativosList, AdministrativosCrear } from './common/components/App/Administrative/Configuration';
import { AdministrativosResumen, AdministrativosEnCursoVer } from './common/components/App/Administrative/Summary';

import { InvestmentsList, InvestmentsCrear } from './common/components/App/Investments/Configuration';
import { InvestmentsSummary, InvestmentsInProgressShow, InvestmentsCreateAnnual } from './common/components/App/Investments/Summary';

// Sales Budget
import { SalesEdit, SalesList } from './common/components/App/Sales/configuration';
import { SalesShow, SalesListShow } from './common/components/App/Sales/summary';
// Shopping budget
import { ShoppingEdit } from './common/components/App/Shopping/configuration';
import { ShoppingShow } from './common/components/App/Shopping/summary';
// Finca budget
import { FincaEdit } from './common/components/App/Finca/configuration';
import { FincaEditSummary } from './common/components/App/Finca/summary';
// Latex Plant budget
import { PlantaLatexList } from './common/components/App/PlantaLatex/Configuration';
import { PlantaLatexSummary } from './common/components/App/PlantaLatex/Summary';
// Solid Plant budget
import { SolidPlantList } from './common/components/App/SolidsPlant/Configuration';
import { SolidPlantSummary } from './common/components/App/SolidsPlant/Summary';
// Logistics and export
import { LogisticsAndExportList } from './common/components/App/LogisitcsAndExport/Configuration';
import { LogisticsAndExportSummary } from './common/components/App/LogisitcsAndExport/Summary';

// presupuesto de deuda
import { DeptManagementSummary } from './common/components/App/DeptManagement/Summary';
import { DebtManagementEdit } from './common/components/App/DeptManagement/Configuration';
// Credito fiscal
import { TaxCreditListSummary } from './common/components/App/TaxCredit/Summary';
// Flujo de caja
import { CashFlowEdit } from './common/components/App/Report/CashFlow';

import { ComparativoFlujoCajaReport } from './common/components/App/Report/ComparativoFlujoCaja';
import { CostoVentasReport } from './common/components/App/Reports/CostoVentas';
import { CostoMateriaPrimaReport } from './common/components/App/Reports/CostoMateriaPrima';


require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
require('../style/index.css');

/**
 * Al agregar una ruta, verificar cómo se manejaría en la función isItemSelected() del componente Sidebar.
 */
module.exports = (
    <div>
        <div className="container__content">
            <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/registro" component={Registro} />
                <Route exact path="/recuperar_pass" component={sendRecoverPassword} />
                <Route exact path="/cambiar_pass/:token" component={PassRecover} />
                <ProtectedRoute exact path="/" component={Home}  requiredPermission="sensitivity_parameters"/>
                <ProtectedRoute exact path="/page2" component={Examples} />
                <ProtectedRoute exact path="/user-profile" component={Profile} />
                <ProtectedRoute exact path="/grids" component={Grids} />
                <ProtectedRoute exact path="/notifications" component={Notificaciones} />
                <ProtectedRoute exact path="/tabs" component={ExampleTabs} />
                {/*Usuarios*/}
                <ProtectedRoute exact path="/usuarios" component={UsuariosList} requiredPermission="users"/>
                <ProtectedRoute exact path="/usuario/crear" component={CrearUsuario} requiredPermission="users"/>
                <ProtectedRoute exact path="/usuario/:id/editar" component={CrearUsuario} requiredPermission="users"/>
                {/* Customer */}
                <ProtectedRoute exact path="/customers" component={CustomerList}  requiredPermission="customers"/>
                <ProtectedRoute exact path="/customer/crear" component={CreateCustomer}  requiredPermission="customers"/>
                <ProtectedRoute exact path="/customer/:id/editar" component={CreateCustomer}  requiredPermission="customers"/>
                {/* Puertos de salida */}
                <ProtectedRoute exact path="/ports" component={PortList} requiredPermission="port_of_departure" />
                <ProtectedRoute exact path="/port/crear" component={CreatePort}  requiredPermission="port_of_departure"/>
                <ProtectedRoute exact path="/port/:id/editar" component={CreatePort}  requiredPermission="port_of_departure"/>
                {/* Paqueteria */}
                <ProtectedRoute exact path="/packings" component={PackingList}  requiredPermission="packaging"/>
                <ProtectedRoute exact path="/packing/crear" component={CreatePacking}  requiredPermission="packaging"/>
                <ProtectedRoute exact path="/packing/:id/editar" component={CreatePacking}  requiredPermission="packaging"/>
                {/* Transporte */}
                <ProtectedRoute exact path="/transport" component={TransportList} requiredPermission="types_of_transport" />
                <ProtectedRoute exact path="/transport/crear" component={CreateTransport}  requiredPermission="types_of_transport"/>
                <ProtectedRoute exact path="/transport/:id/editar" component={CreateTransport}  requiredPermission="types_of_transport"/>
                {/*EnvioNotificiaciones*/}
                <ProtectedRoute exact path="/notificaciones" component={EmailsListar}/>
                <ProtectedRoute exact path="/notificaciones/crear" component={EmailsCrear}/>
                <ProtectedRoute exact path="/notificaciones/:id/editar" component={EmailsCrear}/>
                {/* Roles */}
                <ProtectedRoute exact path="/roles" component={RolesListar}  requiredPermission="roles"/>
                <ProtectedRoute exact path="/rol/crear" component={RolesCrear} requiredPermission="roles" />
                <ProtectedRoute exact path="/rol/:id/editar" component={RolesCrear}  requiredPermission="roles"/>
                {/* Administrativos */}
                <ProtectedRoute exact path="/administrativos/configuration" component={AdministrativosList} requiredPermission="administrative_configuration" />
                <ProtectedRoute exact path="/administrativos/configuration/crear" component={AdministrativosCrear}  requiredPermission="administrative_configuration"/>
                <ProtectedRoute exact path="/administrativos/configuration/:id/editar" component={AdministrativosCrear}  requiredPermission="administrative_configuration"/>
                {/* Administrativos resumen */}
                <ProtectedRoute exact path="/administrativos/summary" component={AdministrativosResumen}  requiredPermission="administrative_summary"/>
                <ProtectedRoute exact path="/administrativos/summary/:id/" component={AdministrativosEnCursoVer}  requiredPermission="administrative_summary"/>
                {/* Inversiones */}
                <ProtectedRoute exact path="/inversiones/configuration" component={InvestmentsList}  requiredPermission="investments_configuration"/>
                <ProtectedRoute exact path="/inversiones/configuration/crear" component={InvestmentsCrear}  requiredPermission="investments_configuration"/>
                <ProtectedRoute exact path="/inversiones/configuration/:id/editar" component={InvestmentsCrear}  requiredPermission="investments_configuration"/>
                {/* Inversiones resumen */}
                <ProtectedRoute exact path="/inversiones/summary" component={InvestmentsSummary}  requiredPermission="investments_summary"/>
                <ProtectedRoute exact path="/inversiones/summary/:id/" component={InvestmentsInProgressShow}  requiredPermission="investments_summary"/>

                <ProtectedRoute exact path="/inversiones/annual/crear" component={InvestmentsCreateAnnual}  requiredPermission="investments_summary"/>
                <ProtectedRoute exact path="/inversiones/annual/:id/editar" component={InvestmentsCreateAnnual}  requiredPermission="investments_summary"/>

                {/* Presupuesto de deudas */}

                <ProtectedRoute exact path="/deuda/summary" component={DeptManagementSummary}  requiredPermission="debt_management_summary"/>
                <ProtectedRoute exact path="/deuda/configuration" component={DebtManagementEdit}  requiredPermission="debt_management_configuration"/>

                {/* Presupuesto de ventas configuracion */}
                <ProtectedRoute exact path="/sales/configuration" component={SalesList}  requiredPermission="sales_configuration"/>
                <ProtectedRoute exact path="/sales/configuration/:id/" component={SalesList}  requiredPermission="sales_configuration"/>
                <ProtectedRoute exact path="/sales/configuration/:id/sub/:id/editar" component={SalesEdit}  requiredPermission="sales_configuration"/>
                {/* Presupuesto de ventas resumen */}
                <ProtectedRoute exact path="/sales/summary" component={SalesListShow}  requiredPermission="sales_summary"/>
                <ProtectedRoute exact path="/sales/summary/:id/" component={SalesListShow}  requiredPermission="sales_summary"/>
                <ProtectedRoute exact path="/sales/summary/:id/sub/:id/" component={SalesShow}  requiredPermission="sales_summary"/>
                {/* Presupuestos de compra configuracion */}
                <ProtectedRoute exact path="/shopping/configuration" component={ShoppingEdit}  requiredPermission="shopping_configuration"/>
                <ProtectedRoute exact path="/shopping/summary" component={ShoppingShow}  requiredPermission="shopping_summary"/>
                {/* Presupuesto de finca configuration */}
                <ProtectedRoute exact path="/finca/configuration" component={FincaEdit} requiredPermission="farm_configuration"/>
                <ProtectedRoute exact path="/finca/summary" component={FincaEditSummary}requiredPermission="farm_summary" />
                {/* Presupuesto de planta latex */}
                <ProtectedRoute exact path="/planta-latex/configuration" component={PlantaLatexList}requiredPermission="latex_configuration" />
                <ProtectedRoute exact path="/planta-latex/summary" component={PlantaLatexSummary}requiredPermission="latex_summary" />
                {/* Presupuesto de planta solidos */}
                <ProtectedRoute exact path="/planta-solidos/configuration" component={SolidPlantList} requiredPermission="solid_configuration"/>
                <ProtectedRoute exact path="/planta-solidos/summary" component={SolidPlantSummary}requiredPermission="solid_summary" />
                {/* {Presupuestos de logistica y exportacion} */}
                <ProtectedRoute exact path="/logistica/configuration" component={LogisticsAndExportList}requiredPermission="logistics_and_export_configuration" />
                <ProtectedRoute exact path="/logistica/summary" component={LogisticsAndExportSummary}requiredPermission="logistics_and_export_summary" />
                {/* Presupuesto de credito fiscal */}
                <ProtectedRoute exact path="/credito-fiscal/summary" component={TaxCreditListSummary} requiredPermission="tax_credit_summary"/>
                <ProtectedRoute exact path="/cash-flow/configuration" component={CashFlowEdit} requiredPermission="cash_flow"/>

                <ProtectedRoute exact path="/reports/comparativo-flujo-caja" component={ComparativoFlujoCajaReport} />
                <ProtectedRoute exact path="/reports/costo-venta" component={CostoVentasReport} />
                <ProtectedRoute exact path="/reports/costo-materia-prima" component={CostoMateriaPrimaReport} />

                <Route component={NotFound} />
            </Switch>
        </div>
        <NotificationContainer />
    </div>
);
