import React, { Component } from 'react';
import _ from 'lodash';
import { Field, FieldArray } from 'redux-form';
import { renderFieldDisabled, renderDollarDisabled, renderNumberPercentage, renderPercentageDisabled, renderNumberDisabled, renderToneladasDisabled } from '../../../../../Utils/renderField/renderField';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { months } from '../../../../../Utils/months';
class TableRow extends Component {
    componentDidMount() {
        this.handleInputChange();
    }
    componentDidUpdate(prevProps) {

        if (!_.isEqual(prevProps.propiedades, this.props.propiedades)) {
            this.handleInputChange();
        }
    }

    handleInputChange() {
        const { propiedades, change } = this.props;
        const { data, form_values } = propiedades;
        const { sales_data_solido, parameters_data, kg_proyectados } = data;
        // En todo el formulario, la segunda fila es ta el drc, ademas que por la magnitud del proyecto se 
        // usaron tablas y filas genericas lo que agiliza el proceso un algunas partes, y en otras no se tomo en cuenta 
        // un identificador mucho mas completo.
        const drc_values = form_values ? form_values.values.chipa_table[2] : [];
        let chipa_table = [];

        const {
            produces_latex,
            purchase_price_table,
        } = parameters_data;

        const kg_proyectados_data = kg_proyectados
        const sales_data_form = sales_data_solido
        const priceWithIva = purchase_price_table ? purchase_price_table.generic_row.find(item => item.name === 'PRECIO (CON IVA) SOLIDO') : [];
        const priceWithoutIva = purchase_price_table ? purchase_price_table.generic_row.find(item => item.name === 'PRECIO (SIN IVA) SOLIDO') : [];
        // Validacion de prima fn
        const meses = months;


        function convertToDecimalPercentage(value) {
            return value / 100;
        }

        const calculate = (data, factor) => {
            return meses.reduce((obj, mes) => {
                if (data[mes]) {
                    obj[mes] = data[mes] * factor;
                }
                return obj;
            }, {});
        }

        const const_shopping_chipa = 0.1
        const calculatedProductionFincaSolido = calculate(kg_proyectados_data, const_shopping_chipa);

        const CalculateEquivalentKilos = (sales_data_form, calculatedProductionFincaSolido) => {
            return meses.reduce((obj, mes) => {
                if (sales_data_form[mes] && calculatedProductionFincaSolido[mes]) {
                    produces_latex
                        ? obj[mes] = sales_data_form[mes] - calculatedProductionFincaSolido[mes]
                        : obj[mes] = sales_data_form[mes]
                }
                return obj;
            }, {});
        }

        let dataEquivalentKilos = CalculateEquivalentKilos(sales_data_form, calculatedProductionFincaSolido);

        const CalculateWetKilos = (drcData, dataEquivalentKilos) => {
            return meses.reduce((obj, mes) => {
                if (drcData[mes] && dataEquivalentKilos[mes]) {
                    obj[mes] = dataEquivalentKilos[mes] / convertToDecimalPercentage(drcData[mes]);
                }
                return obj;
            }, {});
        }

        // En esta funcion puede encontrar un 1 esta es una constante de formula
        const CalculateNetPayForPurchasesWithoutIva = (dataEquivalentKilos, priceWithoutIva) => {
            return meses.reduce((obj, mes) => {
                if (dataEquivalentKilos[mes] && priceWithoutIva[mes]) {
                    obj[mes] = dataEquivalentKilos[mes] * priceWithoutIva[mes];
                }
                return obj;
            }, {});
        }
        const netPayForPurchasesWithoutIva = CalculateNetPayForPurchasesWithoutIva(dataEquivalentKilos, priceWithoutIva)

        const CalculateNetPayForPurchasesWithIva = (dataEquivalentKilos, priceWithIva) => {
            return meses.reduce((obj, mes) => {
                if (dataEquivalentKilos[mes] && priceWithIva[mes]) {
                    obj[mes] = dataEquivalentKilos[mes] * priceWithIva[mes];
                }
                return obj;
            }, {});
        }
        const netPayForPurchasesWithIva = CalculateNetPayForPurchasesWithIva(dataEquivalentKilos, priceWithIva)

        const CalculateAmountofIvaPaid = (netPayForPurchasesWithoutIva, netPayForPurchasesWithIva) => {
            return meses.reduce((obj, mes) => {
                if (netPayForPurchasesWithoutIva[mes] && netPayForPurchasesWithIva[mes]) {
                    obj[mes] = netPayForPurchasesWithIva[mes] - netPayForPurchasesWithoutIva[mes];
                }
                return obj;
            }, {});
        }
        const amountofIvaPaid = CalculateAmountofIvaPaid(netPayForPurchasesWithoutIva, netPayForPurchasesWithIva)


        chipa_table = [
            { name: 'Compra de chipa (costo neto)', data: netPayForPurchasesWithoutIva },
            { name: 'kILOS hUMEDOS', total: 0, data: CalculateWetKilos(drc_values, dataEquivalentKilos), is_kg: true },
            { name: 'drc promedio general', data: drc_values, is_drc: true },
            { name: 'kilos secos equivalentes', data: dataEquivalentKilos, is_kg: true },
            { name: 'precio por kilo seco (sin iva)', data: priceWithoutIva },
            { name: 'precio por kilo seco (con iva)', data: priceWithIva },
            { name: 'PAGO NETO POR COMPRAS SIN IVA', data: netPayForPurchasesWithoutIva },
            { name: 'PAGO BRUTO POR OCMPRAS CON IVA', data: netPayForPurchasesWithIva , shopping_save:true},
            { name: 'IMPORTE DEL IVA PAGADO', data: amountofIvaPaid, is_iva_credit: true },
        ];

        chipa_table.forEach(item => {
            let total = 0;
            let count = 0;
            for (let mes in item.data) {
                total += item.data[mes];
                count++;
            }
            // Lista de nombres para los que se debe calcular el promedio
            const namesForAverage = [
                'precio por kilo seco (sin iva)',
                'precio por kilo seco (con iva)'
            ];

            if (namesForAverage.includes(item.name)) {
                item.total = total / count;
            } else {
                item.total = total;
            }
            Object.assign(item, item.data);
        });

        change('chipa_table', chipa_table);

    }

    render() {
        const { index, system, fields, step } = this.props;

        return (
            <React.Fragment key={index}>
                <tr
                    key={`system-${index}`}
                    style={{
                        ...(index === 6 || index === 7 || index === 8) && { backgroundColor: "#F0FFC7" },
                        borderBottom: (index === 0 || index === 5 || index === 6 || index === 7) ? "1px solid #035B2F" : "none"
                    }}
                >
                    <td className="text-center sin-borde-top first-column-shopping table-fixed-cell-left-0">
                        <Field
                            name={`${system}.name`}
                            type="text"
                            component={renderFieldDisabled}
                            isTextarea={false}
                            applyStyles={false}
                            className="form-control"
                            placeholder='Nuevo'
                        />
                    </td>
                    <td className='table-form-shopping-fixed-cell-left-1' style={{ width: "8%" }}>
                        <Field
                            name={`${system}.total`}
                            type="text"
                            disabled={true}
                            component={
                                fields.get(index).is_drc
                                    ? renderPercentageDisabled
                                    : fields.get(index).is_kg
                                        ? renderToneladasDisabled
                                        : renderDollarDisabled}
                            placeholder='0.00 %'
                            disabledStyle={index === 0 ? { fontWeight: 'bold' } : {}}
                            className="form-control"
                        />
                    </td>
                    {months.map((month) => (
                        <td key={month} style={{ width: "6.54%" }}>
                            <Field
                                name={`${system}.${month}`}
                                type="text"
                                component={
                                    step === 1
                                        ?
                                        (fields.get(index).is_drc
                                            ? renderNumberPercentage
                                            : fields.get(index).is_kg
                                                ? renderToneladasDisabled
                                                : renderDollarDisabled
                                        )
                                        :
                                        (fields.get(index).is_drc
                                            ? renderPercentageDisabled
                                            : fields.get(index).is_kg
                                                ? renderToneladasDisabled
                                                : renderDollarDisabled
                                        )

                                }
                                className="form-control"
                                placeholder={fields.get(index).is_drc ? '0.00 %' : '$ 0.00'}
                                disabledStyle={index === 0 ? { fontWeight: 'bold' } : {}}
                            />
                        </td>
                    ))}
                </tr>
            </React.Fragment>
        );
    }
}

class GenericTable extends Component {
    render() {
        const { fields, change, step, propiedades } = this.props;
        return (
            <div className=" col-sm-12 form-group np-r p-0">
                <div className='scroll-container'>
                    <div className='scroll-content-shopping'>
                        <table className="table mb-0 text-center table-striped">
                            <thead>
                                <tr>
                                    <th className="custom-cell text-center first-column-shopping text-center table-form-fixed-cell-left-0"></th>
                                    <th className="custom-cell text-center text-center table-form-shopping-fixed-cell-left-1">TOTAL ANUAL</th>
                                    <th style={{ width: "6.53%" }}>1</th>
                                    <th style={{ width: "6.53%" }}>2</th>
                                    <th style={{ width: "6.53%" }}>3</th>
                                    <th style={{ width: "6.53%" }}>4</th>
                                    <th style={{ width: "6.53%" }}>5</th>
                                    <th style={{ width: "6.53%" }}>6</th>
                                    <th style={{ width: "6.53%" }}>7</th>
                                    <th style={{ width: "6.53%" }}>8</th>
                                    <th style={{ width: "6.53%" }}>9</th>
                                    <th style={{ width: "6.53%" }}>10</th>
                                    <th style={{ width: "6.53%" }}>11</th>
                                    <th style={{ width: "6.53%" }}>12</th>
                                </tr>
                            </thead>
                            <tbody style={{ backgroundColor: "#F0FFC7" }}>
                                {fields.map((system, index) => <TableRow key={index} system={system} index={index} fields={fields} change={change} propiedades={propiedades} step={step} />)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

class ShoppingChipaTable extends Component {
    render() {
        const { change, step } = this.props;
        return (
            <div>
                <FieldArray
                    name='chipa_table'
                    change={change}
                    component={GenericTable}
                    step={step}
                    propiedades={this.props}
                />
            </div>
        );
    }
}

const formName = 'ShoppingForm';

const selector = formValueSelector(formName);

const mapStateToProps = (state) => {
    const categoryBudget = selector(state, 'generic_row') || [];
    const form_values = state.form.ShoppingForm || {};
    const allValues = categoryBudget.map(item => item);

    return { form_values, allValues };
};

export default connect(mapStateToProps)(ShoppingChipaTable);
