import React from 'react';
import { Field, FieldArray } from 'redux-form';
import { renderNumberKGDisabled, renderNumberPercentage } from '../../../../../Utils/renderField/renderField';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';

const calculateAdministrativeData = (fields) => {
    if (!fields || fields.length === 0) return;

    fields.forEach((item, index) => {
        const isInitialYear = index === 0;

        if (isInitialYear) {
            item.value_final = item.value_final;
        } else {
            const prevItem = fields[index - 1];
            const growthMultiplier = 1 + (item.growth_percentage / 100);
            const growthMultiplierSkim = 1 + (item.growth_percentage_skim/100)
            item.value_final = prevItem.value_final * growthMultiplier * growthMultiplierSkim ;
        }
    });
};

const ProjectionTable = ({ fields, years }) => {
    if (!years || years.length === 0) {
        return <div>No hay datos de años disponibles</div>;
    }

    return (
        <div className="table-responsive">
            <table className="table table-bordered text-center">
                <thead>
                    <tr className="table-header">
                        <th>Concepto</th>
                        {years.map((year, index) => (
                            <th key={`year-${year}-${index}`}>{year}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Proyección (kg)</td>
                        {fields.map((field, index) => (
                            <td key={index}>
                                <Field
                                    name={`${field}.value_final`}
                                    component={renderNumberKGDisabled}
                                    placeholder="0.00 kg"
                                    className="form-control"
                                />
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td>Crecimiento solido(%)</td>
                        {fields.map((field, index) => (
                            <td key={index}>
                                {index > 0 && (
                                    <Field
                                        name={`${field}.growth_percentage`}
                                        component={renderNumberPercentage}
                                        placeholder="0%"
                                        className="form-control"
                                    />
                                )}
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td>Crecimiento skim(%)</td>
                        {fields.map((field, index) => (
                            <td key={index}>
                                {index > 0 && (
                                    <Field
                                        name={`${field}.growth_percentage_skim`}
                                        component={renderNumberPercentage}
                                        placeholder="0%"
                                        className="form-control"
                                    />
                                )}
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

const AnnualProjections = props => {
    const { data, data_state } = props;
    
    calculateAdministrativeData(data_state);
    
    if (!data || !data.results || !data.data_to_serialize || !data.years_list) {
        return <div>Cargando datos...</div>;
    }

    const { data_to_serialize, years_list } = data;
    const projections = data_to_serialize.results.projections || [];
    const years = years_list || [];

    if (projections.length === 0 || years.length === 0) {
        return <div>Cargando datos de proyecciones...</div>;
    }

    const initializeProjectionData = (fields) => {
        if (fields.length === 0) {
            projections.forEach((data) => {
                fields.push(data);
            });
        }
    };

    return (
        <div className="administrative-annual-projections-container">
            <form onSubmit={props.handleSubmit}>
                <FieldArray
                    name="results.projections"
                    component={ProjectionTable}
                    years={years}
                    rerenderOnEveryChange={true}
                    props={{ initializeProjectionData }}
                />

                <div className='buttons-box mt-5'>
                    <button type="submit"
                        className="btn btn-primary m-4 btn-modal"
                    >
                        GUARDAR
                    </button>
                </div>
            </form>
        </div>
    );
};
const formName = 'FormSolidos';

const selector = formValueSelector(formName);

const mapStateToProps = (state) => {
    const data_state = selector(state, 'results.projections');
    return {
        data_state,
    };
};

export default connect(mapStateToProps)(
    reduxForm({
        form: formName,
    })(AnnualProjections)
);
