import React from 'react';
import { Field, FieldArray } from 'redux-form';
import { renderDollarDisabled } from '../../../../../Utils/renderField/renderField';
import { reduxForm } from 'redux-form';

const BudgetTable = ({ fields, years }) => {
    if (!years || years.length === 0) {
        return <div>No hay datos de años disponibles</div>;
    }

    return (
        <div className="table-responsive">
            <table className="table table-bordered text-center sales-budget-table">
                <thead>
                    <tr className="table-header">
                        <th>Concepto</th>
                        {years.map(year => (
                            <th key={year} className="year-header">{year}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {fields.map((field, index) => {
                        const fieldData = fields.get(index);
                        const rowStyle = fieldData && fieldData.name === 'TOTAL'
                            ? { backgroundColor: "#F0FFC7", borderBottom: '1px solid #035B2F', borderTop: '1px solid #035B2F' }
                            : {};
                        return (
                            <React.Fragment key={index}>
                                <tr style={rowStyle}>
                                    <td>{`Proyección ${fieldData && fieldData.name ? fieldData.name : ''}`}</td>
                                    {years.map(( year, yearIndex) => (
                                        <td key={yearIndex}>
                                            <Field
                                                name={`${field}.annual_projection_row[${yearIndex}].total`}
                                                component={renderDollarDisabled}
                                                placeholder="0 Kg"
                                                suffix={" kg"}
                                                className="form-control"
                                            />
                                        </td>
                                    ))}
                                </tr>
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );

};



const AnnualProjectionsLogistics = props => {
    const { data } = props;
    if (!data || !data.results) {
        return <div>Cargando datos...</div>;
    }
    const { results } = data;
    const logistics_data = results.logistics_data || [];
    const years = results.years || [];

    if (logistics_data.length === 0 || years.length === 0) {
        return <div>Cargando datos ...</div>;
    }

    const initializeSalesData = (fields) => {
        if (fields.length === 0) {
            logistics_data.forEach((data) => {
                fields.push(data);
            });
        }
    };

    return (
        <div className="sales-annual-projections-container">
            <form onSubmit={props.handleSubmit}>
                <h4>Presupuesto anualizado de logística y exportación</h4>
                <FieldArray
                    name="logistics_data"
                    component={BudgetTable}
                    years={years}
                    rerenderOnEveryChange={true}
                    props={{ initializeSalesData }}

                />
            </form>
        </div>
    );
};

const formName = 'FormLogistics';

export default (
    reduxForm({
        form: formName,
    })(AnnualProjectionsLogistics)
);

