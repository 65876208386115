import React, { Fragment } from 'react';


function Card(props) {
    const { noBorder } = props;
    return (
        <Fragment>
            <div className="card-main">
                <img src={require(`assets/img/icons/${props.icon}`)} alt="Usuario" className="title_img mr-2" />
                <img src={require('assets/img/icons/linea.png')} alt="Linea" className="title_img mr-2" />
                <h2 className="mr-2 titulo_principal">{props.titulo}</h2>
                <img src={require('assets/img/icons/ellipse_mini.png')} alt='ellipce' className='ellipse_mini' />
                <h3 className="subtitulo">  {props.subtitulo}</h3>
                <img src={require('assets/img/icons/ellipse_mini.png')} alt='ellipce' className='ellipse_mini' />
            </div>
            <div className={`mb-4${noBorder ? '' : ' card-tabla'}`}>
                <div className="card-content p-0 px-3 pt-3">
                    {props.children}
                </div>
            </div>
        </Fragment>

    )
}

export default Card;
