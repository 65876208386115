import React, { Component } from 'react'
import Grid from "../../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../../Utils/Grid/StandardActions";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";

export default class CustomerList extends Component {
    componentDidMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar: onPageChange, onSortChange, eliminar } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        return (
            <div className="mb-4 col-12">
                <Card
                    icon="configuraciones_activo.png"
                    titulo="CLIENTES"
                    subtitulo="Entre Ríos">
                    <Header to="customer/crear" textBtn="CREAR CLIENTE" {...this.props} />
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                        page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            width='20%'
                            dataFormat={standardActions({ editar: "customer", eliminar })}
                        />
                        <TableHeaderColumn
                            isKey
                            dataField="company"
                            dataSort
                        >
                            EMPRESA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="name"
                            dataSort
                        >
                            NOMBRE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="last_name"
                            dataSort
                        >
                            APELLIDO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="credit_days"
                            dataSort
                        >
                            DÍAS DE CRÉDITO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="phone"
                            dataSort
                        >
                            TELÉFONO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="identification_number"
                            dataSort
                        >
                            NUMERO DE IDENTIFICACIÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="email"
                            dataSort
                        >
                            CORREO ELECTRONICO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="country"
                            dataSort
                        >
                            PAIS
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
