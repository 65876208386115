import React, { useState, useEffect } from 'react';
import { Modal } from 'react-responsive-modal';
import { Field, FieldArray, SubmissionError, reduxForm } from 'redux-form';
import { renderDollar, renderField, renderFieldDisabled, renderSwitch } from '../../../../../Utils/renderField/renderField';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import Alerta from '../../../../../Utils/Alerta/Alerta';
import { api } from 'api';
import RecetaRow from './RecetaRow';
import { validate } from './validate';


const TableCategory = ({ fields, portId, change, dollarExchange, }) => {

    const fetchData = (endpoint, params) => {
        return api.get(endpoint, { params })
            .then((data) => {
                if (data) return data.results;
                return [];
            })
            .catch(() => {
                return [];
            });
    };

    const getTypeOfTransport = (search) => {
        return fetchData('transport', {
            search
        });
    };

    const handleAddField = () => {
        const initialValues = {
            portId: portId
        };
        fields.push(initialValues);
    };

    return (
        <div className="tab-finca">
            <div className="col-sm-12 form-group np-r p-0">
                <div className="react-bs-container-header table-header-wrapper">
                    <table className="table mb-0">
                        <thead>
                            <tr>
                                <th className="text-center sin-borde-top" style={{ width: '15%', }}>RUBRO</th>
                                {<th className="text-center sin-borde-top" style={{ width: '30%' }}>TRANSPORTE</th>}
                                <th className="text-center sin-borde-top" style={{ width: '15%' }}>COSTO Q</th>
                                <th className="text-center sin-borde-top" style={{ width: '15%' }}>¿Genera IVA?</th>
                                <th className="text-center sin-borde-top" style={{ width: '10%' }}>ACCIONES</th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className="tabla-con-borde">
                    <table className="table table-sm m-0 table-striped">
                        <tbody>
                            {fields.map((system, index) => (
                                <RecetaRow
                                    key={index}
                                    system={system}
                                    index={index}
                                    fields={fields}
                                    change={change}
                                    getTypeOfTransport={getTypeOfTransport}
                                    dollarExchange={dollarExchange}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="mt-2">
                    <div
                        type="button"
                        className="btn btn-light btn-lg btn-block d-flex justify-content-end"
                        onClick={handleAddField}
                    >
                        AGREGAR
                    </div>
                </div>
            </div>
        </div>
    );
};

const RecipeModal = props => {
    const { open,
        closeModal,
        handleAddCategory,
        initialValues,
        message,
        change,
        dollarExchange,
        reset,
    }
        = props
    const [ports, setPorts] = useState(initialValues.ports || []);
    useEffect(() => {
        setPorts(initialValues.ports || []);
        handleRecipeChange(true)
    }, [initialValues]);

    const fetchData = (endpoint, params) => {
        return api.get(endpoint, { params })
            .then((data) => {
                if (data) return data.results;
                return [];
            })
            .catch(() => {
                return [];
            });
    };

    const handleRecipeChange = async (event) => {
        if (event) {
            const data = await fetchData('/port', {});
            setPorts(data);
        } else {
            setPorts([]);
        }
    };

    const handleCloseModal = () => {
        closeModal();
        reset();
    };

    const handleAddCategoryInternal = (values) => {
        const formData = {
            id: initialValues.id ? initialValues.id : undefined,
            ModalData: values.recipe
        };
        handleAddCategory(formData, reset);
    };

    return (
        <Modal
            open={open}
            onClose={closeModal}
            center
            classNames={{
                overlayAnimationIn: 'customEnterOverlayAnimation',
                overlayAnimationOut: 'customLeaveOverlayAnimation',
                modalAnimationIn: 'customEnterModalAnimation',
                modalAnimationOut: 'customLeaveModalAnimation',
                modal: 'customModalSizeLatex',
            }}
        >
            <form onSubmit={props.handleSubmit(handleAddCategoryInternal.bind(this))}>
                <div className="pb-4 titulo-modal d-flex justify-content-center">
                    <div className="col-md-12 p-0 mt-2 text-center">
                        <h4>COSTOS DE LOGÍSTICA Y EXPORTACIÓN POR CONTENEDOR</h4>
                    </div>
                </div>
                <div className="row justify-content-center col-12 p-0">
                    <div className="text-center">
                        <Alerta texto={
                            'La generación de IVA, al ser activada, se tomará en cuenta para todos los puertos en donde aplique'
                        } />
                    </div>
                </div>
            <div className="row col-12 p-0">
                {message && <div className="alerta error_mensaje">{message}</div>}
            </div>
            <div className="position-relative">
                {
                    ports.map((port, index) => (
                        <div key={index}>
                            <h4 style={{ margin: '0 0 5px 20px' }}>{`${port.name}`}</h4>
                            <FieldArray
                                key={index}
                                name={`recipe[${port.id}]`}
                                portId={`${port.id}`}
                                component={TableCategory}
                                change={change}
                                initialValues={initialValues}
                                dollarExchange={dollarExchange}
                            />
                        </div>
                    ))
                }
                <div className="buttons-box mt-4 mb-4">
                    <button
                        className="btn btn-outline-dark mr-3 btn-modal"
                        onClick={(e) => {
                            e.preventDefault();
                            handleCloseModal();
                        }}
                    >
                        CANCELAR
                    </button>
                    <button type="submit" className="btn btn-primary btn-modal">
                        CONTINUAR
                    </button>
                </div>
            </div>
        </form>
        </Modal >
    );
};

const formName = 'RecipeModal';

const ConnectedRecipeModal = RecipeModal;

export default reduxForm({
    form: formName,
    validate
})(ConnectedRecipeModal);
