import React, { Component } from 'react'
import LoadMask from 'Utils/LoadMask/LoadMask';
import CardFormNoBorder from '../../../../Utils/Cards/CardFormNoBorder';
import Tabs, { TabPane } from 'rc-tabs';
import CostoVentasForm from './CostoVentasForm';

export default class CostoVentasReport extends Component {

    state = {
        open: false,
    };
    componentDidMount() {
        if (this.props.tab === 'PROYECCIONES_ANUALES') {
            this.props.summary_annual_list()
        } else {
            this.props.getDollar()
            this.props.read()

        }
    }
    openModalCategory = () => {

        this.setState({ open: true });

    };
    closeModalCategory = () => {
        this.setState({ open: false });

    };

    actualizar = (data) => {
        this.props.editar(1, data);
    };

    getPermissions = () => {
        const { getMe } = this.props;
        getMe();
    }

    updateAnnual = (data) => {
        this.props.edit_annual(data);
    };

    render() {
        const { tab, setTab } = this.props;
        const { updateData, loader, item } = this.props;


        return (
            <div className="mb-4 col-12">
                <CardFormNoBorder
                    icon="Side_activos/finca.svg"
                    titulo="COSTO DE VENTAS"
                    subtitulo="RESUMEN"
                    subtituloForm={"VER"}
                    col="12"
                    noShadow
                >

                </CardFormNoBorder>
                <Tabs
                    defaultActiveKey={tab}
                    onChange={tab => setTab(tab)}
                    animated={{ tabPane: true, inkBar: true }}
                >
                    <TabPane tab='COSTO DE VENTAS' key="COSTO_DE_VENTAS">
                        <LoadMask loading={loader} blur>
                            <CostoVentasForm
                                onSubmit={this.actualizar}
                                updateData={updateData}
                                item={item}
                                editando={true}
                                permisos={this.getPermissions}
                                openModal={this.openModalCategory}
                                closeModal={this.closeModalCategory}
                                open={this.state.open}
                                {...this.props}
                            />
                        </LoadMask>
                    </TabPane>
                    <TabPane tab='COSTO REAL DE VENTAS' key="COSTO_REAL_DE_VENTAS">
                        <LoadMask loading={loader} blur>
                            <CostoVentasForm
                                onSubmit={this.actualizar}
                                updateData={updateData}
                                item={item}
                                editando={true}
                                permisos={this.getPermissions}
                                openModal={this.openModalCategory}
                                closeModal={this.closeModalCategory}
                                open={this.state.open}
                                {...this.props}
                            />
                        </LoadMask>
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}

