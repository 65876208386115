import React, { useState } from 'react';
import { Field, FieldArray } from 'redux-form';
import { renderDollar, renderFieldDisabled, renderCurrency, renderFieldCheck, renderDollarDisabled, renderCurrencyDisabled } from '../../../../Utils/renderField/renderField';
import { renderField } from "../../../../Utils/renderField/renderField"
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { TYPE_OF_ROW } from '../../../../../../utility/constants';
import { months } from '../../../../Utils/months';
import Swal from 'sweetalert2';
const alerta = require('assets/img/icons/alerta_roja.png');


const TableRow = ({ system, index, fields, change, eliminar, currency }) => {
    const _months = months
    const is_manual = fields.get(index).is_manual;
    const isTitulo = fields.get(index).isTitulo;
    const isEntradas = fields.get(index).type_of_flow === "salidas";
    const isFinal = fields.get(index).isFinal
    const isOpeningBalance = fields.get(index).isOpeningBalance

    React.useEffect(() => {
        change(`${system}.is_dollar`, currency === 'USD');
    }, [currency, system, change]);

    const calculateTotalForRow = () => {
        const systemData = fields.get(index);
        if (!systemData) return 0;

        let totalForRow = _months.reduce((accumulatedTotal, month) => {
            const value = parseFloat(systemData[month]) || 0;
            accumulatedTotal += value;
            return accumulatedTotal;
        }, 0);
        return totalForRow;
    };
    const disabledStyle = isEntradas ? { color: 'red' } : {}
    const style = isEntradas ? { width: "8%", color: 'red' } : { width: "8%" }
    const _style = isFinal ? { backgroundColor: "#F0FFC7" } : style
    return (
        <React.Fragment key={index}>
            <tr key={`system-${index}`}>
                <td className='first-column table-form-fixed-cell-left-0' style={!isFinal ? {} : { backgroundColor: "#F0FFC7" }} >
                    <Field
                        name={`${system}.name`}
                        type="text"
                        isTextarea={!isTitulo}
                        applyStyles={!isTitulo}
                        style={disabledStyle}
                        component={is_manual ? renderField : renderFieldDisabled}
                        className="form-control"
                        placeholder='Nuevo'
                    />
                </td>
                <td className='table-form-fixed-cell-left-1' style={_style}>

                    {
                        currency === 'USD'
                            ?
                            new Intl.NumberFormat('es-US', { style: 'currency', currency: 'USD' }).format(calculateTotalForRow(true))
                            :
                            new Intl.NumberFormat('es-GT', { style: 'currency', currency: 'GTQ' }).format(calculateTotalForRow())
                    }

                </td>
                {!isOpeningBalance ?
                    months.map((month) => (
                        <td key={month} style={!isFinal ? { width: "6.9%" } : { width: "6.9%", backgroundColor: "#F0FFC7" }}>
                            <Field
                                name={`${system}.${month}`}
                                type="text"
                                component={currency !== 'USD'
                                    ?
                                    is_manual ? renderCurrency : renderCurrencyDisabled
                                    :
                                    is_manual ? renderDollar : renderDollarDisabled
                                }
                                disabledStyle={disabledStyle}
                                className="form-control"
                                placeholder={fields.get(index).is_dollar ? '$ 0.00' : 'Q 0.00'}
                            />
                        </td>
                    ))
                    :
                    <td style={!isFinal ? { width: "6.9%" } : { width: "6.9%", backgroundColor: "#F0FFC7" }}>
                        <Field
                            name={`${system}.${'enero'}`}
                            type="text"
                            component={currency !== 'USD' ? renderCurrency : renderDollar}
                            disabledStyle={disabledStyle}
                            className="form-control"
                            placeholder={fields.get(index).is_dollar ? '$ 0.00' : 'Q 0.00'}
                        />
                    </td>
                }
            </tr>
        </React.Fragment>
    );
};

const GenericTable = ({ fields, dollar_exchange, allValues, change, eliminar, currency }) => {
    const _months = months
    const calculateTotalForMonth = (month, isDollar) => {
        const allFields = fields.getAll() || [];
        let total = allFields.reduce((total, system) => {
            let value = parseFloat(system[month]) || 0;

            if (system.is_dollar) {
                value *= dollar_exchange;
            }

            return total + value;
        }, 0);
        if (isDollar && dollar_exchange) {
            total /= dollar_exchange;
        }
        return total;
    };
    const columnas = Array.from({ length: 12 }, (_, index) => (
        <th key={index} style={{ width: "6.9%" }}>{index + 1}</th>
    ));

    return (
        <div className=" col-sm-12 form-group np-r p-0">
            <div className='scroll-container'>
                <div className='scroll-content-shopping'>
                    <table className="table mb-0 text-center table-striped">
                        <thead>
                            <tr>
                                <th className='first-column table-form-fixed-cell-left-0'>MES</th>
                                <th className='table-form-fixed-cell-left-1'>TOTAL</th>
                                {columnas}
                            </tr>
                        </thead>
                        <tbody style={{ backgroundColor: "#F0FFC7" }}>
                            {fields.map((system, index) => <TableRow key={index} system={system} index={index} fields={fields} change={change} eliminar={eliminar} currency={currency} />)}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

const ResumenTable = ({ dollar_exchange, allValues, change, eliminar, currency }) => {

    return (
        <div>
            <FieldArray
                name={`summary`}
                dollar_exchange={dollar_exchange}
                component={GenericTable}
                allValues={allValues}
                change={change}
                eliminar={eliminar}
                currency={currency}
            />
        </div>
    );
}

const formName = 'ComparativoFlujoCajaForm';

const selector = formValueSelector(formName);

const mapStateToProps = (state) => {

    const categoryBudget = selector(state, 'operation.salidas') || [];
    const allValues = categoryBudget.map(item => item);
    return { allValues };

};

export default connect(mapStateToProps)(ResumenTable);
