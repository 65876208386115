import React, { useState, useEffect } from "react";
import Card from 'Utils/Cards/Card';
import Listado from "./Listado";
import ConnectedCategoriaModal from './Modal/CategoriaModal';
import Tabs, { TabPane } from 'rc-tabs';
import CardFormNoBorder from "../../../../Utils/Cards/CardFormNoBorder";
import CardEmpty from "../../../../Utils/Cards/CardEmpty";
import AnnualProjectionSolidBudget from './AnnualProjections/AnnualProjectionsSolidBudget'
import AnnualProjectionsCrecimiento from "./AnnualProjections/AnnualProjectionsCrecimiento";

const SolidPlant = (props) => {
    const { readAllShow, data, loader, readShow, data_modal, tab, setTab,edit_annual } = props;
    const { results } = data_modal;
    const [open, setOpen] = useState(false);
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        readAllShow();
    }, [readAllShow]);

    const openModal = () => {
        setOpen(true);
    };

    const closeModal = () => {
        setOpen(false);
        setInitialValues({});
    };
    const updateAnnual = (data) => {
        edit_annual(data);
    };

    return (

        <div className="mb-4 col-12">
            <CardFormNoBorder
                noShadow
                icon="Side_activos/Admin.svg"
                titulo="PLANTA SOLIDOS"
                subtitulo="RESUMEN"
            >
            </CardFormNoBorder>
            <Tabs
                defaultActiveKey='EN_CURSO'
                onChange={tab => setTab(tab)}
                animated={{ tabPane: true, inkBar: true }}
            >
                <TabPane tab='EN CURSO' key="EN_CURSO">
                    <CardEmpty noShadow
                        isMarginButtom>
                        <div className="row col-md-12 p-0">
                            <div className="col-md-6">
                                <h4 className="mr-2 titulo_principal">PLANTAS SOLIDOS</h4>
                            </div>
                        </div>
                        <Listado data={data} loader={loader} openModal={openModal} readShow={readShow} {...props} />
                    </CardEmpty>

                </TabPane>

                <TabPane tab='PROYECCIONES ANUALES' key="PROYECCIONES_ANUALES">
                    <CardEmpty noShadow
                        isMarginButtom>
                        <div className="row col-md-12 p-0">
                            <div className="col-md-6">
                                <h4 className="mr-2 titulo_principal">CRECIMIENTO DE KILOS SECOS DE MAQUILLA</h4>
                            </div>
                        </div>
                        <AnnualProjectionsCrecimiento  {...props} onSubmit={updateAnnual} />
                    </CardEmpty>
                    <CardEmpty noShadow
                        isMarginButtom>
                        <div className="row col-md-12 p-0">
                            <div className="col-md-6">
                                <h4 className="mr-2 titulo_principal">PLANTAS SOLIDOS ANUAL</h4>
                            </div>
                        </div>
                        <AnnualProjectionSolidBudget  {...props} />
                    </CardEmpty>
                </TabPane>
            </Tabs>

            <ConnectedCategoriaModal
                open={open}
                closeModal={closeModal}
                initialValues={results || initialValues}
                {...props}
            />
        </div>
    );
};

export default SolidPlant;
