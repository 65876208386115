import React, { Component } from 'react'
import LoadMask from 'Utils/LoadMask/LoadMask';
import SalesForm from './SalesForm';

export default class SalesShow extends Component {
    constructor(props) {
        super(props);
        this.handleGoBack = this.handleGoBack.bind(this);
    }

    handleGoBack() {
        this.props.history.goBack();
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.props.read(this.props.match.params.id);
        }
    }
    actualizar = (data) => {
        this.props.editar(this.props.match.params.id, data);
    };

    getPermissions = () => {
        const { getMe } = this.props;
        getMe();
    }

    render() {
        const { updateData, loader} = this.props;
        return (
            <div className="mb-4 col-12">
                <LoadMask loading={loader} blur>
                    <SalesForm
                        onSubmit={this.actualizar}
                        updateData={updateData}
                        permisos={this.getPermissions}
                        regresar={this.handleGoBack}
                        {...this.props}
                    />
                </LoadMask>
            </div>
        )
    }
}

