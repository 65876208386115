import React, { Component } from 'react';
import { Field, FieldArray } from 'redux-form';
import { renderFieldDisabled, renderDollarDisabled, renderPercentageDisabled, renderNumber, renderToneladasDisabled } from '../../../../../Utils/renderField/renderField';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { months } from '../../../../../Utils/months';

class TableRow extends Component {
    componentDidMount() {
        this.handleInputChange();
    }

    handleInputChange() {
        const { propiedades, change } = this.props;
        const { data } = propiedades;
        const { sales_data_latex, parameters_data, kg_proyectados, results } = data;
        const { drc_promedio_general_latex } = results;
        const { generic_row } = drc_promedio_general_latex;

        const drc_values = generic_row ? generic_row[0] : [];
        let latex_table = [];

        const {
            var_yield,
            var_clot_wash,
            var_clot_traps,
            var_clot_centrifuged,
            produces_latex,
            var_lost,
            purchase_price_table,
            prima_for_production_nf,
            prima_for_production_nf_porcentage
        } = parameters_data;

        const kg_proyectados_data = kg_proyectados;
        const sales_data_form = sales_data_latex;
        const priceWithIva = purchase_price_table ? purchase_price_table.generic_row.find(item => item.name === 'PRECIO (CON IVA) LATEX') : [];
        const priceWithoutIva = purchase_price_table ? purchase_price_table.generic_row.find(item => item.name === 'PRECIO (SIN IVA) LATEX') : [];
        // Validacion de prima fn
        const prima_for_production_nf_data = prima_for_production_nf_porcentage > 0 ? prima_for_production_nf : 0;
        const meses = months;


        function convertToDecimalPercentage(value) {
            return value / 100;
        }

        var yeild = convertToDecimalPercentage(var_yield);

        const calculate = (data, factor) => {
            return meses.reduce((obj, mes) => {
                if (data[mes]) {
                    obj[mes] = data[mes] * factor;
                }
                return obj;
            }, {});
        }

        const const_shopping_latex = 0.9
        const calculatedProductionFincaLatex = calculate(kg_proyectados_data, const_shopping_latex * yeild);

        const CalculateEquivalentKilos = (sales_data_pros, calculatedProductionFincaLatex, params) => {
            if (yeild === 0) {
                throw new Error('yeild cannot be 0');
            }

            return meses.reduce((obj, mes) => {
                if (sales_data_pros[mes] && calculatedProductionFincaLatex[mes]) {
                    const calculatedLatexPerYeild = produces_latex ? calculatedProductionFincaLatex[mes] / yeild : null;
                    obj[mes] = (sales_data_pros[mes] - calculatedLatexPerYeild) * params;
                }
                return obj;
            }, {});
        }

        let porcentageParams = 1 + (
            convertToDecimalPercentage(var_clot_wash) +
            convertToDecimalPercentage(var_clot_traps) +
            convertToDecimalPercentage(var_clot_centrifuged) +
            convertToDecimalPercentage(var_lost)
        );

        let dataEquivalentKilos = CalculateEquivalentKilos(sales_data_form, calculatedProductionFincaLatex, porcentageParams);

        const CalculateWetKilos = (drcData, dataEquivalentKilos) => {
            return meses.reduce((obj, mes) => {
                if (drcData[mes] && dataEquivalentKilos[mes]) {
                    obj[mes] = dataEquivalentKilos[mes] / convertToDecimalPercentage(drcData[mes]);
                }
                return obj;
            }, {});
        }

        const CalculatePriceWithoutIvaNF = (priceWithoutIva, prima_for_production_nf_data) => {
            return meses.reduce((obj, mes) => {
                if (priceWithoutIva[mes]) {
                    obj[mes] = prima_for_production_nf_data !== 0 ? priceWithoutIva[mes] + prima_for_production_nf_data : priceWithoutIva[mes];
                }
                return obj;
            }, {});
        }
        const PriceWithoutIvaNF = CalculatePriceWithoutIvaNF(priceWithoutIva, prima_for_production_nf_data)

        const monthlyDebit = 1.12
        const CalculatePriceWithIvaNF = (PriceWithoutIvaNF, monthlyDebit) => {
            return meses.reduce((obj, mes) => {
                if (PriceWithoutIvaNF[mes]) {
                    obj[mes] = PriceWithoutIvaNF[mes] * monthlyDebit;
                }
                return obj;
            }, {});
        }
        const priceWithIvaNF = CalculatePriceWithIvaNF(PriceWithoutIvaNF, monthlyDebit)
        // En esta funcion puede encontrar un 1 esta es una constante de formula
        const CalculateNetPayForPurchasesWithoutIva = (dataEquivalentKilos, priceWithoutIva, PriceWithoutIvaNF, prima_for_production_nf_porcentage) => {
            return meses.reduce((obj, mes) => {
                if (dataEquivalentKilos[mes] && priceWithoutIva[mes] && PriceWithoutIvaNF[mes]) {
                    obj[mes] = prima_for_production_nf_porcentage !== 0
                        ? (dataEquivalentKilos[mes] * (1 - convertToDecimalPercentage(prima_for_production_nf_porcentage)) * priceWithoutIva[mes]) + (dataEquivalentKilos[mes] * convertToDecimalPercentage(prima_for_production_nf_porcentage) * PriceWithoutIvaNF[mes])
                        : (dataEquivalentKilos[mes] * 1 * priceWithoutIva[mes]);
                }
                return obj;
            }, {});
        }
        const netPayForPurchasesWithoutIva = CalculateNetPayForPurchasesWithoutIva(dataEquivalentKilos, priceWithoutIva, PriceWithoutIvaNF, prima_for_production_nf_porcentage)

        const CalculateNetPayForPurchasesWithIva = (dataEquivalentKilos, priceWithIva, priceWithIvaNF, prima_for_production_nf_porcentage) => {
            return meses.reduce((obj, mes) => {
                if (dataEquivalentKilos[mes] && priceWithIva[mes] && priceWithIvaNF[mes]) {
                    obj[mes] = prima_for_production_nf_porcentage !== 0
                        ? (dataEquivalentKilos[mes] * (1 - convertToDecimalPercentage(prima_for_production_nf_porcentage)) * priceWithIva[mes]) + (dataEquivalentKilos[mes] * convertToDecimalPercentage(prima_for_production_nf_porcentage) * priceWithIvaNF[mes])
                        : (dataEquivalentKilos[mes] * 1 * priceWithIva[mes]);
                }
                return obj;
            }, {});
        }
        const netPayForPurchasesWithIva = CalculateNetPayForPurchasesWithIva(dataEquivalentKilos, priceWithIva, priceWithIvaNF, prima_for_production_nf_porcentage)

        const CalculateAmountofIvaPaid = (netPayForPurchasesWithoutIva, netPayForPurchasesWithIva) => {
            return meses.reduce((obj, mes) => {
                if (netPayForPurchasesWithoutIva[mes] && netPayForPurchasesWithIva[mes]) {
                    obj[mes] = netPayForPurchasesWithIva[mes] - netPayForPurchasesWithoutIva[mes];
                }
                return obj;
            }, {});
        }
        const amountofIvaPaid = CalculateAmountofIvaPaid(netPayForPurchasesWithoutIva, netPayForPurchasesWithIva)

        const CalculatePricePerKiloCombinedWithIva = (dataEquivalentKilos, netPayForPurchasesWithIva) => {
            return meses.reduce((obj, mes) => {
                if (dataEquivalentKilos[mes] && netPayForPurchasesWithIva[mes]) {
                    obj[mes] = netPayForPurchasesWithIva[mes] / dataEquivalentKilos[mes];
                }
                return obj;
            }, {});
        }
        const pricePerKiloCombinedWithIva = CalculatePricePerKiloCombinedWithIva(dataEquivalentKilos, netPayForPurchasesWithoutIva)

        const CalculatePricePerKiloCombinedWithoutIva = (dataEquivalentKilos, netPayForPurchasesWithoutIva) => {
            return meses.reduce((obj, mes) => {
                if (dataEquivalentKilos[mes] && netPayForPurchasesWithoutIva[mes]) {
                    obj[mes] = netPayForPurchasesWithoutIva[mes] / dataEquivalentKilos[mes];
                }
                return obj;
            }, {});
        }
        const pricePerKiloCombinedWithoutIva = CalculatePricePerKiloCombinedWithoutIva(dataEquivalentKilos, netPayForPurchasesWithoutIva)


        latex_table = [
            { name: 'Compra de látex (costo neto)', data: netPayForPurchasesWithoutIva },
            { name: 'kILOS hUMEDOS', total: 0, data: CalculateWetKilos(drc_values, dataEquivalentKilos), is_kg: true },
            { name: 'drc promedio general', data: drc_values, is_drc: true },
            { name: 'kilos secos equivalentes', data: dataEquivalentKilos, is_kg: true },
            { name: 'precio por kilo seco (sin iva)', data: priceWithoutIva },
            { name: 'precio por kilo seco (con iva)', data: priceWithIva },
            { name: 'Precio PRIMA por kilo seco NF (SIN IVA)', data: PriceWithoutIvaNF },
            { name: 'Precio PRIMA por kilo seco NF (CON IVA)', data: priceWithIvaNF },
            { name: 'precio por kilo seco (sin iva) combinado', data: pricePerKiloCombinedWithoutIva },
            { name: 'precio por kilo seco (con iva) combinado', data: pricePerKiloCombinedWithIva },
            { name: 'PAGO NETO POR COMPRAS SIN IVA', data: netPayForPurchasesWithoutIva },
            { name: 'PAGO BRUTO POR COMPRAS CON IVA', data: netPayForPurchasesWithIva },
            { name: 'IMPORTE DEL IVA PAGADO', data: amountofIvaPaid },
        ];

        latex_table.forEach(item => {
            let total = 0;
            let count = 0;
            for (let mes in item.data) {
                total += item.data[mes];
                count++;
            }
            // Lista de nombres para los que se debe calcular el promedio
            const namesForAverage = [
                'precio por kilo seco (sin iva)',
                'precio por kilo seco (con iva)',
                'Precio PRIMA por kilo seco NF (SIN IVA)',
                'Precio PRIMA por kilo seco NF (CON IVA)',
                'precio por kilo seco (sin iva) combinado',
                'precio por kilo seco (con iva) combinado'
            ];
            if (namesForAverage.includes(item.name)) {
                item.total = total / count;
            } else {
                item.total = total;
            }
            Object.assign(item, item.data);
        });

        change('latex_table', latex_table);
    }

    render() {
        const { index, system, fields } = this.props;
        return (
            <React.Fragment key={index}>
                <tr
                    key={`system-${index}`}
                    style={{
                        ...(index === 10 || index === 11 || index === 12) && { backgroundColor: "#F0FFC7" },
                        borderBottom: (index === 0 || index === 9 || index === 10 || index === 11) ? "1px solid #035B2F" : "none"
                    }}
                >
                    <td className='first-column-shopping table-form-fixed-cell-left-0'>
                        <Field
                            name={`${system}.name`}
                            type="text"
                            component={renderFieldDisabled}
                            isTextarea={false}
                            applyStyles={false}
                            className="form-control"
                            placeholder='Nuevo'
                        />
                    </td>
                    <td className='table-form-shopping-fixed-cell-left-1'>
                        <Field
                            name={`${system}.total`}
                            type="text"
                            disabled={true}
                            component={
                                fields.get(index).is_drc
                                    ? renderPercentageDisabled
                                    : fields.get(index).is_kg
                                        ? renderToneladasDisabled
                                        : renderDollarDisabled}
                            placeholder='0.00 %'
                            disabledStyle={index === 0 ? { fontWeight: 'bold' } : {}}
                            className="form-control"
                        />
                    </td>
                    {months.map((month) => (
                        <td key={month} style={{ width: "6.54%" }}>
                            <Field
                                name={`${system}.${month}`}
                                type="text"
                                component={
                                    (fields.get(index).is_drc
                                        ? renderPercentageDisabled
                                        : fields.get(index).is_kg
                                            ? renderToneladasDisabled
                                            : renderDollarDisabled)}
                                className="form-control"
                                placeholder={fields.get(index).is_drc ? '0.00 %' : '$ 0.00'}
                                disabledStyle={index === 0 ? { fontWeight: 'bold' } : {}}
                            />
                        </td>
                    ))}
                </tr>
            </React.Fragment>
        );
    }
}

class GenericTable extends Component {
    render() {
        const { fields, change, propiedades } = this.props;
        return (
            <div className=" col-sm-12 form-group np-r p-0">
                <div className='scroll-container'>
                    <div className='scroll-content-shopping'>
                        <table className="table mb-0 text-center table-striped">
                            <thead>
                                <tr>
                                    <th className='first-column-shopping table-form-fixed-cell-left-0'>CONCEPTO</th>
                                    <th className='table-form-shopping-fixed-cell-left-1' style={{ width: "8%" }}>TOTAL ANUAL</th>
                                    {Array.from({ length: 12 }, (_, i) => (
                                        <th key={i} style={{ width: "6.9%" }}>{i + 1}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody style={{ backgroundColor: "#F0FFC7" }}>
                                {fields.map((system, index) => <TableRow key={index} system={system} index={index} fields={fields} change={change} propiedades={propiedades} />)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

class ShoppingLatexTable extends Component {
    render() {
        const { change } = this.props;
        return (
            <div>
                <FieldArray
                    name='latex_table'
                    change={change}
                    component={GenericTable}
                    propiedades={this.props}
                />
            </div>
        );
    }
}
export default ShoppingLatexTable