import React from 'react';

import { Field } from 'redux-form';
import { renderNumberPercentage } from '../../Utils/renderField/renderField';

const TaxISR = props => {
    return (
        <div>
            <h2 className='titulo_parrafos'> Impuesto del ISR</h2>
            <div className='fn-info-encabezado'>
                <div className='row  col-md-12 p-0'>
                    <div className='col-md- p-0'>
                        <div className='col-md-12 col-sm-12'>
                            <span className='finca-entre-rios'>Porcentaje del ISR de la compra de MP por facturas especiales:</span>
                        </div>
                        <div className='col-md-12  form-group'>
                            <Field name="tax_isr" component={renderNumberPercentage} className="form-control" placeholder='0.00 %' />
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </div>
    );
}
export default TaxISR;
