import React from 'react';
import { reduxForm } from 'redux-form';
import ProductionLatexTable from "./Tables/ProductionLatexTable"
import validate from './validate';
import CardEmpty from '../../../../Utils/Cards/CardEmpty';
import CardFormNoBorder from '../../../../Utils/Cards/CardFormNoBorder';
import Tabs, { TabPane } from 'rc-tabs';
import KgProjected from './Tables/KgProjected';


let FincaForm = props => {
    const { handleSubmit, editar, item, tab} = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className='col-sm-12 p-0'>
                <CardFormNoBorder
                    icon="Side_activos/finca.svg"
                    titulo="PRESUPUESTO DE FINCA"
                    subtitulo="CONFIGURACIÓN"
                    subtituloForm={editar ? "EDITAR" : "NUEVO"}
                    col="12"
                    noShadow
                >

                </CardFormNoBorder>
                <Tabs defaultActiveKey={tab} onChange={tab => setTab(tab)}>
                    <TabPane tab='EN CURSO' key="EN_CURSO">
                        <CardEmpty noShadow
                            isMarginButtom>
                            <div className="col-md-3 p-0 mt-2">
                                <div className="md-12">
                                    <h6 className="mb-4"><strong>KILOGRAMOS PROYECTADOS</strong></h6>
                                </div>
                            </div>
                            <div className='mb-4'>
                                <KgProjected {...props} />
                            </div>
                        </CardEmpty>
                    </TabPane>
                </Tabs>
                 <CardEmpty
                    isMarginButtom
                    noShadow>
                    <div className="col-md-3 p-0 mt-2">
                        <div className="md-12">
                            <h6 className="mb-4"><strong>PRESUPUESTO DE PRODUCCIÓN DE FINCA</strong></h6>
                        </div>
                    </div>
                    <ProductionLatexTable
                        {...props}
                    />
                </CardEmpty>
                <div className='buttons-box mt-5'>
                    <button type="submit" className="btn btn-primary m-4 btn-modal">
                        GUARDAR
                    </button>
                </div>
            </div>
        </form>
    )
};
FincaForm = reduxForm({
    form: 'FincaForm',
    validate,
})(FincaForm);

export default FincaForm;