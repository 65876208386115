import React from 'react';
import { Field, FieldArray } from 'redux-form';
import { renderDollar, renderFieldDisabled, renderDollarDisabled } from '../../../../../Utils/renderField/renderField';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { months } from '../../../../../Utils/months';


const TYPE_OF_ROW_MAPPING = {
    TOTAL_INVESTMENTS: 20,
    INITIAL_INVESTMENT: 21,
    RETURN_ON_INVESTMENT: 22,
    INVESTMENT_BALANCE: 23,
    INVESTMENT_INCREASE: 24,
}
const TableRow = ({ system, index, fields, data }) => {
    const { years_list } = data;

    if (!years_list && Array.isArray(years_list) && years_list.length === 0) {
        <div>
            <h1> Cargando data</h1>
        </div>
    }
    const isEditRow = [TYPE_OF_ROW_MAPPING.RETURN_ON_INVESTMENT, TYPE_OF_ROW_MAPPING.INVESTMENT_INCREASE].includes(fields.get(index).type_of_row);
    return (
        <React.Fragment key={index}>
            <tr key={`system-${index}`}
                style={isEditRow ? { backgroundColor: "#F0FFC7" } : {}}
            >
                <td className="text-center sin-borde-top" style={{ width: "15%" }}>
                    <Field
                        name={`${system}.name`}
                        type="text"
                        component={renderFieldDisabled}
                        className="form-control"
                        placeholder='Nuevo'
                    />
                </td>
                {years_list.map((year) => (
                    <td key={year} style={{ width: "17%" }}>
                        <Field
                            name={`${system}.${year}`}
                            type="text"
                            component={isEditRow ? renderDollar : renderDollarDisabled}
                            className="form-control"
                            placeholder='$ 0.00'
                            style={isEditRow ? { backgroundColor: "#F0FFC7" } : {}}
                        />
                    </td>
                ))}
            </tr>
        </React.Fragment>
    );
};

const GenericTable = ({ fields, change, data }) => {

    const calculateTotalForYear = () => {
        const allFields = fields.getAll() || []
        const authorizedRow = allFields.find(row => row.type_of_row === TYPE_OF_ROW_MAPPING.TOTAL_INVESTMENTS);
        const initialBalanceRow = allFields.find(row => row.type_of_row === TYPE_OF_ROW_MAPPING.INITIAL_INVESTMENT);
        const repaymentsRow = allFields.find(row => row.type_of_row === TYPE_OF_ROW_MAPPING.RETURN_ON_INVESTMENT);
        const finalBalanceRow = allFields.find(row => row.type_of_row === TYPE_OF_ROW_MAPPING.INVESTMENT_BALANCE);
        const increaseRow = allFields.find(row => row.type_of_row === TYPE_OF_ROW_MAPPING.INVESTMENT_INCREASE);

        // Iterar sobre los meses para calcular los valores
        data.years_list.forEach((year, index) => {
            if (authorizedRow && initialBalanceRow && repaymentsRow && finalBalanceRow && increaseRow) {
                const authorized = parseFloat(authorizedRow[year]) || 0;
                const initialBalance = parseFloat(initialBalanceRow[year]) || 0;
                const repayments = parseFloat(repaymentsRow[year]) || 0;
                const increase = parseFloat(increaseRow[year]) || 0;

                const finalBalance = initialBalance - repayments;

                if (index !== 0) {
                    const previousYear = data.years_list[index - 1];
                    initialBalanceRow[year] = (parseFloat(finalBalanceRow[previousYear]) || 0) + (parseFloat(increaseRow[previousYear]) || 0);
                }

                finalBalanceRow[year] = finalBalance;
            }
        });

    };

    const columnas = data.years_list && Array.isArray(data.years_list) && data.years_list.length > 0 ? (
        data.years_list.map((year, index) => (
            <th key={index + 1} style={{ width: "17%" }}>
                {year}
            </th>
        ))
    ) : null;

    if (data.years_list && Array.isArray(data.years_list) && data.years_list.length > 0){
        <div>
            <h5> Cargando data</h5>
        </div>
    }

    data.years_list.map((year, idx) => (
        calculateTotalForYear(year)
    ));

    return (
        <div className=" col-sm-12 form-group np-r p-0">
            <div className='scroll-container'>
                <div className='scroll-content-annual'>
                    <div className="react-bs-container-header table-header-wrapper">
                        <table className="table mb-0 text-center">
                            <thead>
                                <tr>
                                    <th style={{ width: "15%" }}></th>
                                    {columnas ? columnas : <th colSpan="5">No hay datos disponibles</th>}
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="tabla-con-borde">
                        <table className="table table-sm  m-0 table-striped">
                            <tbody style={{ backgroundColor: "#F0FFC7" }}>
                                {fields.map((system, index) => <TableRow key={index} system={system} index={index} fields={fields} change={change} data={data} />)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

const AdministrativosTableBank = ({ fieldName, debtManagement, data }) => {
    return (
        <div>
            <FieldArray
                name={`${fieldName}`}
                component={GenericTable}
                debtManagement={debtManagement}
                data={data}
            />
        </div>
    );
}

const formName = 'AnnualDebtManagementForm';

const mapStateToProps = (state) => ({
    debtManagement: formValueSelector(formName)(state, 'details') || [],
});

export default connect(mapStateToProps)(AdministrativosTableBank);