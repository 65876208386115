export const validate = (values) => {
    const errors = {
        port: [],
        recipe: [],
    };

    const validateField = (value, minLength, requiredMsg, minLengthMsg) => {
        if (!value) {
            return requiredMsg;
        } if (value.length < minLength) {
            return minLengthMsg;
        }
        return null;
    };

    const validateNumberField = (value, requiredMsg, minValueMsg) => {
        if (!value) {
            return requiredMsg;
        } if (Number(value) < 0) {
            return minValueMsg;
        }
        return null;
    };



    if (values.recipe) {
        values.recipe.forEach((recipe, index) => {
            if (!recipe) return;

            const nameSet = new Set(); // Mover el set aquí para reiniciarlo por cada empaque

            recipe.forEach((recipeRow, indexRow) => {
                const recipeErrors = {};

                // Validar el nombre
                recipeErrors.name = validateField(
                    recipeRow.name,
                    3,
                    "Este campo es requerido",
                    "El nombre debe tener al menos 3 caracteres"
                );

                // Verificar si el nombre ya existe en el conjunto
                // if (nameSet.has(recipeRow.name)) {
                //     recipeErrors.name = "El nombre debe ser único";
                // } else {
                //     nameSet.add(recipeRow.name);
                // }

                // Validar sub_product
                if (!recipeRow.type_of_transport || recipeRow.type_of_transport.length === 0) {
                    recipeErrors.type_of_transport = "Necesitas seleccionar por lo menos un producto";
                }

                // Validar cost_dollar
                recipeErrors.cost_quetzal = validateNumberField(
                    recipeRow.cost_quetzal,
                    "Este campo es requerido",
                    "El costo debe ser igual o superior a 0"
                );

                errors.recipe[index] = errors.recipe[index] || [];
                errors.recipe[index][indexRow] = recipeErrors;
            });
        });
    }



    return errors;
};
