import React from 'react';
import { Modal } from 'react-responsive-modal';
import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import ModalForm from './ModalForm';
import validate from '../validate';
const CategoriaModal = props => {
    const { open, closeModal, handleSubmit, selectedDebtType,isEditMode } = props;

    return (
        <Modal
            open={open}
            onClose={closeModal}
            center
            classNames={{
                overlayAnimationIn: 'customEnterOverlayAnimation',
                overlayAnimationOut: 'customLeaveOverlayAnimation',
                modalAnimationIn: 'customEnterModalAnimation',
                modalAnimationOut: 'customLeaveModalAnimation',
                modal: 'customModalSize',
            }}
        >
            <div>


                <div className="pb-4 titulo-modal d-flex justify-content-center">
                    <div className='col-md-12 p-0 mt-2 text-center'>
                        <h4>AGREGAR DEUDA</h4>
                    </div>
                </div>

                <form onSubmit={handleSubmit.bind(selectedDebtType)}>
                    <ModalForm {...props}
                     selectedDebtType={selectedDebtType}
                     isEditMode={isEditMode}
                     />
                    <div className='position-relative'>
                        <div className="buttons-box mt-4 mb-4">
                            <button className="btn btn-outline-dark mr-3 btn-modal" onClick={e => {
                                e.preventDefault();
                                closeModal();
                            }}>
                                CANCELAR
                            </button>
                            {
                                selectedDebtType &&
                                <button type="submit" className="btn btn-primary m-4 btn-modal">
                                    CONFIRMAR
                                </button>
                            }

                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

const formName = 'AnnualDebtManagementForm';

const mapStateToProps = (state) => {
    const selector = formValueSelector(formName);
    const selectedDebtType = selector(state, 'type_of_debt') || null;

    return {
        selectedDebtType
    };
};

export default connect(mapStateToProps)(
    reduxForm({
        form: 'AnnualDebtManagementForm',
        enableReinitialize: true,
        validate
    })(CategoriaModal)
);