import React from 'react';

const YearChange = (props) => {
    const { status_year_change, yearChange } = props;

    if (!status_year_change) {
        return (
            <div className="text-center py-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Cargando...</span>
                </div>
                <h5 className="mt-3 text-secondary">Cargando información...</h5>
            </div>
        );
    }

    const { is_active, can_activate, message } = status_year_change;

    return (
        <div className="container mt-5">
            <div className="card shadow-lg">
                <div className="card-header text-center">
                    <h2 className="card-title m-0">Cambio de año del presupuesto</h2>
                </div>
                <div className="card-body">
                    <p className="card-text text-center">{message}</p>
                    {!is_active && can_activate && (
                        <div className="text-center">
                            <button 
                                className="btn btn-primary px-4 py-2" 
                                onClick={() => {
                                    yearChange()
                                }}
                            >
                                Cambiar año del presupuesto
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default YearChange;
