import React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import Grid from '../../../../Utils/Grid';
import { months } from '../../../../Utils/months';
import editar_img from 'assets/img/icons/ver.svg';
import { columnaClaseFija, columnaFijaTotales } from '../../../../../../utility/staticColumns';

const Listado = (props) => {
    const { data, loader, onSortChange, show, openModal } = props;

    if (!data || !Array.isArray(data.results)) {
        if (loader) {
            return <div>Cargando datos...</div>;
        }
        return <div>No hay datos disponibles para mostrar.</div>;
    }
    const abrirModalYConsultar = (id) => {
        show(id);
        openModal();
    };


    const formatearNumero = (dato) => {
        const numero = Number(dato);

        if (isNaN(numero)) {
            return '--';
        }

        const formatoMoneda = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        return formatoMoneda.format(numero).replace('$', '$ ');
    };

    const meses = months;

    // Crear las filas de datos en formato "Capital" e "Interés" separados
    const generateRows = (data) => {
        return data.results.flatMap(row => {
            const capital = Array.isArray(row.capital) ? row.capital : [];
            const interes = Array.isArray(row.interes) ? row.interes : [];

            const capitalRow = {
                id: row.id,
                name: row.name,
                type: 'Capital',
                ...capital.reduce((acc, value, index) => {
                    acc[`mes_${index + 1}`] = value;
                    return acc;
                }, {})
            };

            const interesRow = {
                id: `${row.id}_interes`,
                name: '',
                type: 'Interés',
                ...interes.reduce((acc, value, index) => {
                    acc[`mes_${index + 1}`] = value;
                    return acc;
                }, {})
            };

            return [capitalRow, interesRow];
        });
    };

    // Función para calcular totales separados de Capital e Interés
    const calculateTotals = (rows) => {
        const totalsCapital = {};
        const totalsInteres = {};
        const totalErogado = {};

        meses.forEach((_, index) => {
            const totalCapital = rows.reduce((sum, row) => {
                return row.type === 'Capital' ? sum + (Number(row[`mes_${index + 1}`]) || 0) : sum;
            }, 0);
            const totalInteres = rows.reduce((sum, row) => {
                return row.type === 'Interés' ? sum + (Number(row[`mes_${index + 1}`]) || 0) : sum;
            }, 0);

            totalsCapital[`mes_${index + 1}`] = totalCapital;
            totalsInteres[`mes_${index + 1}`] = totalInteres;
            totalErogado[`mes_${index + 1}`] = totalCapital + totalInteres;
        });

        return { totalsCapital, totalsInteres, totalErogado };
    };

    const columnaClaseTotal = (cell, row) => {
        if (['totalesCapital', 'totalesInteres', 'totalErogado'].includes(row.id)) {
            return 'celda-totales';
        }
        return ' ';
    };


    const columnaAcciones = (cell, row) => {
        if (row.type !== 'Capital' || ['totalesCapital', 'totalesInteres', 'totalErogado'].includes(row.id)) {
            return '';
        }
        return (
            <div>
                <button
                    type='button'
                    onClick={() => abrirModalYConsultar(row.id)}
                    style={{ border: 'none', background: 'none' }}
                >
                    <img src={editar_img} alt="Editar" className="action_img" />
                </button>
            </div>
        );
    };

    // Generar filas y calcular totales
    const rows = generateRows(data);
    const { totalsCapital, totalsInteres, totalErogado } = calculateTotals(rows);

    // Agregar filas de totales de Capital, Interés, y Total Erogado
    rows.push({
        id: 'totalesCapital',
        name: 'AMORTIZACIÓN CAPITAL/DEVOLUCIONES',
        type: '',
        ...totalsCapital
    });

    rows.push({
        id: 'totalesInteres',
        name: 'INTERESES',
        type: '',
        ...totalsInteres
    });

    rows.push({
        id: 'totalErogado',
        name: 'TOTAL EROGADO POR MES',
        type: '',
        ...totalErogado
    });
    const customHeaderText = (text) => {
        const words = text.split(/[-/]/);
        const lastWord = words.pop();
        return (
            <div>
                {words.join(' ')}
                <br />
                {lastWord}
            </div>
        );
    };

    return (
        <div>
            <Grid
                hover
                striped
                data={{ results: rows }}
                loading={loader}
                onSortChange={onSortChange}
                pagination={false}
                className="pb-3"
                height='400'
            >
                <TableHeaderColumn
                    dataField="name"
                    dataSort
                    isKey
                    width="170"
                    dataFormat={customHeaderText}
                    className="table-fixed-cell-left-0"
                    columnClassName={columnaClaseFija}
                >
                    PRESUPUESTO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="type"
                    dataSort
                    columnClassName={columnaClaseTotal}
                    dataFormat={(cell) => (
                        <span style={{ color: cell === 'Interés' ? 'red' : 'black' }}>
                            {cell}
                        </span>
                    )}
                    width='90'
                >
                    TIPO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField={`mes_${13}`}
                    dataAlign="center"
                    dataSort
                    dataFormat={(cell, row) => (
                        <span style={{ color: row.id === 'totalesInteres' || row.type === 'Interés' ? 'red' : 'black' }}>
                            {formatearNumero(cell)}
                        </span>
                    )}
                    className="table-fixed-cell-left-1"
                    columnClassName={columnaFijaTotales}
                    width='130'
                >
                    TOTAL
                </TableHeaderColumn>
                {meses.map((mes, index) => (
                    <TableHeaderColumn
                        key={`mes_${index + 1}`}
                        dataField={`mes_${index + 1}`}
                        dataSort
                        dataFormat={(cell, row) => (
                            <span style={{ color: row.id === 'totalesInteres' || row.type === 'Interés' ? 'red' : 'black' }}>
                                {formatearNumero(cell)}
                            </span>
                        )}
                        columnClassName={columnaClaseTotal}
                        width='130'
                    >
                        {`${index + 1}`}
                    </TableHeaderColumn>
                ))}

                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    dataSort
                    columnClassName={columnaClaseTotal}
                    dataFormat={columnaAcciones}
                    width='100'
                >
                    ACCIONES
                </TableHeaderColumn>
            </Grid>
        </div>
    );
};

export default Listado;
