import React, { Component } from 'react'
import LoadMask from 'Utils/LoadMask/LoadMask';
import FincaForm from './FincaForm';

export default class FincaEdit extends Component {

    state = {
        open: false,
    };
    componentDidMount() {
        this.props.getDollar()
        this.props.read()
    }
    openModalCategory = () => {

        this.setState({ open: true });

    };
    closeModalCategory = () => {
        this.setState({ open: false });

    };

    actualizar = (data) => {
        const id = data.id
        this.props.editar(id, data);
    };

    getPermissions = () => {
        const { getMe } = this.props;
        getMe();
    }

    render() {
        const { editar } = this.props;
        const { updateData, loader, item } = this.props;
        return (
            <div className="mb-4 col-12">
                <LoadMask loading={loader} blur>
                    <FincaForm
                        onSubmit={this.actualizar}
                        updateData={updateData}
                        item={item}
                        editando={true}
                        permisos={this.getPermissions}
                        openModal={this.openModalCategory}
                        closeModal={this.closeModalCategory}
                        open={this.state.open}
                        {...this.props}
                    />
                </LoadMask>
            </div>
        )
    }
}

