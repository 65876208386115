import React from 'react';
import { Field, FieldArray } from 'redux-form';
import { renderNumberPercentage } from '../../../../../Utils/renderField/renderField';
import { reduxForm } from 'redux-form';

const AdministrativeProjectionTable = ({ fields, years }) => {
    if (!years || years.length === 0) {
        return <div>No hay datos de años disponibles</div>;
    }

    return (
        <div className="table-responsive">
            <table className="table table-bordered text-center">
                <thead>
                    <tr className="table-header">
                        <th>Concepto</th>
                        {years.map((year, index) => (
                            <th key={`year-${year}-${index}`}>{year}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Crecimiento (%)</td>
                        {fields.map((field, index) => (
                            <td key={index}>
                                {index > 0 && (
                                    <Field
                                        name={`${field}.growth_percentage_latex_table`}
                                        component={renderNumberPercentage}
                                        placeholder="0%"
                                        className="form-control"
                                    />
                                )}
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

const FincaAnnualProjections = props => {
    const { data } = props;
    
    
    if (!data  || !data.results || !data.results.projections) {
        return <div>Cargando datos...</div>;
    }

    const projections = data.results.projections || [];
    const years = data.results.years || [];

    if (projections.length === 0 || years.length === 0) {
        return <div>Cargando datos de proyecciones...</div>;
    }

    const initializeProjectionData = (fields) => {
        if (fields.length === 0) {
            projections.forEach((data) => {
                fields.push(data);
            });
        }
    };

    return (
        <div className="administrative-annual-projections-container">
            <form onSubmit={props.handleSubmit}>
                <FieldArray
                    name="projections"
                    component={AdministrativeProjectionTable}
                    years={years}
                    rerenderOnEveryChange={true}
                    props={{ initializeProjectionData }}
                />

                <div className='buttons-box mt-5'>
                    <button type="submit"
                        className="btn btn-primary m-4 btn-modal"
                    >
                        GUARDAR
                    </button>
                </div>
            </form>
        </div>
    );
};
const formName = 'FincaForm';

export default (
    reduxForm({
        form: formName,
    })(FincaAnnualProjections)
);
