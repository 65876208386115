const validate = values => {
    const errors = {};
    const budgetFincaLatexTable = values.budget_finca_latex_table;
    const budgetFincaSolidoTable = values.budget_finca_solidos_table;

    if (budgetFincaLatexTable) {
        if (budgetFincaLatexTable.generic_row && budgetFincaLatexTable.generic_row.length > 0) {
            const genericRowArrayErrors = [];
            budgetFincaLatexTable.generic_row.forEach((row, rowIndex) => {
                const rowErrors = {};
                if (!row || !row.name) {
                    rowErrors.name = "requerido";
                    genericRowArrayErrors[rowIndex] = rowErrors;
                }
            });
            if (genericRowArrayErrors.length) {
                errors.budget_finca_latex_table = { generic_row: genericRowArrayErrors };
            }
        }
    }
    if (budgetFincaSolidoTable) {
        if (budgetFincaSolidoTable.generic_row && budgetFincaSolidoTable.generic_row.length > 0) {
            const genericRowArrayErrors = [];
            budgetFincaSolidoTable.generic_row.forEach((row, rowIndex) => {
                const rowErrors = {};
                if (!row || !row.name) {
                    rowErrors.name = "requerido";
                    genericRowArrayErrors[rowIndex] = rowErrors;
                }
            });
            if (genericRowArrayErrors.length) {
                errors.budget_finca_solidos_table = { generic_row: genericRowArrayErrors };
            }
        }
    }

    return errors;
};

export default validate;
