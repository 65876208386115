import React, { Component } from 'react'
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './InvestmentsForm';
import InvestmentsForm from './InvestmentsForm';

export default class InvestmentsCreate extends Component {

    state = {
        editar: false,
        open: false,
    };
    componentDidMount() {
        this.props.getDollar()
        this.props.readYearForCreate()
        if (this.props.match.params.id) {
            this.props.editar_investments_annual(this.props.match.params.id);
            this.setState({ editar: true });
            
        }
        
    }
    openModalCategory = () => {

        this.setState({ open: true });

    };
    closeModalCategory = () => {
        this.setState({ open: false });

    };

    actualizar = (data) => {
        this.props.actualizar_annual(data);
    };

    getPermissions = () => {
        const { getMe } = this.props;
        getMe();
    }


    render() {
        const { create_annual } = this.props;
        const { updateData, loader, item, year } = this.props;
        return (
            <div className="mb-4 col-12">
                <LoadMask loading={loader} blur>
                    {
                        this.state.editar ?
                            <InvestmentsForm
                                onSubmit={this.actualizar}
                                updateData={updateData}
                                item={item}
                                editando={true}
                                permisos={this.getPermissions}
                                openModal={this.openModalCategory}
                                closeModal={this.closeModalCategory}
                                years={year}
                                open={this.state.open}
                                {...this.props}
                            />
                            :
                            <Form
                                onSubmit={create_annual}
                                permisos={this.getPermissions}
                                openModal={this.openModalCategory}
                                closeModal={this.closeModalCategory}
                                open={this.state.open}
                                item={item}
                                years={year}
                            />
                    }
                </LoadMask>
            </div>
        )
    }
}

