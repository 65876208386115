export const validate = (values) => {
    const errors = {
        defaultRecipe: [],
        generic_row: [],
        recipe: [],
    };

    const validateField = (value, minLength, requiredMsg, minLengthMsg) => {
        if (!value) {
            return requiredMsg;
        } if (value.length < minLength) {
            return minLengthMsg;
        }
        return null;
    };

    const validateNumberField = (value, requiredMsg, minValueMsg) => {
        if (!value) {
            return requiredMsg;
        } if (Number(value) < 0) {
            return minValueMsg;
        }
        return null;
    };

    const MONTHS = [
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre",
    ];

    errors.name = validateField(
        values.name,
        3,
        "Este campo es requerido",
        "El nombre debe tener al menos 3 caracteres"
    );

    if (values.is_paking) {
        if (values.recipe) {
            values.recipe.forEach((recipe, index) => {
                if (!recipe) return;
    
                const nameSet = new Set();
    
                recipe.forEach((recipeRow, indexRow) => {
                    const recipeErrors = {};
    
                    recipeErrors.name = validateField(
                        recipeRow.name,
                        3,
                        "Este campo es requerido",
                        "El nombre debe tener al menos 3 caracteres"
                    );
    
                    if (nameSet.has(recipeRow.name)) {
                        recipeErrors.name = "El nombre debe ser único";
                    } else {
                        nameSet.add(recipeRow.name);
                    }

                    if (!recipeRow.sub_product || recipeRow.sub_product.length === 0) {
                        recipeErrors.sub_product = "Necesitas seleccionar por lo menos un producto";
                    }
    
                    recipeErrors.cost_dollar = validateNumberField(
                        recipeRow.cost_dollar,
                        "Este campo es requerido",
                        "El costo debe ser igual o superior a 0"
                    );
    
                    if (recipeRow.is_unit_measurement) {
                        recipeErrors.unit_measurement = validateNumberField(
                            recipeRow.unit_measurement,
                            "Este campo es requerido",
                            "La medida debe ser igual o superior a 0"
                        );
                        recipeErrors.cost_quetzal = validateNumberField(
                            recipeRow.cost_quetzal,
                            "Este campo es requerido",
                            "El costo debe ser igual o superior a 0"
                        );
                    }
    
                    errors.recipe[index] = errors.recipe[index] || [];
                    errors.recipe[index][indexRow] = recipeErrors;
                });
            });
        }
    }

    if (values.is_manual) {
        if (values.generic_row) {
            values.generic_row.forEach((generic, index) => {
                const genericErrors = {};

                MONTHS.forEach((month) => {
                    genericErrors[month] = validateNumberField(
                        generic[month],
                        "Este campo es requerido",
                        "El costo debe ser igual o superior a 0"
                    );
                });

                errors.generic_row[index] = genericErrors;
            });
        }
    }

    if (values.defaultRecipe) {
        const nameSet = new Set();
    
        values.defaultRecipe.forEach((recipe, index) => {
            const recipeErrors = {};
    
            recipeErrors.name = validateField(
                recipe.name,
                3,
                "Este campo es requerido",
                "El nombre debe tener al menos 3 caracteres"
            );
    
    
            if (!recipe.sub_product || recipe.sub_product.length === 0) {
                recipeErrors.sub_product = "Necesitas seleccionar por lo menos un producto";
            }
    
            recipeErrors.cost_dollar = validateNumberField(
                recipe.cost_dollar,
                "Este campo es requerido",
                "El costo debe ser igual o superior a 0"
            );
    
            if (recipe.is_unit_measurement) {
                recipeErrors.unit_measurement = validateNumberField(
                    recipe.unit_measurement,
                    "Este campo es requerido",
                    "La medida debe ser igual o superior a 0"
                );
                recipeErrors.cost_quetzal = validateNumberField(
                    recipe.cost_quetzal,
                    "Este campo es requerido",
                    "El costo debe ser igual o superior a 0"
                );
            }
    
            errors.defaultRecipe[index] = recipeErrors;
        });
    }

    return errors;
};
