import React from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../../Utils/Grid/StandardActions";
import Grid from "../../../../Utils/Grid";
import { months } from '../../../../Utils/months';
import { columnaClaseFija, columnaFijaTotales,formatearNumeroToneladas } from '../../../../../../utility/staticColumns';
const List = props => {
    const { data, loader, onSortChange } = props;

    const responseData = data.response || {};

    
    const meses = months
    const columnas = meses.map((mes, index) => (
        <TableHeaderColumn
            key={index}
            dataField={mes}
            dataSort
            dataFormat={formatearNumeroToneladas}
            width='130'
        >
            {index + 1}
        </TableHeaderColumn>
    ));

    return (
        <Grid hover striped data={responseData} loading={loader} onSortChange={onSortChange} pagination={false} className="pb-3">
            <TableHeaderColumn 
            dataField="name" 
            dataSort
            className="table-fixed-cell-left-0"
            columnClassName={columnaClaseFija}
            width='170'
            >
                PRODUCTO
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField='total'
                dataSort
                className="table-fixed-cell-left-1"
                columnClassName={columnaFijaTotales}
                dataFormat={formatearNumeroToneladas}
                width='130'
            >
                TOTAL
            </TableHeaderColumn>
            {columnas}
            <TableHeaderColumn 
            dataField="id" 
            dataAlign="center" 
            isKey 
            width='100'
            dataSort 
            dataFormat={standardActions({ ver: "configuration" })}
            >
                ACCIONES
            </TableHeaderColumn>
        </Grid>
    );
};
export default List;