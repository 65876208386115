import React from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";
import { months } from '../../../../../Utils/months';
import GridEmpty from '../../../../../Utils/Grid/GridEmpty';
import { columnaClaseFija, columnaFijaTotales, formatearNumeroToneladas } from '../../../../../../../utility/staticColumns';

const List = props => {
    const { data, loader, onSortChange } = props;
    const dataExportable = Array.isArray(data.production_exportable) ? data.production_exportable : [];

    const columnaClaseTotal = (cell, row) => {
        if (row.name === 'TOTALES') {
            return 'celda-totales';
        }
        return '';
    };

    const meses = months
    const columnas = meses.map((mes, index) => (
        <TableHeaderColumn
            key={index}
            dataField={mes}
            dataSort
            dataFormat={formatearNumeroToneladas}
            columnClassName={columnaFijaTotales}
            width='130'
        >
            {index + 1}
        </TableHeaderColumn>
    ));

    return (
        <GridEmpty
            hover
            striped
            data={dataExportable}
            loading={loader}
            onSortChange={onSortChange}
            pagination={false}
            className="pb-3"
        >

            <TableHeaderColumn
                dataField="name"
                isKey={true}
                dataSort
                className="table-fixed-cell-left-0"
                columnClassName={columnaClaseFija}
                width='170'
            >
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="total"
                dataSort
                className="table-fixed-cell-left-1"
                columnClassName={columnaFijaTotales}
                dataFormat={formatearNumeroToneladas}
                width='160'
            >
                Total
            </TableHeaderColumn>
            {columnas}
        </GridEmpty>
    );
};
export default List;