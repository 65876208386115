import React from 'react';
import { Field, FieldArray } from 'redux-form';
import { renderFieldDisabled, renderDollarDisabled, renderQuetzalesDisabled } from '../../../../../Utils/renderField/renderField';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { months as Months } from '../../../../../Utils/months';


const TableRow = ({ system, index, fields, dollar_exchange }) => {
    const months = Months

    const calculateTotalForRow = (isDollar) => {
        const systemData = fields.get(index);
        if (!systemData) return 0;

        let totalForRow = months.reduce((accumulatedTotal, month) => {
            const value = parseFloat(systemData[month]) || 0;
            accumulatedTotal += value;
            return accumulatedTotal;
        }, 0);

        if (isDollar && dollar_exchange) {
            totalForRow /= dollar_exchange;
        }

        return totalForRow;
    };

    return (
        <React.Fragment key={index}>
            <tr key={`system-${index}`}>
                <td className='first-column table-form-fixed-cell-left-0'>
                    <Field
                        name={`${system}.name`}
                        type="text"
                        component={renderFieldDisabled}
                        isTextarea={false}
                        className="form-control"
                        placeholder='Nuevo'
                    />
                </td>
                <td className='table-form-fixed-cell-left-1'>

                    {
                        fields.get(index).is_dollar
                            ?
                            new Intl.NumberFormat('es-US', { style: 'currency', currency: 'USD' }).format(calculateTotalForRow())
                            :
                            new Intl.NumberFormat('es-GT', { style: 'currency', currency: 'GTQ' }).format(calculateTotalForRow())
                    }

                </td>
                {months.map((month) => (
                    <td key={month} style={{ width: "6.9%" }}>
                        <Field
                            name={`${system}.${month}`}
                            type="text"
                            component={fields.get(index).is_dollar ? renderDollarDisabled : renderQuetzalesDisabled}
                            className="form-control"
                            placeholder={fields.get(index).is_dollar ? '$ 0.00' : 'Q 0.00'}
                        />
                    </td>
                ))}
            </tr>
        </React.Fragment>
    );
};

const GenericTable = ({ fields, dollar_exchange }) => {
    const months = Months

    const calculateTotalForMonth = (month, isDollar) => {
        const allFields = fields.getAll() || [];
        let total = allFields.reduce((total, system) => {
            let value = parseFloat(system[month]) || 0;

            if (system.is_dollar) {
                value *= dollar_exchange;
            }

            return total + value;
        }, 0);
        if (isDollar && dollar_exchange) {
            total /= dollar_exchange;
        }
        return total;
    };

    return (
        <div className=" col-sm-12 form-group np-r p-0">
            <div className='scroll-container'>
                <div className='scroll-content-sales'>
                    <table className="table mb-0 text-center table-striped">
                        <thead>
                            <tr>
                                <th className='first-column table-form-fixed-cell-left-0'>CONCEPTO</th>
                                <th className='table-form-fixed-cell-left-1'>TOTAL</th>
                                {Array.from({ length: 12 }, (_, i) => (
                                    <th key={i} style={{ width: "6.9%" }}>{i + 1}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody style={{ backgroundColor: "#F0FFC7" }}>
                            {fields.map((system, index) => <TableRow
                                key={index}
                                system={system}
                                index={index}
                                fields={fields}
                                dollar_exchange={dollar_exchange}
                            />)}
                            <tr style={{ backgroundColor: "#F0FFC7", borderBottom: '1px solid #035B2F', borderTop: '1px solid #035B2F' }}>
                                <td className='table-form-fixed-cell-left-0'>TOTALES Q</td>
                                <td className='table-form-fixed-cell-left-1'>
                                    {new Intl.NumberFormat('es-GT', { style: 'currency', currency: 'GTQ' }).format(calculateTotalForMonth('total'))}
                                </td>
                                {months.map((month, idx) => (
                                    <td key={idx} className="custom-cell text-center">
                                        {new Intl.NumberFormat('es-GT', { style: 'currency', currency: 'GTQ' }).format(calculateTotalForMonth(month))}
                                    </td>
                                ))}
                            </tr>
                            <tr style={{ backgroundColor: "#F0FFC7" }}>
                                <td className='table-form-fixed-cell-left-0'>TOTALES $</td>
                                <td className='table-form-fixed-cell-left-1'>
                                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(calculateTotalForMonth('total', true))}
                                </td>
                                {months.map((month, idx) => (
                                    <td key={idx} className="custom-cell text-center">
                                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(calculateTotalForMonth(month, true))}
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

const ShoppingTable = ({ fieldName, dollar_exchange, allValues }) => {
    return (
        <div>
            <FieldArray
                name={`${fieldName}`}
                dollar_exchange={dollar_exchange}
                component={GenericTable}
                allValues={allValues}
            />
        </div>
    );
}

const formName = 'ShoppingForm';

const selector = formValueSelector(formName);

const mapStateToProps = (state) => {

    const categoryBudget = selector(state, 'category_budget') || [];
    const allValues = categoryBudget.map(item => item);
    return { allValues };

};

export default connect(mapStateToProps)(ShoppingTable);