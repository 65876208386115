import React, { useState } from 'react';
import { Field } from 'redux-form';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { AsyncSelectField, renderCurrency, renderDollar, renderField, renderNumber, renderNumberKG,renderNumberUnits } from '../../../../../Utils/renderField/renderField';

const RecetaRow = ({ system, index, fields, change, getCustomers, dollarExchange, isPacking }) => {

    const handleCurrencySelection = (selectedCurrency) => {
        const isDollarSelected = selectedCurrency === 'USD';
        change(`${system}.is_dollar`, isDollarSelected);
    };

    const handleUnitMeasurementSelection = (selectedCurrency) => {
        const isUnitMeasurementSelected = selectedCurrency === 'SI';
        change(`${system}.is_unit_measurement`, isUnitMeasurementSelected);
    };

    const calculateTotalForRow = () => {
        const systemData = fields.get(index);
        if (!systemData || !systemData.cost_quetzal || !systemData.unit_measurement) return 0;
        return (systemData.cost_quetzal / dollarExchange) * systemData.unit_measurement;
    };


    return (
        <tr key={index}>
            <td className="sin-borde-top" style={{ width:isPacking? '25%':'15%' }}>
                <Field
                    name={`${system}.name`}
                    type="text"
                    component={renderField}
                    className="form-control"
                    placeholder="Rubro"
                />
            </td>
            {!isPacking &&<td className="sin-borde-top" style={{ width: '30%' }}>
                <Field
                    name={`${system}.sub_product`}
                    component={AsyncSelectField}
                    loadOptions={getCustomers}
                    valueKey="id"
                    labelKey="subproduct"
                    className="form-control"
                    isMulti
                />
            </td>}
            {fields.get(index).is_unit_measurement ? (
                <div>
                    <td className="text-center sin-borde-top" style={{ width: '15%' }}>
                        <Field
                            name={`${system}.unit_measurement`}
                            type="number"
                            component={renderNumber}
                            placeholder='0.00'
                            decimalScale={8}
                        />  
                    </td>
                    <td className="text-center sin-borde-top" style={{ width: '15%' }}>
                        <Field
                            name={`${system}.cost_quetzal`}                                                                 
                            type="number"
                            component={renderCurrency}
                            placeholder='Q 0.00'
                            decimalScale={8}
                        />
                    </td>
                    <td style={{ width: "15%" }} className="custom-cell text-center">
                        {new Intl.NumberFormat('es-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 6, maximumFractionDigits: 6 }).format(calculateTotalForRow())}
                    </td>
                </div>
            ) : (
                <div>
                    <td className="text-center sin-borde-top" style={{ width: '15%' }}></td>
                    <td className="text-center sin-borde-top" style={{ width: '15%' }}></td>
                    <td className="text-center sin-borde-top" style={{ width: '15%' }}>
                        <Field
                            name={`${system}.cost_dollar`}
                            type="number"
                            disabled={fields.get(index).is_unit_measurement ? true : false}
                            component={fields.get(index).is_dollar ? renderDollar : renderCurrency}
                            placeholder={fields.get(index).is_dollar ? '$ 0.00' : 'Q 0.00'}
                            decimalScale={6}
                        />
                    </td>
                </div>
            )}
            <td className="text-center sin-borde-top" style={{ width: '10%', position: 'relative' }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: '5px' }}>
                        <UncontrolledDropdown>
                            <DropdownToggle tag="span" className="action_img" data-toggle="dropdown" aria-expanded={false}>
                                <img src={require('assets/img/icons/Side_activos/Medida.svg')} alt="Moneda" title="Moneda" />
                            </DropdownToggle>
                            <DropdownMenu container="body" style={{ width: '195px' }}>
                                <div className="br-top"></div>
                                <DropdownItem onClick={() => handleUnitMeasurementSelection('SI')}>Con unidad de medida</DropdownItem>
                                <DropdownItem onClick={() => handleUnitMeasurementSelection('NO')}>Sin unidad de medida</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                    {!fields.get(index).is_unit_measurement ?
                        <div style={{ marginRight: '5px' }}>
                            <UncontrolledDropdown>
                                <DropdownToggle tag="span" className="action_img" data-toggle="dropdown" aria-expanded={false}>
                                    <img src={require('assets/img/icons/donate-coin.svg')} alt="Moneda" title="Moneda" />
                                </DropdownToggle>
                                <DropdownMenu container="body" style={{ width: '177px' }}>
                                    <div className="br-top"></div>
                                    <DropdownItem onClick={() => handleCurrencySelection('USD')}>USD</DropdownItem>
                                    <DropdownItem onClick={() => handleCurrencySelection('QUETZALES')}>QUETZALES</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                        :
                        <div style={{ marginRight: '30px' }}>

                        </div>
                    }

                    <div>
                        <img
                            src={require('assets/img/icons/Accion-Eliminar.svg')}
                            alt="Borrar"
                            title="Borrar"
                            className="action_img"
                            onClick={() => fields.remove(index)}
                        />
                    </div>
                </div>
            </td>
        </tr>
    );
};

export default RecetaRow;
