import React from "react";
import classNames from "classnames";
import NumberFormat from "react-number-format";

export const ToneladasField = ({ value }) => {
    return (
        <NumberFormat
            className={classNames('form-control-disabled')}
            decimalScale={2}
            fixedDecimalScale
            placeholder={"0.00 TM"}
            value={value / 1000} // Dividimos el valor entre 1000 para mostrar toneladas
            thousandSeparator
            suffix=" TM"
            readOnly
            displayType="text"
        />
    );
};
