import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
import { initialize as initializeForm } from 'redux-form';
import { api } from "api";
import Swal from 'sweetalert2';
const alerta = require('assets/img/icons/activo.png');
import { months } from '../../../../common/components/Utils/months';

let url = 'cash-flow';
let form = 'ComparativoFlujoCajaForm';
// ------------------------------------
// Constants
// ------------------------------------

export const constants = {
    LOADER: 'COMPARATIVO_FLUJO_CAJA_LOADER',
    DATA: 'COMPARATIVO_FLUJO_CAJA_DATA',
    ITEM_DATA: 'COMPARATIVO_FLUJO_CAJA_ITEM',
    PAGE: 'COMPARATIVO_FLUJO_CAJA_PAGE',
    ORDERING: 'COMPARATIVO_FLUJO_CAJA_ORDERING',
    SEARCH: 'COMPARATIVO_FLUJO_CAJA_SEARCH',
    TAB_EN_CURSO: 'COMPARATIVO_FLUJO_CAJA_TAB_EN_CURSO',
    PAGE_EN_CURSO: 'COMPARATIVO_FLUJO_CAJA_PAGE_EN_CURSO',
    PAGE_ANUALES: 'COMPARATIVO_FLUJO_CAJA_PAGE_ANUALES',
    KG_PROYECTADOS: 'COMPARATIVO_FLUJO_CAJA_KG_PROYECTADOS',
    SET_CURRENCY: 'SET_CURRENCY'
}


// -----------------------------------
// Pure Actions
// -----------------------------------
const setLoader = loader => ({
    type: constants.LOADER,
    loader,
});
const setData = data => ({
    type: constants.DATA,
    data,
});
const setItem = item => ({
    type: constants.ITEM_DATA,
    item,
});


// -----------------------------------
// Actions
// -----------------------------------
const read = () => async (dispatch, getState) => {
    dispatch(setLoader(true));

    try {
        const cashFlowResponse = await api.get(`${url}/get_cash_flow`);
        const investmentsResponse = await api.get(`comparativo_flujo_caja/get_investments_flow`);

        const newData = { ...cashFlowResponse, investments: investmentsResponse };

        dispatch(setData(newData));
        dispatch(initializeForm(form, newData));

    } catch (error) {
        console.log(error);
        NotificationManager.error(
            (error && error.detail) ? error.detail : 'Error en la carga de datos',
            'ERROR',
            3000
        );
    } finally {
        dispatch(setLoader(false));
    }
};

const readAnnual = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${url}/get_annual_cash_flow`)
        .then((response) => {
            dispatch(setData(response));
            dispatch(initializeForm(form, response));
        })
        .catch((error) => {
            NotificationManager.error((error && error.detail) ? error.detail : 'Error en la edición', 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const getDollar = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`administrative/get_dollar_exchange`).then((response) => {
        dispatch(setItem(response.dollar_exchange));
        dispatch(initializeForm(form, response.results));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${url}/${id}`, data).then(() => {
        confirm_finca_save(dispatch)
        dispatch(setCurrency('USD'));
    }).catch(() => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error en la edición', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${url}/${id}`).then(() => {
        dispatch(read());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch((error) => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};
//  ----------------
//  Resumen
// -----------------


const setTab = (tab) => (dispatch) => {
    dispatch(setLoader(true));
    dispatch({ type: constants.TAB_EN_CURSO, tab });
    if (tab === "EN_CURSO") {
        dispatch(read())
    } else {
        dispatch(readAnnual())
    }
    dispatch(setLoader(false));
};

const setCurrency = (currency) => (dispatch) => {
    dispatch({ type: constants.SET_CURRENCY, currency })

}

const convertValues = (item, factor) => {

    months.forEach(month => {
        if (item.hasOwnProperty(month)) {
            item[month] = item[month] * factor;
        }
    });

    if (item.hasOwnProperty("total")) {
        item.total = item.total * factor;
    }

    return item;
};


const convertNestedData = (data, factor) => {
    if (Array.isArray(data)) {
        return data.map(item => {
            if (item.results && Array.isArray(item.results)) {
                return {
                    ...item,
                    results: convertNestedData(item.results, factor),
                };
            }

            return convertValues({ ...item }, factor);
        });
    }
    return data;
};


const convertCurrency = (data, exchangeRate, currency) => {
    const isToDollar = currency === 'USD';
    const factor = isToDollar ? (1 / exchangeRate) : exchangeRate;

    if (data.hasOwnProperty("investments")) {
        data.investments = {
            ...data.investments,
            results: convertNestedData(data.investments.results, factor),
        };
    }

    // if (data.hasOwnProperty("investments")) {
    //     data.investments = convertNestedData(data.investments.results, factor);
    // }

    if (data.hasOwnProperty("financial")) {
        data.financial = convertNestedData(data.financial, factor);
    }

    if (data.hasOwnProperty("operation")) {
        data.operation = convertNestedData(data.operation, factor);
    }

    if (data.hasOwnProperty("summary")) {
        data.summary = convertNestedData(data.summary, factor);
    }

    return data;
};



const changeCurrency = (currency, exchangeRate) => (dispatch, getState) => {
    const currentState = getState();
    if (!currentState.comparativo_flujo_caja || !currentState.comparativo_flujo_caja.data) {
        console.error("Data not available or not in expected format");
        return;
    }
    const data = currentState.comparativo_flujo_caja.data;
    const convertedData = convertCurrency(data, exchangeRate, currency);
    dispatch(setData(convertedData));
    dispatch(initializeForm(form, convertedData));

};

export const actions = {
    editar,
    read,
    getDollar,
    setTab,
    eliminar,
    changeCurrency,
    setCurrency,
    readAnnual
};

// -----------------------------------
// Reducers
// -----------------------------------

const reducers = {
    [constants.LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [constants.DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [constants.ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE_EN_CURSO]: (state, { page_activas }) => {
        return {
            ...state,
            page_activas,
        };
    },
    [constants.PAGE_ANUALES]: (state, { page_finalizadas }) => {
        return {
            ...state,
            page_finalizadas,
        };
    },

    [constants.TAB_EN_CURSO]: (state, { tab }) => {
        return {
            ...state,
            tab,
        };
    },
    [constants.KG_PROYECTADOS]: (state, { kg_proyectados }) => {
        return {
            ...state,
            kg_proyectados,
        };
    },
    [constants.SET_CURRENCY]: (state, { currency }) => {
        return {
            ...state,
            currency: currency,
        };
    },

};
export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    exchangeRate: 7.8,
    currency: 'USD',
    production_exportable: {},
    kg_proyectados: {},
    item: {},
    tab: 'EN_CURSO',
};
export default handleActions(reducers, initialState)
