import React from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";
import GridEmpty from '../../../../../Utils/Grid/GridEmpty';
import { columnaClaseFija, columnaFijaTotales } from '../../../../../../../utility/staticColumns';

const FlujosInversionTable = props => {
    const { data, loader, onSortChange, currency } = props;

    if (!data || !data.year_list || data.year_list.length === 0) {
        return <p>Cargando datos...</p>;
    }

    const dataExportable = data.investments && Array.isArray(data.investments.results) ? data.investments.results : [];

    const formatearNumero = (dato, row) => {

        if (row.isTitulo) {
            return '';
        }

        if (dato === null || dato === undefined || isNaN(dato)) {
            dato = 0;
        }

        const formatoMoneda = new Intl.NumberFormat('es-GT', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });

        const numero = Number(dato);
        return currency === 'USD' ? "-$ " + formatoMoneda.format(numero) : "-Q " + formatoMoneda.format(numero);
    };

    const estilosNombre = (cell, row) => {
        if (row.isTitulo) {
            return { fontWeight: 'bold', color: 'black' };
        }
        return {};
    };

    const columnaClaseTotal = (cell, row) => {
        if (row.name === 'TOTALES') {
            return 'celda-totales';
        }
        return '';
    };

    const columnas = data.year_list.map((year, index) => (
        <TableHeaderColumn
            key={index}
            dataField={year.toString()}
            dataSort
            dataFormat={(cell, row) => formatearNumero(cell, row)}
            columnClassName={columnaClaseTotal}
            width='130'
            tdStyle={{ color: 'red' }}
        >
            {year}
        </TableHeaderColumn>
    ));

    return (
        <GridEmpty
            hover
            striped
            data={dataExportable}
            loading={loader}
            onSortChange={onSortChange}
            pagination={false}
            className="pb-3"
        >
            <TableHeaderColumn
                dataField="name"
                isKey={true}
                dataSort
                className="table-fixed-cell-left-0"
                width='170'
                columnClassName={columnaClaseFija}
                tdStyle={(cell, row) => row.isTitulo ? { fontWeight: 'bold', color: 'black' } : { color: 'red' }}
                columnStyle={(cell, row) => estilosNombre(cell, row)}
            >
                FLUJO DE INVERSIÓN
            </TableHeaderColumn>
            {columnas}
        </GridEmpty>
    );
};

export default FlujosInversionTable;
