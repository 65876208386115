import React, { Fragment} from 'react';


function CardPunteado(props) {
    return (
        <Fragment>
            <div className='dotted-card p-0 px-3 pt-3'>
                <div className={'p-0 px-3 pt-3'}>
                    {props.children}
                </div>
            </div>
        </Fragment>

    )
}

export default CardPunteado;
