import { connect } from 'react-redux';
import { actions } from '../../../../../../redux/modules/investments/investments';
import InvestmentsList from "./Investments";


const ms2p = (state) => {
  return {
    ...state.investments,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(InvestmentsList);
