import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderField, renderSwitch, renderFieldCheck, SelectField } from '../../../Utils/renderField/renderField';
import CardEmpty from '../../../Utils/Cards/CardEmpty';

const vistas = [
    { value: '/', label: 'Parámetros  generales' },
    { value: '/sales/summary', label: 'Ventas' },
    { value: '/finca/summary', label: 'Finca' },
    { value: '/shopping/summary', label: 'Compras' },
    { value: '/planta-solidos/summary', label: 'Plantas - Sólido' },
    { value: '/planta-latex/summary', label: 'Plantas - Látex' },
    { value: '/logistica/summary', label: 'Logística y exportación' },
    { value: '/administrativos/summary', label: 'Administrativos' },
    { value: '/deuda/summary', label: 'Manejo de deuda' },
    { value: '/inversiones/summary', label: 'Inversiones' },
    { value: '/credito-fiscal/summary', label: 'Crédito Fiscal' }
]
const validateInitialView = (values) => {
    const initialView = values.initial_view;
    const errors = {};

    if (initialView) {
        const viewPermissionMap = {
            '/': 'sensitivity_parameters',
            '/sales/summary': 'sales_summary',
            '/finca/summary': 'farm_summary',
            '/shopping/summary': 'shopping_summary',
            '/planta-solidos/summary': 'solid_summary',
            '/planta-latex/summary': 'latex_summary',
            '/logistica/summary': 'logistics_and_export_summary',
            '/administrativos/summary': 'administrative_summary',
            '/deuda/summary': 'debt_management_summary',
            '/inversiones/summary': 'investments_summary',
            '/credito-fiscal/summary': 'tax_credit_summary'
        };

        const requiredPermission = viewPermissionMap[initialView];
        if (requiredPermission && !values[requiredPermission]) {
            errors.initial_view = 'Este rol no tiene permisos a la pantalla que desea acceder.';
        }
    }

    return errors;
};


let RolForm = props => {
    const { handleSubmit, permisos, editando } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container d-flex align-items-center flex-column col-12">
                <div className="row col-md-4 p-0">
                    <div className="col-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre_rol" className="m-0">NOMBRE DEL ROL</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field name="rol_name" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 px-2 mt-2">
                            <div>
                                <CardEmpty noShadow noPadding>
                                    <div className="p-1 mt-1 mr-2" style={{ height: "95.41px" }}>
                                        <div className="d-flex flex-column justify-content-start">
                                            <label>INICIAR EN PANTALLA</label>
                                            <Field component={SelectField} className="form-control" name="initial_view" options={vistas} />
                                        </div>
                                    </div>
                                </CardEmpty>
                            </div>
                            <div style={{ marginTop: '1.112rem' }}>
                                <CardEmpty noShadow noPadding>
                                    <div className="p-1 mt-1 mr-2">
                                        <label>PARÁMETROS GENERALES</label>
                                        <div className="d-flex flex-row justify-content-between align-items-start" style={{ marginBottom: '0.1rem' }}>
                                            <ul>
                                                <li>
                                                    <label className="gris">PARÁMETROS DE SENSIBILIDAD</label>
                                                </li>
                                            </ul>
                                            <Field component={renderFieldCheck} className="form-control" name="sensitivity_parameters" />
                                        </div>
                                    </div>
                                </CardEmpty>
                            </div>
                            <div style={{ marginTop: '1.112rem' }}>
                                <CardEmpty noShadow noPadding>
                                    <div className="p-1 mt-1 mr-2">
                                        <label>VENTAS</label>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <ul>
                                                <li>
                                                    <label className="gris">RESUMEN</label>
                                                </li>
                                            </ul>
                                            <Field component={renderFieldCheck} className="form-control" name="sales_summary" />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <ul>
                                                <li>
                                                    <label className="gris">CONFIGURACIÓN</label>
                                                </li>
                                            </ul>
                                            <Field component={renderFieldCheck} className="form-control" name="sales_configuration" />
                                        </div>
                                    </div>
                                </CardEmpty>
                            </div>
                            <div style={{ marginTop: '1.112rem' }}>
                                <CardEmpty noShadow noPadding>
                                    <div className="p-1 mt-1 mr-2">
                                        <label>FINCA</label>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <ul>
                                                <li>
                                                    <label className="gris">RESUMEN</label>
                                                </li>
                                            </ul>
                                            <Field component={renderFieldCheck} className="form-control" name="farm_summary" />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <ul>
                                                <li>
                                                    <label className="gris">CONFIGURACIÓN</label>
                                                </li>
                                            </ul>
                                            <Field component={renderFieldCheck} className="form-control" name="farm_configuration" />
                                        </div>
                                    </div>
                                </CardEmpty>
                            </div>
                            <div style={{ marginTop: '1.112rem' }}>
                                <CardEmpty noShadow noPadding>
                                    <div className="p-1 mt-1 mr-2">
                                        <label>COMPRAS</label>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <ul>
                                                <li>
                                                    <label className="gris">RESUMEN</label>
                                                </li>
                                            </ul>
                                            <Field component={renderFieldCheck} className="form-control" name="shopping_summary" />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <ul>
                                                <li>
                                                    <label className="gris">CONFIGURACIÓN</label>
                                                </li>
                                            </ul>
                                            <Field component={renderFieldCheck} className="form-control" name="shopping_configuration" />
                                        </div>
                                    </div>
                                </CardEmpty>
                            </div>

                            <div style={{ marginTop: '1.112rem' }}>
                                <CardEmpty noShadow noPadding>
                                    <div className="p-1 mt-1 mr-2">
                                        <label>PLANTAS</label>
                                        <div className="p-1 mt-1 mr-2">
                                            <label>SÓLIDOS</label>
                                            <div className="d-flex flex-row justify-content-between align-items-start">
                                                <ul>
                                                    <li>
                                                        <label className="gris">RESUMEN</label>
                                                    </li>
                                                </ul>
                                                <Field component={renderFieldCheck} className="form-control" name="solid_summary" />
                                            </div>
                                            <div className="d-flex flex-row justify-content-between align-items-start">
                                                <ul>
                                                    <li>
                                                        <label className="gris">CONFIGURACIÓN</label>
                                                    </li>
                                                </ul>
                                                <Field component={renderFieldCheck} className="form-control" name="solid_configuration" />
                                            </div>
                                        </div>
                                        <div className="p-1 mt-1 mr-2">
                                            <label>LÁTEX</label>
                                            <div className="d-flex flex-row justify-content-between align-items-start">
                                                <ul>
                                                    <li>
                                                        <label className="gris">RESUMEN</label>
                                                    </li>
                                                </ul>
                                                <Field component={renderFieldCheck} className="form-control" name="latex_summary" />
                                            </div>
                                            <div className="d-flex flex-row justify-content-between align-items-start">
                                                <ul>
                                                    <li>
                                                        <label className="gris">CONFIGURACIÓN</label>
                                                    </li>
                                                </ul>
                                                <Field component={renderFieldCheck} className="form-control" name="latex_configuration" />
                                            </div>
                                        </div>
                                    </div>
                                </CardEmpty>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 px-2 mt-2">
                            <CardEmpty noShadow noPadding>
                                <div className="p-1 mt-1 mr-2">
                                    <label>LOGÍSTICA Y EXPORTACIÓN</label>
                                    <div className="d-flex flex-row justify-content-between align-items-start">
                                        <ul>
                                            <li>
                                                <label className="gris">RESUMEN</label>
                                            </li>
                                        </ul>
                                        <Field component={renderFieldCheck} className="form-control" name="logistics_and_export_summary" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start">
                                        <ul>
                                            <li>
                                                <label className="gris">CONFIGURACIÓN</label>
                                            </li>
                                        </ul>
                                        <Field component={renderFieldCheck} className="form-control" name="logistics_and_export_configuration" />
                                    </div>
                                </div>
                            </CardEmpty>
                            <div style={{ marginTop: '0.62rem' }}>
                                <CardEmpty noShadow noPadding>
                                    <div className="p-1 mt-1 mr-2">
                                        <label>ADMINISTRATIVOS</label>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <ul>
                                                <li>
                                                    <label className="gris">RESUMEN</label>
                                                </li>
                                            </ul>
                                            <Field component={renderFieldCheck} className="form-control" name="administrative_summary" />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <ul>
                                                <li>
                                                    <label className="gris">CONFIGURACIÓN</label>
                                                </li>
                                            </ul>
                                            <Field component={renderFieldCheck} className="form-control" name="administrative_configuration" />
                                        </div>
                                    </div>
                                </CardEmpty>
                            </div>
                            <div style={{ marginTop: '0.62rem' }}>
                                <CardEmpty noShadow noPadding>
                                    <div className="p-1 mt-1 mr-2">
                                        <label>MANEJO DE DEUDA</label>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <ul>
                                                <li>
                                                    <label className="gris">RESUMEN</label>
                                                </li>
                                            </ul>
                                            <Field component={renderFieldCheck} className="form-control" name="debt_management_summary" />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <ul>
                                                <li>
                                                    <label className="gris">CONFIGURACIÓN</label>
                                                </li>
                                            </ul>
                                            <Field component={renderFieldCheck} className="form-control" name="debt_management_configuration" />
                                        </div>
                                    </div>
                                </CardEmpty>
                            </div>
                            <div style={{ marginTop: '0.62rem' }}>
                                <CardEmpty noShadow noPadding>
                                    <div className="p-1 mt-1 mr-2">
                                        <label>INVERSIONES</label>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <ul>
                                                <li>
                                                    <label className="gris">RESUMEN</label>
                                                </li>
                                            </ul>
                                            <Field component={renderFieldCheck} className="form-control" name="investments_summary" />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <ul>
                                                <li>
                                                    <label className="gris">CONFIGURACIÓN</label>
                                                </li>
                                            </ul>
                                            <Field component={renderFieldCheck} className="form-control" name="investments_configuration" />
                                        </div>
                                    </div>
                                </CardEmpty>
                            </div>
                            <div style={{ marginTop: '0.62rem' }}>
                                <CardEmpty noShadow noPadding>
                                    <div className="p-1 mt-1 mr-2">
                                        <label>CRÉDITO FISCAL</label>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <ul>
                                                <li>
                                                    <label className="gris">RESUMEN</label>
                                                </li>
                                            </ul>
                                            <Field component={renderFieldCheck} className="form-control" name="tax_credit_summary" />
                                        </div>
                                    </div>
                                </CardEmpty>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 px-2 mt-2">
                            <div style={{ marginTop: '0.62rem' }}>
                                <CardEmpty noShadow noPadding>
                                    <div className="p-1 mt-1 mr-2">
                                        <label>REPORTES</label>
                                        <div className="d-flex flex-row justify-content-between align-items-start" style={{ marginBottom: '0.1rem' }}>
                                            <ul>
                                                <li>
                                                    <label className="gris">FLUJO DE CAJA</label>
                                                </li>
                                            </ul>
                                            <Field component={renderFieldCheck} className="form-control" name="cash_flow" />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between align-items-start" style={{ marginBottom: '0.1rem' }}>
                                            <ul>
                                                <li>
                                                    <label className="gris">BALANCE</label>
                                                </li>
                                            </ul>
                                            <Field component={renderFieldCheck} className="form-control" name="balance" />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between align-items-start" style={{ marginBottom: '0.1rem' }}>
                                            <ul>
                                                <li>
                                                    <label className="gris">ESTADO DE RESULTADOS</label>
                                                </li>
                                            </ul>
                                            <Field component={renderFieldCheck} className="form-control" name="income_statement" />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between align-items-start" style={{ marginBottom: '0.1rem' }}>
                                            <ul>
                                                <li>
                                                    <label className="gris">RESULTADOS DE OPERACIÓN</label>
                                                </li>
                                            </ul>
                                            <Field component={renderFieldCheck} className="form-control" name="results_of_operation" />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between align-items-start" style={{ marginBottom: '0.1rem' }}>
                                            <ul>
                                                <li>
                                                    <label className="gris">COSTO DE COMPRA M.P.A</label>
                                                </li>
                                            </ul>
                                            <Field component={renderFieldCheck} className="form-control" name="purchase_cost_m_p_a" />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between align-items-start" style={{ marginBottom: '0.1rem' }}>
                                            <ul>
                                                <li>
                                                    <label className="gris">COSTO DE VENTA P.</label>
                                                </li>
                                            </ul>
                                            <Field component={renderFieldCheck} className="form-control" name="cost_of_sales_p" />
                                        </div>

                                        <div className="d-flex flex-row justify-content-between align-items-start" style={{ marginBottom: '0.1rem' }}>
                                            <ul>
                                                <li>
                                                    <label className="gris">COMPARATIVOS ANUALES</label>
                                                </li>
                                            </ul>
                                            <Field component={renderFieldCheck} className="form-control" name="comparative_annual" />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between align-items-start" style={{ marginBottom: '0.1rem' }}>
                                            <ul>
                                                <li>
                                                    <label className="gris">PRESUPUESTOS A.A.</label>
                                                </li>
                                            </ul>
                                            <Field component={renderFieldCheck} className="form-control" name="assumptions_a_a" />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between align-items-start" style={{ marginBottom: '0.1rem' }}>
                                            <ul>
                                                <li>
                                                    <label className="gris">PRESUPUESTOS A.E.P.</label>
                                                </li>
                                            </ul>
                                            <Field component={renderFieldCheck} className="form-control" name="budgets_a_e_p" />
                                        </div>
                                        <div className="report-group">
                                            <label className="group-title">GRUPO DE REPORTES E.V.</label>
                                            <ul className="report-list">
                                                <li className="d-flex justify-content-between">
                                                    <label>VOLÚMENES DE VENTA</label>
                                                    <Field component={renderFieldCheck} name="sales_volumes" />
                                                </li>
                                                <li className="d-flex justify-content-between">
                                                    <label>MARGEN POR K.P.F.E.R.</label>
                                                    <Field component={renderFieldCheck} name="margen_por_k_p_f_e_r" />
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </CardEmpty>
                            </div>
                            <div style={{ marginTop: '0.62rem' }}>
                                <CardEmpty noShadow noPadding>
                                    <div className="p-1 mt-1 mr-2">
                                        <label>ADMINISTRACIÓN</label>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <ul>
                                                <li>
                                                    <label className="gris">ROLES</label>
                                                </li>
                                            </ul>
                                            <Field component={renderFieldCheck} className="form-control" name="roles" />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <ul>
                                                <li>
                                                    <label className="gris">USUARIOS</label>
                                                </li>
                                            </ul>
                                            <Field component={renderFieldCheck} className="form-control" name="users" />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <ul>
                                                <li>
                                                    <label className="gris">CLIENTES</label>
                                                </li>
                                            </ul>
                                            <Field component={renderFieldCheck} className="form-control" name="customers" />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <ul>
                                                <li>
                                                    <label className="gris">PUERTOS DE SALIDA</label>
                                                </li>
                                            </ul>
                                            <Field component={renderFieldCheck} className="form-control" name="port_of_departure" />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <ul>
                                                <li>
                                                    <label className="gris">EMPAQUES</label>
                                                </li>
                                            </ul>
                                            <Field component={renderFieldCheck} className="form-control" name="packaging" />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <ul>
                                                <li>
                                                    <label className="gris">TIPOS DE TRANSPORTE</label>
                                                </li>
                                            </ul>
                                            <Field component={renderFieldCheck} className="form-control" name="types_of_transport" />
                                        </div>
                                    </div>
                                </CardEmpty>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/roles">CANCELAR</Link>
                    {editando ?
                        (<button type="submit" className="btn btn-primary" onClick={permisos()}>GUARDAR</button>)
                        :
                        (<button type="submit" className="btn btn-primary">GUARDAR</button>)
                    }
                </div>
            </div>
        </form>
    )
};
RolForm = reduxForm({
    form: 'RolForm',
    validate: (data) => {
        let errors = validate(data, {
            'rol_name': validators.exists()('Campo requerido.'),
        });
        return { ...errors, ...validateInitialView(data) };
    }
})(RolForm);

export default RolForm
