import React from 'react';
import CardForm from '../../../../Utils/Cards/CardForm';
import { reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import validate from './validate';
import FincancialCashDetailTable from './FinancialFlowTable';
import InvestmentsFlow from './InvestmentsFlow';
import OperatingFlowTable from './OperatingFlowTable';
import ResumeFlow from './ResumeFlow';

let CashFlowForm = props => {
    const { handleSubmit, editar, item, eliminar, currency, setCurrency } = props;

    const handleCurrencyChange = (event) => {
        const newCurrency = event.target.value;
        setCurrency(newCurrency);
        props.changeCurrency(newCurrency, item);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className='col-sm-12 p-0'>
                <CardForm
                    icon="Side_activos/Admin.svg"
                    titulo="FLUJO DE CAJA"
                    subtitulo="CONFIGURACIÓN"
                    subtituloForm={editar ? "EDITAR" : "NUEVO"}
                    col="12"
                    noShadow
                >
                    <div className="mb-3 d-flex align-items-center">
                        <label htmlFor="currency-selector" style={{ marginRight: '10px', marginBottom: '0' }}>Expresado en:</label>
                        <select id="currency-selector" className="form-control custom-select" style={{ width: '180px', border: 'none', outline: 'none', boxShadow: 'none' }} value={currency} onChange={handleCurrencyChange}>
                            <option value="USD">Dólar US$</option>
                            <option value="GTQ">Quetzales (GTQ)</option>
                        </select>
                    </div>

                    <ResumeFlow {...props} dollar_exchange={item} eliminar={eliminar} />
                    <OperatingFlowTable {...props} dollar_exchange={item} eliminar={eliminar} />
                    <InvestmentsFlow {...props} dollar_exchange={item} />
                    <FincancialCashDetailTable {...props} dollar_exchange={item} eliminar={eliminar} />
                </CardForm>
                <div className='buttons-box mt-5'>
                    <button type="submit" className="btn btn-primary m-4 btn-modal">
                        CONFIRMAR
                    </button>
                </div>
            </div>
        </form>
    );
};

CashFlowForm = reduxForm({
    form: 'CashFlowForm',
    validate,
})(CashFlowForm);

export default CashFlowForm;
