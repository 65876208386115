import React from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";
import GridEmpty from '../../../../../Utils/Grid/GridEmpty';

const List = props => {

    const { data, loader, onSortChange } = props;
    const { years_list } = data;
        if (!years_list || years_list.length === 0) {
            return <div>No hay datos disponibles para mostrar.</div>;
        }
    const dataExportable = data && data.results && Array.isArray(data.results) ? data.results : [];
    const formatearNumero = (dato) => {
        if (dato === null || dato === undefined || isNaN(dato)) {
            return '';
        }

        const formatoMoneda = new Intl.NumberFormat('es-GT', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        const numero = Number(dato);
        return "$ "+formatoMoneda.format(numero);
    }
    const columnaClaseTotal = (cell, row) => {
        if (row.name === 'Total') {
            return 'celda-totales';
        }
        return '';
    };

    const columnas = years_list.map((year, index) => (
        <TableHeaderColumn
            key={year}
            dataField={year}
            dataSort
            dataFormat={formatearNumero}
            columnClassName={columnaClaseTotal}
            width='130'
        >
            {year}
        </TableHeaderColumn>
    ));


    return (
        <GridEmpty
            hover
            striped
            data={dataExportable}
            loading={loader}
            onSortChange={onSortChange}
            pagination={false}
            className="pb-3"
            >
            
            <TableHeaderColumn
                dataField="name"
                isKey={true}
                dataSort
                columnClassName={columnaClaseTotal}
                width='200'
            >
            </TableHeaderColumn>
            {columnas}
        </GridEmpty>
    );
};
export default List;