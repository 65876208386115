import React from 'react';
import { Field, FieldArray } from 'redux-form';
import { renderDollar, renderFieldDisabled, renderDollarDisabled, renderCurrency, renderCurrencyDisabled } from '../../../../../Utils/renderField/renderField';
import { renderField } from "../../../../../Utils/renderField/renderField"
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { months } from '../../../../../Utils/months';
import Swal from 'sweetalert2';
const alerta = require('assets/img/icons/alerta_roja.png');


const TableRow = ({ system, index, fields, eliminar, currency, change, year_list }) => {

    const is_manual = fields.get(index).is_manual;
    const isNew = fields.get(index).isNew
    const isEntradas = fields.get(index).type_of_flow === "salidas";
    const currentRow = fields.get(index)

    React.useEffect(() => {
        change(`${system}.is_dollar`, currency === 'USD');
    }, [currency, system, change]);

    const delete_category = (index, id) => {
        let message = '¿Estás seguro de confirmar todos los cambios?';
        const SwalMod = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-primary btn-modal',
                cancelButton: 'btn btn-outline-dark mr-5 btn-modal',
                content: '',
                title: 'darck',
                popup: 'custom-modal-size',
            },
            buttonsStyling: false,
            showCloseButton: true,
            focusConfirm: false,
            focusCancel: false,
            padding: "1em",
        });

        SwalMod.fire({
            title: 'ADVERTENCIA',
            html: 'Si eliminas la categoría perderás toda la información, <br/>' + message,
            imageUrl: alerta,
            imageAlt: 'Alerta',
            showCancelButton: true,
            confirmButtonText: 'CONTINUAR',
            cancelButtonText: 'CANCELAR',
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                fields.remove(index);
                eliminar(id)

            }
        });
    }
    // Styles
    const disabledStyle = isEntradas ? { color: 'red' } : {}

    return (
        <React.Fragment key={index}>
            <tr key={`system-${index}`}>
                <td className='first-column table-form-fixed-cell-left-0'>
                    <Field
                        name={`${system}.name`}
                        type="text"
                        style={disabledStyle}
                        component={isNew ? renderField : renderFieldDisabled}
                        className="form-control"
                        placeholder='Nuevo'
                    />
                </td>
                {year_list.map((year) => {
                    return (
                        <td key={year} style={{ width: "6.9%" }}>
                            <Field
                                name={`${system}.${year}`}
                                type="text"
                                component={
                                    currency === 'USD' ?
                                        renderDollarDisabled
                                        :
                                        renderCurrencyDisabled
                                }
                                disabledStyle={disabledStyle}
                                placeholder={fields.get(index).is_dollar ? '$ 0.00' : 'Q 0.00'}
                            />
                        </td>
                    );
                })}
            </tr>
        </React.Fragment>
    );
};

const GenericTable = ({ fields, dollar_exchange, allValues, change, eliminar, currency, year_list }) => {

    const calculateTotalForMonth = (year) => {
        const allFields = fields.getAll() || [];
        let total = allFields.reduce((total, system) => {
            let value = parseFloat(system[year]) || 0;
            return total + value;
        }, 0);
        return total;
    };
    const columnas = year_list.map((year, index) => (
        <th key={index} style={{ width: `${100 / year_list.length}%` }}>{year}</th>
    ));

    return (
        <div className=" col-sm-12 form-group np-r p-0">
            <div className='scroll-container'>
                <div className='scroll-content-annual'>
                    <table className="table mb-0 text-center table-striped">
                        <thead>
                            <tr>
                                <th className='first-column table-form-fixed-cell-left-0'>FLUJOS FINANCIEROS</th>
                                {columnas}
                            </tr>
                        </thead>
                        <tbody style={{ backgroundColor: "#F0FFC7" }}>
                            {fields.map((system, index) =>
                                <TableRow
                                    key={index}
                                    system={system}
                                    index={index}
                                    fields={fields}
                                    change={change}
                                    currency={currency}
                                    eliminar={eliminar}
                                    year_list={year_list} />)}
                            {
                                currency !== 'USD' ?
                                    (<tr className="celda-totales">
                                        <td className='table-form-fixed-cell-left-0'>TOTALES Q</td>
                                        {year_list.map((year, idx) => (
                                            <td key={idx}>
                                                {new Intl.NumberFormat('es-GT', { style: 'currency', currency: 'GTQ' }).format(calculateTotalForMonth(year))}
                                            </td>
                                        ))}
                                        <td></td>
                                    </tr>)
                                    :
                                    <tr className="celda-totales">
                                        <td className='table-form-fixed-cell-left-0'>TOTALES $</td>
                                        {year_list.map((year, idx) => (
                                            <td key={idx}>
                                                {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(calculateTotalForMonth(year))}
                                            </td>
                                        ))}
                                        <td></td>
                                    </tr>
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

const FlujosFinancierosTable = ({ dollar_exchange, allValues, change, eliminar, currency, year_list }) => {
    if (!year_list || year_list.length === 0) {
        return <p>Cargando datos...</p>;
    }
    return (
        <div>
            <FieldArray
                name={`financial`}
                dollar_exchange={dollar_exchange}
                component={GenericTable}
                allValues={allValues}
                change={change}
                eliminar={eliminar}
                currency={currency}
                year_list={year_list}
            />
        </div>
    );
}

const formName = 'ComparativoFlujoCajaForm';

const selector = formValueSelector(formName);

const mapStateToProps = (state) => {

    const categoryBudget = selector(state, 'financial') || [];
    const allValues = categoryBudget.map(item => item);
    return { allValues };

};

export default connect(mapStateToProps)(FlujosFinancierosTable);
