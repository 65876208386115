import React from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";
import { months } from '../../../../../Utils/months';
import GridEmpty from '../../../../../Utils/Grid/GridEmpty';
import { columnaClaseFija, columnaFijaTotales } from '../../../../../../../utility/staticColumns';
const List = props => {
    const { data, loader, onSortChange } = props;
    const dataExportable = data && data.constants_table && Array.isArray(data.constants_table.results) ? data.constants_table.results : [];

    const formatearNumero = (dato) => {
        if (dato === null || dato === undefined || isNaN(dato)) {
            return '';
        }

        const formatoMoneda = new Intl.NumberFormat('es-GT', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        const numero = Number(dato);
        return "$ " + formatoMoneda.format(numero);
    };

    const columnaClaseTotal = (cell, row) => {
        if (row.name === 'Total') {
            return 'celda-totales';
        }
        return '';
    };

    const meses = months;
    const columnas = meses.map((mes, index) => (
        <TableHeaderColumn
            key={index}
            dataField={mes}
            dataSort
            dataFormat={formatearNumero}
            columnClassName={columnaClaseTotal}
            width='130'
        >
            {index + 1}
        </TableHeaderColumn>
    ));

    return (
        <div className="table-scrollable">
            <GridEmpty
                hover
                striped
                data={dataExportable}
                loading={loader}
                onSortChange={onSortChange}
                pagination={false}
                className="pb-3"
            >
                <TableHeaderColumn
                    dataField="name"
                    isKey={true}
                    dataSort
                    tdStyle={{ whiteSpace: 'normal' }}
                    width='170'
                    className="table-fixed-cell-left-0"
                    columnClassName={columnaClaseFija}
                >
                    Nombre
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="total"
                    dataSort
                    className="table-fixed-cell-left-1"
                    columnClassName={columnaFijaTotales}
                    dataFormat={formatearNumero}
                    width='160'
                >
                    Total
                </TableHeaderColumn>
                {columnas}
            </GridEmpty>
        </div>
    );
};
export default List;
