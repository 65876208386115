import React from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../../Utils/Grid/StandardActions";
import Grid from "../../../../Utils/Grid";
import { months as month} from '../../../../Utils/months';
import { columnaClaseFija, columnaFijaTotales, formatearNumeroToneladas } from '../../../../../../utility/staticColumns';
const List = props => {
    const { data_summary, loader, onSortChange } = props;
    const responseData = data_summary.response || {};

    if (!responseData || !Array.isArray(responseData.results)) {
        if (loader) {
            return <div>Cargando datos...</div>;
        }
        return <div>No hay datos disponibles para mostrar.</div>;
    }

    const totales = month.reduce((acc, mes) => {
        acc[mes] = responseData.results.reduce((sum, row) => sum + parseFloat(row[mes] || 0), 0);
        return acc;
    }, {});

    const totalAnual = Object.values(totales).reduce((sum, value) => sum + value, 0);

    const dataConTotales = {
        results: [
            ...responseData.results,
            { ...totales, name: 'TOTALES', id: 'totales', total: totalAnual }
        ]
    };
    const formatearDolares = (cell, row) => {
        if (row.id === 'totales') {
            return '';
        }
        const formatoMoneda = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        return formatoMoneda.format(cell) ;
    };
    const columnaAcciones = (cell, row) => {
        if (row.id === 'totales') {
            return '';
        }
        return standardActions({ ver: "summary" })(cell, row);
    };

    const columnaClaseTotal = (cell, row) => {
        if (row.name === 'TOTALES') {
            return 'celda-totales'; 
        }
        return '';
    };


    const columnas = month.map((mes, index) => (
        <TableHeaderColumn
            key={mes}
            dataField={mes}
            dataSort
            columnClassName={columnaClaseTotal}
            dataFormat={formatearNumeroToneladas}
            width='100'
        >
            {index + 1}
        </TableHeaderColumn>
    ));

    return (
        <Grid hover striped data={dataConTotales} loading={loader} onSortChange={onSortChange} pagination={false}
            className="pb-3"
        >

            <TableHeaderColumn
                dataField="name"
                dataSort
                className="table-fixed-cell-left-0"
                columnClassName={columnaClaseFija}
                width='170'
            >
                PRODUCTO
            </TableHeaderColumn>

            <TableHeaderColumn
                dataField='total'
                dataSort
                className="table-fixed-cell-left-1"
                columnClassName={columnaFijaTotales}
                dataFormat={formatearNumeroToneladas}
                width='130'
            >
                TOTAL
            </TableHeaderColumn>
            {columnas}
            <TableHeaderColumn
                dataField='differential'
                columnClassName={columnaClaseTotal}
                dataSort
                dataFormat={formatearDolares}
                width='125'
            >
                DIFERENCIAL PONDERADO
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="id"
                dataAlign="center"
                isKey
                width='100'
                dataSort
                columnClassName={columnaClaseTotal}
                dataFormat={columnaAcciones}
            >
                ACCIONES
            </TableHeaderColumn>
        </Grid>
    )
};
export default List;