import { connect } from "react-redux";
import { actions } from '../../../../../../redux/modules/solidPlant';
import SolidPlantList from "./SolidPlant";

const mapStateToProps = (state) => {
    return {
        ...state.solidPlant,
    };
};

const mapDispatchToProps = { ...actions };

export default connect(mapStateToProps, mapDispatchToProps)(SolidPlantList);
