import React, { Component } from 'react'
import LoadMask from 'Utils/LoadMask/LoadMask';
import DebtManagementForm from './DebtManagementForm';
import CategoriaModal from './Modal/CategoriaModal';

export default class AnnualDebtManagementEdit extends Component {

    state = {
        open: false,
        isEditMode: false,
    };
    componentDidMount() {
        this.props.getDebtConfigAnnual()
    }
    // componentDidUpdate(prevProps) {
    //     if (this.props.annual_data !== prevProps.annual_data) {
    //         this.props.getDebtConfigAnnual()
    //     }
    // }
    openModalCategory = () => {
        this.setState({ open: true });
        this.isEdition()
    };
    closeModalCategory = () => {
        this.setState({ open: false });
        this.props.initializeAnnualFormPrincipal(this.props.annual_data)

    };

    isEdition = () => {
        this.setState({ isEditMode: true });
    };
    isCreate = () => {
        this.setState({ isEditMode: false });
    };

    actualizar = (data) => {
        this.props.editarAnnual(data.id, data);
        this.props.setTab('PROYECCIONES_ANUALES_RESUMEN')
    };
    actualizarModal = (data) => {

        const updatedData = {
            ...data,
            Modal: true,
            frequency:'annual'
        };
        if (updatedData.id === undefined) {
            this.props.crear(updatedData,true)

        }
        else {
            this.props.editarAnnualModal(data.id, updatedData)
        }
        this.closeModalCategory()
    }

    getPermissions = () => {
        const { getMe } = this.props;
        getMe();
    }

    render() {
        const { updateData, loader, item } = this.props;
        return (
            <div className="mb-4 col-12">
                <LoadMask loading={loader} blur>
                    <DebtManagementForm
                        onSubmit={this.actualizar}
                        item={item}
                        permisos={this.getPermissions}
                        openModal={this.openModalCategory}
                        closeModal={this.closeModalCategory}
                        open={this.state.open}
                        isEdition={this.isEdition}
                        isCreate={this.isCreate}
                        {...this.props}
                    />
                </LoadMask>

                <CategoriaModal
                    onSubmit={this.actualizarModal}
                    open={this.state.open}
                    closeModal={this.closeModalCategory}
                    isEditMode={this.state.isEditMode}
                    {...this.props}
                />
            </div>
        )
    }
}

