import React, { Component } from 'react';
import { Modal } from 'react-responsive-modal';


export default class CategoriaModal extends Component {
    constructor(props) {
        super(props);
        this.state = { name:'', description: ''}
    }

    closeModal = () => {
        this.setState({ open: false })
    };
    onSubmit = (data) => {
        if (this.state.activar) {
            this.props.activar(this.props.id)
        } else {
            this.props.desactivar(this.props.id, data)
        }
    };
    handleNameChange = (event) => {
        const value = event.target.value;
        this.setState({ name : value})
        this.props.handleNameChange(value)
    }
    handleDescriptionChange = (event) => {
        const value = event.target.value;
        this.setState({ description : value})
        this.props.handleDescriptionChange(value)
    }

    render() {
        const { open, closeModal, addCategory, handleNameChange, handleDescriptionChange, message} = this.props;
        return (
            <Modal
                open={open}
                onClose={closeModal}
                center
                classNames={{
                    overlayAnimationIn: 'customEnterOverlayAnimation',
                    overlayAnimationOut: 'customLeaveOverlayAnimation',
                    modalAnimationIn: 'customEnterModalAnimation',
                    modalAnimationOut: 'customLeaveModalAnimation',
                    modal: 'customModalSize',
                }}
            >
                <div className="pb-4 titulo-modal d-flex justify-content-center">
                    <div className='col-md-12 p-0 mt-2 text-center'>
                        <h4>AGREGAR NUEVO RUBRO</h4>
                    </div>
                </div>
                <div className="row col-12 p-0">
                    { message && <div className="alerta error_mensaje"> {message} </div> }
                </div>
                <div className='position-relative'>
                    <div className="col-md-6 p-0">
                        <div className="md-12">
                            <label htmlFor="name" className="m-0">NOMBRE DE RUBRO</label>
                        </div>
                        <div className="md-12 form-group">
                            <input
                                id="name"
                                name="name"
                                type="text"
                                className="form-control"
                                placeholder="Nombre"
                                value={this.name}
                                onChange={this.handleNameChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 p-0 mt-2 mb-4">
                        <div className="md-12">
                            <label htmlFor="name" className="m-0">DESCRIPCIÓN</label>
                        </div>
                        <div className="md-12  form-group">
                        <input
                                id="description"
                                name="description"
                                type="text"
                                className="form-control"
                                placeholder="Descripción"
                                value={this.description}
                                onChange={this.handleDescriptionChange}
                            />
                        </div>
                    </div>

                    <div className="buttons-box mt-4 mb-4">
                        <button className="btn btn-outline-dark mr-3 btn-modal"
                            onClick={e => {
                                e.preventDefault();
                                closeModal();
                            }}>
                            CANCELAR
                        </button>
                        <button className="btn btn-primary btn-modal"
                            onClick={e => {
                                e.preventDefault();
                                addCategory();
                            }}
                        >CONTINUAR</button>
                    </div>
                </div>
            </Modal>
        );
    }
}
CategoriaModal.propTypes = {};

