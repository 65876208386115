import React, { Component } from 'react'
import LoadMask from 'Utils/LoadMask/LoadMask';
import ShoppingForm from './ShoppingForm';
import ShoppingFormAnnual from './AnnualProjections/ShoppingFormAnnual';
import Tabs, { TabPane } from "rc-tabs";
import CardFormNoBorder from '../../../../Utils/Cards/CardFormNoBorder';
import AnnualProjectionsShopping from './AnnualProjections/AnnualProjectionsShopping';
import CardEmpty from '../../../../Utils/Cards/CardEmpty';
import ShoppingLatexTable from './AnnualProjections/ShoppingLatexTable';
import ShoppingChipaTable from './AnnualProjections/ShoppingChipaTable'
import AnnualProjectionsFinca from './AnnualProjections/AnnualProjectionsShoppingBudget';

export default class ShoppingShow extends Component {

    componentDidMount() {
        if (this.props.tab === 'EN_CURSO') {
            this.props.getDollar()
            this.props.read()
        } else {
            this.props.summary_annual_list()
        }

    }
    componentDidUpdate(prevProps) {
        if (this.props.tab !== prevProps.tab) {
            if (this.props.tab === 'EN_CURSO') {
                this.props.getDollar();
                this.props.read();
            } else {
                this.props.summary_annual_list();
            }
        }
    }


    getPermissions = () => {
        const { getMe } = this.props;
        getMe();
    }

    updateAnnual = (data) => {
        const dataForm = {
            projections : data.projections,
            latex_iva : data.latex_table,
            solid_iva : data.chipa_table,
        }
        this.props.edit_annual(dataForm);
    };

    render() {

        const { loader, item, tab, setTab } = this.props;
        return (
            <div className="mb-4 col-12">
                <CardFormNoBorder
                    icon="Side_activos/Compras.svg"
                    titulo="PRESUPUESTO DE COMPRAS"
                    subtitulo="RESUMEN"
                    subtituloForm="DETALLE"
                    col="12"
                    noShadow
                >
                </CardFormNoBorder>
                <Tabs
                    defaultActiveKey={tab}
                    onChange={tab => setTab(tab)}
                    animated={{ tabPane: true, inkBar: true }}
                >
                    <TabPane tab="EN CURSO" key="EN_CURSO">
                        <LoadMask loading={loader} blur>
                            <ShoppingForm
                                item={item}
                                permisos={this.getPermissions}
                                {...this.props}
                            />
                        </LoadMask>
                    </TabPane>
                    <TabPane tab="PROYECCIONES ANUALES" key="PROYECCIONES_ANUALES">
                        <CardEmpty isMarginButtom>
                            <div className="col-md-3 p-0 mt-2">
                                <div className="md-12">
                                    <h5 className="mb-4">
                                        <strong>
                                            COSTOS POR COMPRAS DE LATEX
                                        </strong>
                                    </h5>
                                </div>
                            </div>
                            <ShoppingLatexTable {...this.props} />
                        </CardEmpty>
                        <CardEmpty isMarginButtom>
                            <div className="col-md-3 p-0 mt-2">
                                <div className="md-12">
                                    <h5 className="mb-4">
                                        <strong>
                                            COSTOS POR COMPRAS DE CHIPA
                                        </strong>
                                    </h5>
                                </div>
                            </div>
                            <ShoppingChipaTable {...this.props} />
                        </CardEmpty>

                        <CardEmpty isMarginButtom >
                            <div className="col-md-3 p-0 mt-2">
                                <div className="md-12">
                                    <h6 className="mb-4"><strong>FLETES Y COMISIONES</strong></h6>
                                </div>
                            </div>
                            <AnnualProjectionsShopping
                                {...this.props}
                            />
                        </CardEmpty>
                        <ShoppingFormAnnual
                            item={item}
                            permisos={this.getPermissions}
                            {...this.props}
                        />
                        <CardEmpty isMarginButtom>
                            <div className="col-md-3 p-0 mt-2">
                                <div className="md-12">
                                    <h6 className="mb-4"><strong>Crecimiento compras</strong></h6>
                                </div>
                            </div>
                            <AnnualProjectionsFinca
                                item={item}
                                onSubmit={this.updateAnnual}
                                {...this.props}
                            />
                        </CardEmpty>

                    </TabPane>
                </Tabs>
            </div>
        )
    }
}

