import React from 'react';
import { connect } from 'react-redux';
import { months } from '../../../../../../Utils/months';
import { ToneladasField } from '../../Utils/TableFields';
import { getComprasKgSecosEquivalentesLatex } from '../../Utils/PrepareData';

const LatexTable = props => {
    const { dataCompras, diferenciaDRCPagoVsDRCProduccionSolidos } = props;

    const KgSecosEquivalentes = getComprasKgSecosEquivalentesLatex(dataCompras);

    const rowDescriptionClassName = "text-left table-fixed-cell-left-0 bg-white text-nowrap";

    return (
        <div className='reports__table-bg'>
            <div className='reports-table__container'>
                <table className="table mb-0 text-center min-w-500 " >
                    <thead >
                        <tr>
                            <th rowSpan={2} className="text-left table-fixed-cell-left-0 tr--border-top tr--border-left td--shadow-right">&nbsp;</th>
                            {months.map((_, index) => (
                                <th colSpan={3} className={`th--border ${index === 0 && 'tr--no-border-left'}`}>{index + 1}</th>
                            )
                            )}
                        </tr>
                        <tr className='tr--no-radius tr--border-top'>
                            {months.map((_, index) => (
                                <React.Fragment key={index}>
                                    <th className={index > 0 && 'tr--border-left'}>Kgs Secos</th>
                                    <th>Costo Kg Sec</th>
                                    <th className='tr--border-right'>Valor total</th>
                                </React.Fragment>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='tr--border-top'>
                            <td className={rowDescriptionClassName}>Inventario Inicial</td>
                            {months.map((_, index) => (
                                <React.Fragment key={index}>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                </React.Fragment>
                            ))}
                        </tr>
                        <tr>
                            <td className={rowDescriptionClassName}>(+) Producción Fca.</td>
                            {months.map((_, index) => (
                                <React.Fragment key={index}>
                                    <td>1231564</td>
                                    <td>1231564</td>
                                    <td>1231564</td>
                                </React.Fragment>
                            ))}
                        </tr>
                        <tr>
                            <td className={rowDescriptionClassName}>(+) Compras Prod/Exp</td>
                            {months.map((_, index) => (
                                <React.Fragment key={index}>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                </React.Fragment>
                            ))}
                        </tr>
                        <tr>
                            <td className={rowDescriptionClassName}>(+) Compras del mes local</td>
                            {months.map((mes, index) => (
                                <React.Fragment key={index}>
                                    <td>
                                        <ToneladasField value={KgSecosEquivalentes[mes]} />
                                    </td>
                                    <td>1312312313</td>
                                    <td>1312312313</td>
                                </React.Fragment>
                            ))}
                        </tr>
                        <tr>
                            <td className={rowDescriptionClassName}>(+) Ajuste al inventario 1%</td>
                            {months.map((_, index) => (
                                <React.Fragment key={index}>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                </React.Fragment>
                            ))}
                        </tr>
                        <tr>
                            <td className={rowDescriptionClassName}>(+/-) Ajuste por diferencia DRC vs Prod.</td>
                            {months.map((_, index) => (
                                <React.Fragment key={index}>
                                    <td>(13123123)</td>
                                    <td>-</td>
                                    <td>-</td>
                                </React.Fragment>
                            ))}
                        </tr>
                        <tr>
                            <td className={rowDescriptionClassName}>&nbsp;</td>
                        </tr>
                        <tr className='celda-totales'>
                            <td className={rowDescriptionClassName + ' celda-totales'}>Cto. Promedio del Inven.</td>
                            {months.map((_, index) => (
                                <React.Fragment key={index}>
                                    <td>1312312313</td>
                                    <td>1312312313</td>
                                    <td>1312312313</td>
                                </React.Fragment>
                            ))}
                        </tr>
                        <tr>
                            <td className={rowDescriptionClassName}>Salidas a producción látex</td>
                            {months.map((_, index) => (
                                <React.Fragment key={index}>
                                    <td>1312312313</td>
                                    <td>1312312313</td>
                                    <td>1312312313</td>
                                </React.Fragment>
                            ))}
                        </tr>
                        <tr >
                            <td className={rowDescriptionClassName}>Salidas a producción skim</td>
                            {months.map((_, index) => (
                                <React.Fragment key={index}>
                                    <td>1312312313</td>
                                    <td></td>
                                    <td>-</td>
                                </React.Fragment>
                            ))}
                        </tr>
                        <tr className='celda-totales'>
                            <td className={rowDescriptionClassName + ' celda-totales'}>Inventario final</td>
                            {months.map((_, index) => (
                                <React.Fragment key={index}>
                                    <td>-</td>
                                    <td>1312312313</td>
                                    <td>-</td>
                                </React.Fragment>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {};
};


export default connect(mapStateToProps)(LatexTable);
