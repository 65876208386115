import React, { useState } from 'react';
import { Field, FieldArray } from 'redux-form';
import { renderDollar, renderFieldDisabled, renderDollarDisabled, renderCurrency, renderFieldCheck, renderFieldCheckOne } from '../../../../Utils/renderField/renderField';
import { renderField } from "../../../../Utils/renderField/renderField"
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { months } from '../../../../Utils/months';


const TableRow = ({ system, index, fields, change }) => {
    const [currency, setCurrency] = useState('QUETZALES');
    const _months = months
    const isNew = fields.get(index).isNew;

    const handleCurrencySelection = (selectedCurrency) => {
        const isDollarSelected = selectedCurrency === 'USD';
        setCurrency(selectedCurrency);
        change(`${system}.is_dollar`, isDollarSelected);
    };
    const calculateTotalForRow = () => {
        const systemData = fields.get(index);
        if (!systemData) return 0;

        let totalForRow = _months.reduce((accumulatedTotal, month) => {
            const value = parseFloat(systemData[month]) || 0;
            accumulatedTotal += value;
            return accumulatedTotal;
        }, 0);
        return totalForRow;
    };

    return (
        <React.Fragment key={index}>
            <tr key={`system-${index}`}>
                <td className='first-column table-form-fixed-cell-left-0'>
                    <Field
                        name={`${system}.name`}
                        type="text"
                        component={isNew ? renderField : renderFieldDisabled}
                        className="form-control"
                        placeholder='Nuevo'
                    />
                </td>
                <td className='table-form-fixed-cell-left-1'>
                    {
                        fields.get(index).is_dollar
                            ?
                            new Intl.NumberFormat('es-US', { style: 'currency', currency: 'USD' }).format(calculateTotalForRow())
                            :
                            new Intl.NumberFormat('es-GT', { style: 'currency', currency: 'GTQ' }).format(calculateTotalForRow())
                    }
                </td>
                {months.map((month) => (
                    <td key={month} style={{ width: "6.9%" }}>
                        <Field
                            name={`${system}.${month}`}
                            type="text"
                            component={fields.get(index).is_dollar ? renderDollar : renderCurrency}
                            className="form-control"
                            placeholder={fields.get(index).is_dollar ? '$ 0.00' : 'Q 0.00'}
                        />
                    </td>
                ))}
                <td className='text-center sin-borde-top' style={{ width: '6.7%' }}>
                    <Field

                        name={`${system}.is_iva_credit`}
                        component={renderFieldCheck}
                        className='form-control'
                    />
                </td>
                <td className='text-center sin-borde-top' style={{ width: '6.7%' }}>
                    <Field

                        name={`${system}.is_mano_de_obra`}
                        component={renderFieldCheck}
                        className='form-control'
                    />
                </td>
                <td className="text-center sin-borde-top" style={{ width: "10%", position: 'relative' }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: '5px' }}>
                            <UncontrolledDropdown>
                                <DropdownToggle tag="span" className="action_img" data-toggle="dropdown" aria-expanded={false}>
                                    <img src={require('assets/img/icons/donate-coin.svg')} alt="Opciones" title="Opciones" />
                                </DropdownToggle>
                                <DropdownMenu container="body" style={{ width: "177px" }} >
                                    <div className="br-top"></div>
                                    <DropdownItem onClick={() => handleCurrencySelection('USD')}>USD</DropdownItem>
                                    <DropdownItem onClick={() => handleCurrencySelection('QUETZALES')}>QUETZALES</DropdownItem>
                                </DropdownMenu>
                                <div className="br-top"></div>
                            </UncontrolledDropdown>
                        </div>
                        <div>
                            <img src={require('assets/img/icons/Accion-Eliminar.svg')} alt="Borrar"
                                title="Borrar" className="action_img"
                                onClick={() => {
                                    fields.remove(index)
                                }}
                            />
                        </div>
                    </div>
                </td>
            </tr>
        </React.Fragment>
    );
};

const GenericTable = ({ fields, dollar_exchange, allValues, change }) => {
    const _months = months
    const calculateTotalForMonth = (month, isDollar) => {
        const allFields = fields.getAll() || [];
        let total = allFields.reduce((total, system) => {
            let value = parseFloat(system[month]) || 0;

            if (system.is_dollar) {
                value *= dollar_exchange;
            }

            return total + value;
        }, 0);
        if (isDollar && dollar_exchange) {
            total /= dollar_exchange;
        }
        return total;
    };
    const columnas = Array.from({ length: 12 }, (_, index) => (
        <th key={index} style={{ width: "6.9%" }}>{index + 1}</th>
    ));

    return (

        <div className=" col-sm-12 form-group np-r p-0">

            <div className="scroll-container">
                <div className="scroll-content-shopping">
                    <table className="table mb-0 text-center table-striped">
                        <thead>
                            <tr>
                                <th className='first-column table-form-fixed-cell-left-0'>CONCEPTO</th>
                                <th className='table-form-fixed-cell-left-1'>TOTAL</th>
                                {columnas.map(columna => columna)}
                                <th style={{ width: '6.9%' }}> ¿Genera IVA?</th>
                                <th style={{ width: '6.9%' }}> ¿Es mano de obra?</th>

                                <th style={{ width: "6.9%" }}>ACCIONES</th>
                            </tr>
                        </thead>
                        <tbody style={{ backgroundColor: "#F0FFC7" }}>
                            {fields.map((system, index) => <TableRow key={index} system={system} index={index} fields={fields} change={change} />)}
                            <tr style={{ backgroundColor: "#F0FFC7", borderBottom: '1px solid #035B2F', borderTop: '1px solid #035B2F' }}>
                                <td className='table-form-fixed-cell-left-0'>TOTALES Q</td>
                                <td className='table-form-fixed-cell-left-1'>
                                    {new Intl.NumberFormat('es-GT', { style: 'currency', currency: 'GTQ' }).format(calculateTotalForMonth('total'))}
                                </td>
                                {_months.map((month, idx) => (
                                    <td key={idx} className="custom-cell text-center">
                                        {new Intl.NumberFormat('es-GT', { style: 'currency', currency: 'GTQ' }).format(calculateTotalForMonth(month))}
                                    </td>
                                ))}
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr style={{ backgroundColor: "#F0FFC7" }}>
                                <td className='table-form-fixed-cell-left-0'>TOTALES $</td>
                                <td className='table-form-fixed-cell-left-1'>
                                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(calculateTotalForMonth('total', true))}
                                </td>
                                {_months.map((month, idx) => (
                                    <td key={idx} className="custom-cell text-center">
                                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(calculateTotalForMonth(month, true))}
                                    </td>
                                ))}
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="mt-2">
                <div type="button" className="btn btn-light btn-lg btn-block d-flex justify-content-end agregar-tab-generica" onClick={() => fields.push({ isNew: true, is_dollar: true })}>
                    AGREGAR
                </div>
            </div>
        </div>
    );
}

const AdministrativosTable = ({ fieldName, dollar_exchange, allValues, change }) => {
    return (
        <div>
            <FieldArray
                name={`${fieldName}`}
                dollar_exchange={dollar_exchange}
                component={GenericTable}
                allValues={allValues}
                change={change}
            />
        </div>
    );
}

const formName = 'AdministrativosForm';

const selector = formValueSelector(formName);

const mapStateToProps = (state) => {

    const categoryBudget = selector(state, 'category_budget') || [];
    const allValues = categoryBudget.map(item => item);
    return { allValues };

};

export default connect(mapStateToProps)(AdministrativosTable);