import React from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../../Utils/Grid/StandardActions";
import Grid from "../../../../Utils/Grid";
import { columnaClaseFija, columnaFijaTotales,formatearNumeroToneladas } from '../../../../../../utility/staticColumns';

const ListSubProduction = props => {
    const { data, loader, onSortChange } = props;
    const month = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
    if (!data || !Array.isArray(data.results)) {
        if (loader) {
            return <div>Cargando datos...</div>;
        }
        return <div>No hay datos disponibles para mostrar.</div>;
    }
    const columnaClaseTotal = (cell, row) => {
        if (row.id === 'totales') {
            return 'celda-totales'; // Esta es la clase CSS que necesitarás definir
        }
        return '';
    };

    const totales = month.reduce((acc, mes) => {
        acc[mes] = data.results.reduce((sum, row) => sum + parseFloat(row[mes] || 0), 0);
        return acc;
    }, {});

    const totalAnual = Object.values(totales).reduce((sum, value) => sum + value, 0);

    const dataConTotales = {
        results: [
            ...data.results,
            { ...totales, name: 'TOTALES', id: 'totales', total: totalAnual }
        ]
    };
    const columnaAcciones = (cell, row) => {
        if (row.id === 'totales') {
            return '';
        }
        return standardActions({ ver: "sub" })(cell, row);
    };
    const formatearNumero = (dato) => {
        const formatoMoneda = new Intl.NumberFormat('es-GT', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        const numero = Number(dato);
        return formatoMoneda.format(numero);
    }

    const columnas = month.map((mes, index) => (
        <TableHeaderColumn
            key={index}
            dataField={mes}
            dataSort
            columnClassName={columnaClaseTotal}
            dataFormat={formatearNumeroToneladas}
            width='130'
        >
            {index + 1}
        </TableHeaderColumn>
    ));

    return (
        <Grid hover striped data={dataConTotales} loading={loader} onSortChange={onSortChange} pagination={false}
            className="pb-3"
        >

            <TableHeaderColumn
                dataField="name"
                dataSort
                className="table-fixed-cell-left-0"
                columnClassName={columnaClaseFija}
                width='170'
            >
                PRODUCTO
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField='total'
                className="table-fixed-cell-left-1"
                columnClassName={columnaFijaTotales}
                dataSort
                dataFormat={formatearNumeroToneladas}
                width='140'
            >
                TOTAL
            </TableHeaderColumn>
            {columnas}
            <TableHeaderColumn
                dataField="id"
                dataAlign="center"
                isKey
                dataSort
                columnClassName={columnaClaseTotal}
                dataFormat={columnaAcciones}
                width='100'
            >
                ACCIONES
            </TableHeaderColumn>

        </Grid>

    )
};
export default ListSubProduction;