import React, { useState } from 'react';
import { Field, FieldArray } from 'redux-form';
import { renderDollar, renderFieldDisabled, renderCurrency, renderFieldCheck, renderDollarDisabled, renderCurrencyDisabled } from '../../../../Utils/renderField/renderField';
import { renderField } from "../../../../Utils/renderField/renderField"
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { TYPE_OF_ROW } from '../../../../../../utility/constants';
import { months } from '../../../../Utils/months';
import Swal from 'sweetalert2';
const alerta = require('assets/img/icons/alerta_roja.png');

const TableRow = ({ system, index, fields, change, eliminar, currency }) => {
    const _months = months
    const is_manual = fields.get(index).is_manual;
    const isNew = fields.get(index).isNew;
    const isTitulo = fields.get(index).isTitulo;
    const isEntradas = fields.get(index).type_of_flow === "salidas";
    const currentRow = fields.get(index)

    React.useEffect(() => {
        change(`${system}.is_dollar`, currency === 'USD');
    }, [currency, system, change]);

    const delete_category = (index, id) => {
        let message = '¿Estás seguro de confirmar todos los cambios?';
        const SwalMod = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-primary btn-modal',
                cancelButton: 'btn btn-outline-dark mr-5 btn-modal',
                content: '',
                title: 'darck',
                popup: 'custom-modal-size',
            },
            buttonsStyling: false,
            showCloseButton: true,
            focusConfirm: false,
            focusCancel: false,
            padding: "1em",
        });

        SwalMod.fire({
            title: 'ADVERTENCIA',
            html: 'Si eliminas la categoría perderás toda la información, <br/>' + message,
            imageUrl: alerta,
            imageAlt: 'Alerta',
            showCancelButton: true,
            confirmButtonText: 'CONTINUAR',
            cancelButtonText: 'CANCELAR',
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                fields.remove(index);
                eliminar(id)

            }
        });
    }

    const calculateTotalForRow = () => {
        const systemData = fields.get(index);
        if (!systemData) return 0;

        let totalForRow = _months.reduce((accumulatedTotal, month) => {
            const value = parseFloat(systemData[month]) || 0;
            accumulatedTotal += value;
            return accumulatedTotal;
        }, 0);

        // setear el nuevo valor del total en el sistema
        fields.get(index).total = totalForRow;

        return totalForRow;
    };

    const disabledStyle = isEntradas ? { color: 'red' } : {}
    const style = isEntradas ? { width: "8%", color: 'red' } : { width: "8%" }

    const handleClick = () => {
        if (isNew) {
            fields.remove(index);
        } else {
            delete_category(index, currentRow.id);
        }
    };

    return (
        <React.Fragment key={index}>
            <tr key={`system-${index}`}>
                <td className='first-column table-form-fixed-cell-left-0'>
                    <Field
                        name={`${system}.name`}
                        type="text"
                        isTextarea={!isTitulo}
                        applyStyles={!isTitulo}
                        style={disabledStyle}
                        component={is_manual ? renderField : renderFieldDisabled}
                        className="form-control"
                        placeholder='Nuevo'
                    />
                </td>
                <td className='table-form-fixed-cell-left-1' style={style}>

                    {
                        currency === 'USD'
                            ?
                            new Intl.NumberFormat('es-US', { style: 'currency', currency: 'USD' }).format(calculateTotalForRow(true))
                            :
                            new Intl.NumberFormat('es-GT', { style: 'currency', currency: 'GTQ' }).format(calculateTotalForRow())
                    }

                </td>
                {months.map((month) => (
                    <td key={month} style={{ width: "6.9%" }}>
                        <Field
                            name={`${system}.${month}`}
                            type="text"
                            component={currency === 'USD' ? renderDollar : renderCurrency}
                            disabledStyle={disabledStyle}
                            className="form-control"
                            placeholder={fields.get(index).is_dollar ? '$ 0.00' : 'Q 0.00'}
                            // Para calcular el total correctamente
                            onChange={(_, newValue) => {
                                fields.get(index)[month] = newValue;
                                calculateTotalForRow();
                            }}
                        />
                    </td>
                ))}

                <td className="text-center sin-borde-top">
                    {is_manual && <div>
                        <img src={require('assets/img/icons/Accion-Eliminar.svg')} alt="Borrar"
                            title="Borrar" className="action_img"

                            onClick={handleClick}
                        />
                    </div>
                    }
                </td>

            </tr>
        </React.Fragment>
    );
};

const GenericTable = ({ fields, dollar_exchange, allValues, change, eliminar, currency }) => {
    const _months = months
    const calculateTotalForMonth = (month) => {
        const allFields = fields.getAll() || [];
        let total = allFields.reduce((total, system) => {
            let value = parseFloat(system[month]) || 0;

            return total + value;
        }, 0);

        return total;
    };
    const columnas = Array.from({ length: 12 }, (_, index) => (
        <th key={index} style={{ width: "6.9%" }}>{index + 1}</th>
    ));

    return (
        <div className=" col-sm-12 form-group np-r p-0">
            <div className='scroll-container'>
                <div className='scroll-content-shopping'>
                    <table className="table mb-0 text-center table-striped">
                        <thead>
                            <tr>
                                <th className='first-column table-form-fixed-cell-left-0'>FLUJOS OPERATIVOS</th>
                                <th className='table-form-fixed-cell-left-1'>TOTAL</th>
                                {columnas}
                                <th>ACCIONES</th>
                            </tr>
                        </thead>
                        <tbody style={{ backgroundColor: "#F0FFC7" }}>
                            {fields.map((system, index) => <TableRow key={index} system={system} index={index} fields={fields} change={change} eliminar={eliminar} currency={currency} />)}

                            {currency !== 'USD' ?
                                (<tr className="celda-totales">
                                    <td className='table-form-fixed-cell-left-0'>TOTALES Q</td>
                                    <td className='table-form-fixed-cell-left-1'>
                                        {new Intl.NumberFormat('es-GT', { style: 'currency', currency: 'GTQ' }).format(calculateTotalForMonth('total'))}
                                    </td>
                                    {months.map((month, idx) => (
                                        <td key={idx}>
                                            {new Intl.NumberFormat('es-GT', { style: 'currency', currency: 'GTQ' }).format(calculateTotalForMonth(month))}
                                        </td>
                                    ))}
                                    <td></td>
                                </tr>)
                                :
                                (
                                    <tr className="celda-totales">
                                        <td className='table-form-fixed-cell-left-0'>TOTALES $</td>
                                        <td className='table-form-fixed-cell-left-1'>
                                            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(calculateTotalForMonth('total'))}
                                        </td>
                                        {months.map((month, idx) => (
                                            <td key={idx}>
                                                {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(calculateTotalForMonth(month))}
                                            </td>
                                        ))}
                                        <td></td>
                                    </tr>)
                            }


                        </tbody>
                    </table>
                </div>
            </div>
            <div className="mt-2">
                <div type="button" className="btn btn-light btn-lg btn-block d-flex justify-content-end agregar-tab-generica" onClick={() => fields.push({ is_manual: true, isNew: true, is_dollar: true, type_of_row: TYPE_OF_ROW["OPERATING"] })}>
                    AGREGAR
                </div>
            </div>
        </div>
    );
}

const FlujosOperativosTable = ({ dollar_exchange, allValues, change, eliminar, currency }) => {

    return (
        <div>
            <FieldArray
                name={`operation`}
                dollar_exchange={dollar_exchange}
                component={GenericTable}
                allValues={allValues}
                change={change}
                eliminar={eliminar}
                currency={currency}
            />
        </div>
    );
}

const formName = 'ComparativoFlujoCajaForm';

const selector = formValueSelector(formName);

const mapStateToProps = (state) => {

    const categoryBudget = selector(state, 'operation.salidas') || [];
    const allValues = categoryBudget.map(item => item);
    return { allValues };

};

export default connect(mapStateToProps)(FlujosOperativosTable);
