import React from 'react';

import { Field } from 'redux-form';
import { renderSwitch, renderCurrency} from '../../Utils/renderField/renderField';

const ProduccionFinca = props => {
    return (
        <div>
                <h2 className='titulo_parrafos'>Finca entre ríos</h2>
                <div className='fn-info-encabezado'>
                    <div className='row  col-md-12 p-0'>
                        <div className='col-md-3 p-0'>
                            <span className='finca-entre-rios'>¿Se incluye finca?</span>
                            <Field name="includes_finca" component={renderSwitch} type="text" className="form-control" />
                        </div>
                        <div className='col-md-3 p-0'>
                            <span className='finca-entre-rios'>¿La finca produce latex?</span>
                            <Field name="produces_latex"component={renderSwitch} type="text" className="form-control" />
                        </div>
                        <div className='col-md-3 p-0'>
                            <span className='finca-entre-rios'>1 Dólar estadounidense Es igual a</span>
                            <Field name="dollar_exchange" component={renderCurrency} type="text" className="form-control" placeholder='7.87 GTQ' />
                        </div>
                    </div>
                </div>
                <br />
        </div>
    );
}
export default ProduccionFinca;