import React from 'react';
import CardForm from '../../../../../Utils/Cards/CardForm';
import { reduxForm } from 'redux-form';
import FlujosFinancierosTable from './FlujosFinancierosTable';
import FlujosInversionTable from './FlujosInversionTable';
import FlujosOperacionTable from './FlujosOperacionTable';
import ResumenTable from './ResumenTable';
import LoadMask from 'Utils/LoadMask/LoadMask';

let ComparativoFlujoCajaForm = props => {
    const { handleSubmit, editar, item, eliminar, data } = props;

    if (!data || !data.year_list) {
        return (
            <LoadMask blur>
            </LoadMask>
        );
    }


    return (
        <form onSubmit={handleSubmit}>
            <div className='col-sm-12 p-0'>
                <CardForm
                    icon="Side_activos/Admin.svg"
                    titulo="PROYECCIONES DE FLUJO DE CAJA"
                    subtitulo="RESUMEN"
                    subtituloForm={editar ? "EDITAR" : "NUEVO"}
                    col="12"
                    noShadow
                >

                    <ResumenTable {...props} dollar_exchange={item} eliminar={eliminar} year_list={data.year_list} />
                    <FlujosOperacionTable {...props} dollar_exchange={item} eliminar={eliminar} year_list={data.year_list} />
                    <FlujosInversionTable {...props} dollar_exchange={item} />
                    <br />
                    <FlujosFinancierosTable {...props} dollar_exchange={item} eliminar={eliminar} year_list={data.year_list} />
                </CardForm>
            </div>
        </form>
    );
};

ComparativoFlujoCajaForm = reduxForm({
    form: 'ComparativoFlujoCajaForm',
})(ComparativoFlujoCajaForm);

export default ComparativoFlujoCajaForm;
