import React, { Component } from 'react'
import Card from "Utils/Cards/Card"
import Tabs, { TabPane } from "rc-tabs";
import CardEmpty from '../../../../Utils/Cards/CardEmpty';
import Listado from './Listado2';
import AnnualList from '../Anuales/AnnualList';
import SummaryModal from './Modal/CategoriaModal'
import AnnualDebtManagementEdit from '../Anuales/Create/AnnualDebtManagementEdit'
import AnnualSummaryModal from '../Anuales/Modal/AnnualModal'
export default class DebtManagementSummary extends Component {
    state = {
        open: false,
        openAnnual: false
    };
    componentDidMount() {
        const { getDeptManagementSummary } = this.props;
        getDeptManagementSummary();
    }

    openModalCategory = () => {
        this.setState({ open: true });
    };
    closeModalCategory = () => {
        this.setState({ open: false });
    };

    openAnnualModalCategory = () => {
        this.setState({ openAnnual: true });
    };
    closeAnnualModalCategory = () => {
        this.setState({ openAnnual: false });
    };



    render() {
        const { setTab, tab, item } = this.props;
        return (
            <div className="mb-4 col-12">
                <Card noShadow
                    icon="Side_activos/deuda.svg"
                    titulo="PRESUPUESTO MANEJO DE DEUDA"
                    subtitulo="RESUMEN"
                    noBorder
                >
                    <Tabs
                        defaultActiveKey={tab}
                        activeKey={tab}
                        onChange={tab => setTab(tab)}
                        animated={{ tabPane: true, inkBar: true }}
                    >
                        <TabPane tab="EN CURSO" key="EN_CURSO">
                            <CardEmpty noShadow>
                                <div className="row col-md-12 p-0 mt-2 mb-4">
                                    <div className="col-md-6">
                                        <h4 className="mr-2 titulo_principal">PRESUPUESTO MANEJO DE DEUDA</h4>
                                    </div>
                                </div>
                                <div className='mb-4'>

                                    <Listado
                                        open={this.state.open}
                                        closeModal={this.closeModalCategory}
                                        openModal={this.openModalCategory}
                                        {...this.props} />
                                </div>
                            </CardEmpty>
                        </TabPane>
                        <TabPane tab="PROYECCIONES ANUALES RESUMEN" key="PROYECCIONES_ANUALES_RESUMEN">
                            <CardEmpty noShadow>
                                <div className="row col-md-12 p-0 mt-2 mb-4">
                                    <div className="col-md-6">
                                        <h4 className="mr-2 titulo_principal">PRESUPUESTO MANEJO DE DEUDA ANUALIZADO</h4>
                                    </div>
                                </div>
                                <div className='mb-4'>

                                    <AnnualList
                                        open={this.state.openAnnual}
                                        closeModal={this.closeAnnualModalCategory}
                                        openModal={this.openAnnualModalCategory}
                                        {...this.props} />
                                </div>
                            </CardEmpty>
                        </TabPane>
                        <TabPane tab="PROYECCIONES ANUALES CONFIGURACIÓN" key="PROYECCIONES_ANUALES_CONFIGURACION">
                            <CardEmpty noShadow>
                                <AnnualDebtManagementEdit {...this.props} dollar_exchange={item} />
                            </CardEmpty>
                        </TabPane>
                    </Tabs>

                    <SummaryModal
                        open={this.state.open}
                        closeModal={this.closeModalCategory}
                        {...this.props}
                    />
                    <AnnualSummaryModal
                        open={this.state.openAnnual}
                        closeModal={this.closeAnnualModalCategory}
                        {...this.props}
                    />

                </Card>
            </div>
        )
    }
}