import React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import Grid from '../../../../Utils/Grid';
import editar_img from 'assets/img/icons/ver.svg';
import { columnaClaseFija, columnaFijaTotales } from '../../../../../../utility/staticColumns';
const AnnualList = (props) => {
    const { loader, onSortChange, Annualshow, openModal, annual_data_summary } = props;

    if (!annual_data_summary || !Array.isArray(annual_data_summary.results)) {
        if (loader) {
            return <div>Cargando datos...</div>;
        }
        return <div>No hay datos disponibles para .</div>;
    }

    if (!annual_data_summary ||
        !annual_data_summary.years_list ||
        !Array.isArray(annual_data_summary.years_list)) {
        if (loader) {
            return <div>Cargando datos...</div>;
        }
        return <div>No hay datos disponibles para .</div>;
    }

    const abrirModalYConsultar = (id) => {
        Annualshow(id);
        openModal();
    };

    const formatearNumero = (dato) => {
        const numero = Number(dato);

        if (isNaN(numero)) {
            return '--';
        }

        const formatoMoneda = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        return formatoMoneda.format(numero).replace('$', '$ ');
    };

    // Crear las filas de datos en formato "Capital" e "Interés" separados
    const generateRows = (annual_data_summary) => {
        return annual_data_summary.results.flatMap(row => {
            const capital = Array.isArray(row.capital) ? row.capital : [];
            const interes = Array.isArray(row.interes) ? row.interes : [];

            const capitalRow = {
                id: row.id,
                name: row.name,
                type: 'Capital',
                is_annual: row.is_annual,
                ...capital.reduce((acc, value, index) => {
                    acc[`year_${annual_data_summary.years_list[index]}`] = value;
                    return acc;
                }, {}),
                total: capital.reduce((sum, value) => sum + value, 0)
            };

            const interesRow = {
                id: `${row.id}_interes`,
                name: '',
                type: 'Interés',
                is_annual: row.is_annual,
                ...interes.reduce((acc, value, index) => {
                    acc[`year_${annual_data_summary.years_list[index]}`] = value;
                    return acc;
                }, {}),
                total: interes.reduce((sum, value) => sum + value, 0)
            };

            return [capitalRow, interesRow];
        });
    };

    // Función para calcular totales separados de Capital e Interés
    const calculateTotals = (rows) => {
        const totalsCapital = {};
        const totalsInteres = {};
        const totalErogado = {};

        annual_data_summary.years_list.forEach((year) => {
            const totalCapital = rows.reduce((sum, row) => {
                return row.type === 'Capital' ? sum + (Number(row[`year_${year}`]) || 0) : sum;
            }, 0);
            const totalInteres = rows.reduce((sum, row) => {
                return row.type === 'Interés' ? sum + (Number(row[`year_${year}`]) || 0) : sum;
            }, 0);

            totalsCapital[`year_${year}`] = totalCapital;
            totalsInteres[`year_${year}`] = totalInteres;
            totalErogado[`year_${year}`] = totalCapital + totalInteres;
        });

        return { totalsCapital, totalsInteres, totalErogado };
    };

    const columnaClaseTotal = (cell, row) => {
        if (['totalesCapital', 'totalesInteres', 'totalErogado'].includes(row.id)) {
            return 'celda-totales';
        }
        return ' ';
    };

    const columnaAcciones = (cell, row) => {
        if (row.type !== 'Capital' || ['totalesCapital', 'totalesInteres', 'totalErogado'].includes(row.id) || row.is_annual) {
            return '';
        }
        return (
            <div>
                <button
                    type='button'
                    onClick={() => abrirModalYConsultar(row.id)}
                    style={{ border: 'none', background: 'none' }}
                >
                    <img src={editar_img} alt="Editar" className="action_img" />
                </button>
            </div>
        );
    };

    // Generar filas y calcular totales
    const rows = generateRows(annual_data_summary);
    const { totalsCapital, totalsInteres, totalErogado } = calculateTotals(rows);

    // Agregar filas de totales de Capital, Interés, y Total Erogado
    rows.push({
        id: 'totalesCapital',
        name: 'AMORTIZACIÓN CAPITAL/DEVOLUCIONES',
        type: '',
        ...totalsCapital,
        total: Object.values(totalsCapital).reduce((sum, value) => sum + value, 0)
    });

    rows.push({
        id: 'totalesInteres',
        name: 'INTERESES',
        type: '',
        ...totalsInteres,
        total: Object.values(totalsInteres).reduce((sum, value) => sum + value, 0)
    });

    rows.push({
        id: 'totalErogado',
        name: 'TOTAL EROGADO POR AÑO',
        type: '',
        ...totalErogado,
        total: Object.values(totalErogado).reduce((sum, value) => sum + value, 0)
    });

    const customHeaderText = (text) => {
        const words = text.split(/[-/]/);
        const lastWord = words.pop();
        return (
            <div>
                {words.join(' ')}
                <br />
                {lastWord}
            </div>
        );
    };

    return (
        <div>
            <Grid
                hover
                striped
                data={{ results: rows }}
                loading={loader}
                onSortChange={onSortChange}
                pagination={false}
                className="pb-3"
                height='400'
            >
                <TableHeaderColumn
                    dataField="name"
                    dataSort
                    isKey
                    width="170"
                    dataFormat={customHeaderText}
                    className="table-fixed-cell-left-0"
                    columnClassName={columnaClaseFija}
                >
                    PRESUPUESTO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="type"
                    dataSort
                    columnClassName={columnaClaseTotal}
                    dataFormat={(cell) => (
                        <span style={{ color: cell === 'Interés' ? 'red' : 'black' }}>
                            {cell}
                        </span>
                    )}
                    width='90'
                >
                    TIPO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="total"
                    dataAlign="center"
                    dataSort
                    dataFormat={(cell, row) => (
                        <span style={{ color: row.id === 'totalesInteres' || row.type === 'Interés' ? 'red' : 'black' }}>
                            {formatearNumero(cell)}
                        </span>
                    )}
                    className="table-fixed-cell-left-1"
                    columnClassName={columnaFijaTotales}
                    width='130'
                >
                    TOTAL
                </TableHeaderColumn>
                {annual_data_summary.years_list.map((year) => (
                    <TableHeaderColumn
                        key={`year_${year}`}
                        dataField={`year_${year}`}
                        dataSort
                        dataFormat={(cell, row) => (
                            <span style={{ color: row.id === 'totalesInteres' || row.type === 'Interés' ? 'red' : 'black' }}>
                                {formatearNumero(cell)}
                            </span>
                        )}
                        columnClassName={columnaClaseTotal}
                        width='130'
                    >
                        {year}
                    </TableHeaderColumn>
                ))}

                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    dataSort
                    columnClassName={columnaClaseTotal}
                    dataFormat={columnaAcciones}
                    width='100'
                >
                    ACCIONES
                </TableHeaderColumn>
            </Grid>
        </div>
    );
};

export default AnnualList;