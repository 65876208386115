import React, { useState } from 'react';
import { months } from '../../Utils/months'
import { Field, FieldArray } from 'redux-form';
import { renderDollar, renderFieldDisabled, renderDollarDisabled, renderNumberPercentage } from '../../Utils/renderField/renderField';


const handleChange = (change, dataForm) => {
    const ivaRate = 1.12; // 12% IVA
    if (dataForm && dataForm.purchase_price_table && dataForm.purchase_price_table.generic_row) {

        const tableIva = dataForm.purchase_price_table.generic_row;

        tableIva.forEach(item => {
            if (item.name.includes('SIN IVA')) {
                const itemWithIva = tableIva.find(i => i.name === item.name.replace('SIN IVA', 'CON IVA'));

                if (itemWithIva) {
                    let totalWithIva = 0;
                    let totalWithoutIva = 0;
                    months.forEach(month => {
                        const priceWithIva = parseFloat(item[month]) * ivaRate;
                        change(`purchase_price_table.generic_row[${tableIva.indexOf(itemWithIva)}].${month}`, priceWithIva);
                        totalWithIva += priceWithIva;
                        totalWithoutIva += parseFloat(item[month]);
                    });
                    const averageWithIva = totalWithIva / months.length;
                    const averageWithoutIva = totalWithoutIva / months.length;
                    change(`purchase_price_table.generic_row[${tableIva.indexOf(itemWithIva)}].total`, averageWithIva);
                    change(`purchase_price_table.generic_row[${tableIva.indexOf(item)}].total`, averageWithoutIva);
                }
            }
        });

    }
};

const TableRow = ({ system, index }) => {

    const isDisabled = index === 1 || index === 3;
    const isSpecialRow = index === 0 || index === 2;

    return (
        <React.Fragment key={index}>
            {isSpecialRow && (
                <tr>
                    <td className="text-center sin-borde-top" style={{ width: "10%", pointerEvents: "none", height: "40px" }}>
                        {index === 0 ? 'LATEX' : 'SOLIDOS'}
                    </td>
                    <td colSpan={13}></td>
                </tr>
            )}
            <tr style={{ backgroundColor: "#F0FFC7" }} key={`system-${index}`} >
                <td className="text-center sin-borde-top" style={{ width: "10%", pointerEvents: "none" }}>
                    <Field name={`${system}.name`} type="text" component={renderFieldDisabled} className="form-control" />
                </td>
                {months.map((month) => (
                    <td key={month} style={{ width: "6.9%" }}>
                        <Field name={`${system}.${month}`} type="text" component={isDisabled ? renderDollarDisabled : renderDollar} className="form-control" />
                    </td>
                ))}
                <td style={{ width: "6.9%" }}>
                    <Field name={`${system}.total`} type="text" disabled={true} component={renderDollarDisabled} className="form-control" />
                </td>
            </tr>
        </React.Fragment>
    );
};

const TableSistem = ({ fields }) => (
    <div className=" col-sm-12 form-group np-r p-0">
        <div className='scroll-container'>
            <div className='scroll-content'>
                <div className="react-bs-container-header table-header-wrapper">
                    <table className="table mb-0 ">
                        <thead>
                            <tr>
                                <th style={{ width: "10%" }}>SISTEMA</th>
                                <th style={{ width: "6.9%" }}>1</th>
                                <th style={{ width: "6.9%" }}>2</th>
                                <th style={{ width: "6.9%" }}>3</th>
                                <th style={{ width: "6.9%" }}>4</th>
                                <th style={{ width: "6.9%" }}>5</th>
                                <th style={{ width: "6.9%" }}>6</th>
                                <th style={{ width: "6.9%" }}>7</th>
                                <th style={{ width: "6.9%" }}>8</th>
                                <th style={{ width: "6.9%" }}>9</th>
                                <th style={{ width: "6.9%" }}>10</th>
                                <th style={{ width: "6.9%" }}>11</th>
                                <th style={{ width: "6.9%" }}>12</th>
                                <th style={{ width: "6.9%" }}>TOTAL</th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className="tabla-con-borde">
                    <table className="table table-sm  m-0 table-striped">
                        <tbody>
                            {fields.map((system, index) => <TableRow key={index} system={system} index={index} />)}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
);

const TablaPrecioCompra = props => {
    const { change, dataForm, tab } = props;
    if (tab === 'COMPRAS') {
        handleChange(change, dataForm);
    }

    return (
        <div>
            <div className='fn-info mt-4'>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="credito5" className="m-0">PRIMA POR PRODUCCIÓN NF</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="prima_for_production_nf" component={renderDollar} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">% DE PRODUCCIÓN NF</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="prima_for_production_nf_porcentage" component={renderNumberPercentage} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='hr-info'>
                <hr />
            </div>
            <span className='fn-info-sb'>Precios de fletes y comisiones</span>
            <div className='fn-info'>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="credito5" className="m-0">Porcentaje del volumen de latex con fletes</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="percentage_of_latex_volume_with_freight" component={renderNumberPercentage} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">Porcentaje del volumen de solidos con fletes</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="percentage_of_solids_volume_with_freight" component={renderNumberPercentage} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">Costo /kg flete latex</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="cost_per_kg_of_latex_freight" decimalScale={4} component={renderDollar} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='fn-info'>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="credito5" className="m-0">Costo /kg flete solidos</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="cost_per_kg_of_solid_freight" decimalScale={4} component={renderDollar} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">Comisiones</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="commissions_for_shopping" decimalScale={4} component={renderDollar} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='hr-info'>
                <hr />
            </div>
            <h2 className='titulo_parrafos'>V. precios de compra </h2>

            <div className='fn-info m-b-4'>
                <FieldArray name="purchase_price_table.generic_row" component={TableSistem} />
            </div>
        </div>
    );

}
export default TablaPrecioCompra;