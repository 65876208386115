const validate = values => {
    const errors = {};
    const message = 'Campo requerido';

    if (values && values.generic_row_sales && values.generic_row_sales.length > 0) {
        const genericRowSalesErrors = [];

        values.generic_row_sales.forEach((genericRow, index) => {
            const rowErrors = {};

            // Validación para cada elemento en generic_row_sales
            if (!genericRow.customer) {
                rowErrors.customer = message;
            }
            if (!genericRow.packing) {
                rowErrors.packing = message;
            }
            if (!genericRow.depure_port) {
                rowErrors.depure_port = message;
            }

            // Se agregan los errores al array si existen
            if (Object.keys(rowErrors).length > 0) {
                genericRowSalesErrors[index] = rowErrors;
            }
        });

        // Se asignan los errores al objeto de errores si existen
        if (genericRowSalesErrors.length > 0) {
            errors.generic_row_sales = genericRowSalesErrors;
        }
    }

    return errors;
}

export default validate;
