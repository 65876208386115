import React from 'react';
import { Modal } from 'react-responsive-modal';
import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import ModalForm from './ModalForm';

let VatDragModal = props => {
    const { open, closeModal, handleSubmit, selectedDebtType, isEditMode } = props;

    return (
        <Modal
            open={open}
            onClose={closeModal}
            center
            classNames={{
                overlayAnimationIn: 'customEnterOverlayAnimation',
                overlayAnimationOut: 'customLeaveOverlayAnimation',
                modalAnimationIn: 'customEnterModalAnimation',
                modalAnimationOut: 'customLeaveModalAnimation',
                modal: 'customModalSize',
            }}
        >
            <div>


                <div className="pb-4 titulo-modal d-flex justify-content-center">
                    <div className='col-md-12 p-0 mt-2 text-center'>
                        <h4>CRÉDITO FISCAL DE ARRASTRE</h4>
                    </div>
                </div>

                <form onSubmit={handleSubmit.bind(selectedDebtType)}>
                    <div className='ml-4 mr-4'>
                        <ModalForm {...props}
                            selectedDebtType={selectedDebtType}
                            isEditMode={isEditMode}
                        />
                    </div>

                    <div className='position-relative'>
                        <div className="buttons-box mt-4 mb-4">
                            <button className="btn btn-outline-dark mr-3 btn-modal" onClick={e => {
                                e.preventDefault();
                                closeModal();
                            }}>
                                CANCELAR
                            </button>
                            <button type="submit" className="btn btn-primary m-4 btn-modal">
                                CONFIRMAR
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    )
};

VatDragModal = reduxForm({
    form: 'VatDragForm',
})(VatDragModal);

export default VatDragModal;