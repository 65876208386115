import { connect } from 'react-redux';
import { actions } from '../../../../../../redux/modules/debt_management/debt_management';
import { getMe } from "../../../../../../redux/modules/cuenta/login";
import DebtManagementEdit from './DebtManagementEdit';

const mstp = state => {
    return {...state.debt_management}
};

const mdtp = {
    ...actions, getMe
};

export default connect(mstp, mdtp)(DebtManagementEdit)
