import React from 'react';
import { Field, FieldArray } from 'redux-form';
import { renderDollar, renderFieldDisabled, renderCurrency, renderFieldCheck } from '../../../../../Utils/renderField/renderField';
import { renderField } from "../../../../../Utils/renderField/renderField";
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { months as meses } from '../../../../../Utils/months';

const TableRow = props => {
    const { system, index, fields, dollar_exchange, kg_proyectados } = props;

    const total_kg_proyectados = (kg_proyectados_data, months) => {
        let totalForRow = months.reduce((accumulatedTotal, month) => {
            const value = parseFloat(kg_proyectados_data[month]) || 0;
            accumulatedTotal += value;
            return accumulatedTotal;
        }, 0);
        return totalForRow;
    };

    const months = meses;
    const isNew = fields.get(index).isNew;
    const kg_proyectados_data = kg_proyectados.values.kg_proyectados.generic_row[0];

    const calculateTotalForMonth = (row, month, isDollar) => {
        let value = parseFloat(row[month]) || 0;

        if (kg_proyectados_data && kg_proyectados_data[month]) {
            value /= kg_proyectados_data[month];
        }
        if (row.is_dollar && dollar_exchange) {
            value *= dollar_exchange;
        }
        if (isDollar && dollar_exchange) {
            value /= dollar_exchange;
        }

        return value;
    };

    const calculateTotalForRow = (isDollar) => {
        let kg_proyectados_total = total_kg_proyectados(kg_proyectados_data, months);
        const systemData = fields.get(index);
        if (!systemData) return 0;

        let totalForRow = months.reduce((accumulatedTotal, month) => {
            const value = parseFloat(systemData[month]) || 0;
            accumulatedTotal += value;
            return accumulatedTotal;
        }, 0);

        if (isDollar && dollar_exchange) {
            totalForRow /= dollar_exchange;
        }
        if (kg_proyectados && isDollar) {
            totalForRow /= kg_proyectados_total;
        }

        return totalForRow;
    };

    return (
        <tr key={`system-${index}`}>
            <td className='first-column table-form-fixed-cell-left-0'>
                <Field
                    name={`${system}.name`}
                    type="text"
                    component={isNew ? renderField : renderFieldDisabled}
                    placeholder="Nuevo"
                />
                Costo por Kg
            </td>
            <td className='table-form-fixed-cell-left-1'>
                {new Intl.NumberFormat('es-GT', { style: 'currency', currency: 'GTQ' }).format(calculateTotalForRow())}
                <br />
                {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(calculateTotalForRow(true))}
            </td>
            {months.map((month, monthIndex) => (
                <td key={month}>
                    <Field
                        name={`${system}.${month}`}
                        type="text"
                        component={fields.get(index).is_dollar ? renderDollar : renderCurrency}
                        placeholder={fields.get(index).is_dollar ? '$ 0.00' : 'Q 0.00'}
                    />
                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(calculateTotalForMonth(fields.get(index), month, true))}
                </td>
            ))}
            <td>
                <Field
                    name={`${system}.is_iva_credit`}
                    component={renderFieldCheck}
                />
            </td>
            <td>
                <Field
                    name={`${system}.is_mano_de_obra`}
                    component={renderFieldCheck}
                />
            </td>
            <td>
                <img
                    src={require('assets/img/icons/Accion-Eliminar.svg')}
                    alt="Borrar"
                    title="Borrar"
                    className="action_img"
                    onClick={() => {
                        fields.remove(index);
                    }}
                />
            </td>
        </tr>
    );
};

const GenericTable = props => {
    const { fields, item, values_kg } = props;
    const months = meses;
    const dollar_exchange = item;

    const values_kg_copy = values_kg.values;
    var kg_values_row = 0;

    if (values_kg_copy) {
        const kg_values = values_kg_copy.kg_proyectados;
        if (kg_values) {
            kg_values_row = kg_values.generic_row[0];
        }
    }

    const calculateTotalForMonth = (month, isDollar, isKg) => {
        const allFields = fields.getAll() || [];
        let total = allFields.reduce((total, system) => {
            let value = parseFloat(system[month]) || 0;

            if (system.is_dollar) {
                value *= dollar_exchange;
            }

            return total + value;
        }, 0);

        if (kg_values_row && isKg && kg_values_row[month]) {
            total /= kg_values_row[month];
        }
        if (isDollar && dollar_exchange) {
            total /= dollar_exchange;
        }
        return total;
    };

    const columnas = Array.from({ length: 12 }, (_, index) => (
        <th key={index}>{index + 1}</th>
    ));

    return (
        <div className="col-sm-12 form-group p-0">
            <div className="scroll-container">
                <div className="scroll-content-shopping">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th className='first-column table-form-fixed-cell-left-0'>CONCEPTO</th>
                                <th className='table-form-fixed-cell-left-1'>TOTAL</th>
                                {columnas}
                                <th>¿Genera IVA?</th>
                                <th>¿Es mano de obra?</th>
                                <th>ACCIONES</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fields.map((system, index) => (
                                <TableRow
                                    key={index}
                                    system={system}
                                    index={index}
                                    fields={fields}
                                    dollar_exchange={dollar_exchange}
                                    kg_proyectados={values_kg}
                                />
                            ))}
                            <tr className="celda-totales">
                                <td className='table-form-fixed-cell-left-0'>TOTALES Q</td>
                                <td className='table-form-fixed-cell-left-1'>
                                    {new Intl.NumberFormat('es-GT', { style: 'currency', currency: 'GTQ' }).format(calculateTotalForMonth('total'))}
                                </td>
                                {months.map((month, idx) => (
                                    <td key={idx}>
                                        {new Intl.NumberFormat('es-GT', { style: 'currency', currency: 'GTQ' }).format(calculateTotalForMonth(month))}
                                    </td>
                                ))}
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr className="celda-totales">
                                <td className='table-form-fixed-cell-left-0'>TOTALES $</td>
                                <td className='table-form-fixed-cell-left-1'>
                                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(calculateTotalForMonth('total', true))}
                                </td>
                                {months.map((month, idx) => (
                                    <td key={idx}>
                                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(calculateTotalForMonth(month, true))}
                                    </td>
                                ))}
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr className="celda-totales">
                                <td className='table-form-fixed-cell-left-0'>Totales costo por kg</td>
                                <td className='table-form-fixed-cell-left-1'>
                                {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(calculateTotalForMonth('total',true, true))}
                                </td>
                                {months.map((month, idx) => (
                                    <td key={idx}>
                                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(calculateTotalForMonth(month, true, true))}
                                    </td>
                                ))}
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="mt-2">
                <button
                    type="button"
                    className="btn btn-light btn-lg btn-block d-flex justify-content-end agregar-tab-generica"
                    onClick={() => fields.push({ isNew: true })}
                >
                    AGREGAR
                </button>
            </div>
        </div>
    );
};

const ProductionLatexTable = props => {
    return (
        <div>
            <FieldArray
                name="budget_finca_latex_table.generic_row"
                component={GenericTable}
                props={props}
            />
        </div>
    );
};

const formName = 'FincaForm';
const selector = formValueSelector(formName);

const mapStateToProps = state => {
    const categoryBudget = selector(state, 'generic_row') || [];
    const values_kg = state.form.FincaForm || {};
    const allValues = categoryBudget.map(item => item);
    return { values_kg, allValues };
};

export default connect(mapStateToProps)(ProductionLatexTable);