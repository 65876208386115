import React from 'react';
import ProductionLatexTable from "./Tables/ProductionLatexTable"
import CardEmpty from '../../../../Utils/Cards/CardEmpty';
import KgProjected from './Tables/KgProjected';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';

let FincaForm = props => {
    const { handleSubmit } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className='col-sm-12 p-0'>
                <CardEmpty noShadow
                    isMarginButtom>
                    <div className="col-md-3 p-0 mt-2">
                        <div className="md-12">
                            <h6 className="mb-4"><strong>KILOGRAMOS PROYECTADOS</strong></h6>
                        </div>
                    </div>
                    <div className='mb-4'>
                        <KgProjected {...props} />
                    </div>
                </CardEmpty>
                <CardEmpty
                    isMarginButtom
                    noShadow>
                    <div className="col-md-3 p-0 mt-2">
                        <div className="md-12">
                            <h6 className="mb-4"><strong>PRESUPUESTO DE PRODUCCIÓN DE FINCA</strong></h6>
                        </div>
                    </div>
                    <ProductionLatexTable
                        {...props}
                    />
                </CardEmpty>
            </div>
        </form>
    )
};
const formName = 'FincaForm';

const selector = formValueSelector(formName);

const mapStateToProps = (state) => {
    const admin_data_state = selector(state, 'projections');
    return {
        admin_data_state,
    };
};

export default connect(mapStateToProps)(
    reduxForm({
        form: formName,
    })(FincaForm)
);
