import React, { Component } from 'react'
import LoadMask from 'Utils/LoadMask/LoadMask';
import InvestmentsFlow from './FlujosInversionTable'
import CardEmpty from '../../../../Utils/Cards/CardEmpty';
import ComparativoFlujoCajaForm from './ComparativoFlujoCajaForm';
import Tabs, { TabPane } from "rc-tabs";
import ComparativoFlujoCajaFormAnnual from './Annual/ComparativoFlujoCajaFormAnnual';

export default class ComparativoFlujoCajaReport extends Component {
    componentDidMount() {
        const { page } = this.props;
        if (this.props.tab === 'EN_CURSO') {
            this.props.getDollar();
            this.props.read(page);
        } else {
            this.props.readAnnual();
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.tab !== prevProps.tab) {
            if (this.props.tab === 'EN_CURSO') {
                this.props.getDollar();
                this.props.read();
            } else {
                this.props.readAnnual();
            }
        }
    }

    actualizar = (data) => {
        const id = data.id
        this.props.editar(id, data);
    };

    getPermissions = () => {
        const { getMe } = this.props;
        getMe();
    }

    render() {
        const { editar } = this.props;
        const { updateData, loader, item, setTab, tab } = this.props;
        return (
            <div className="mb-4 col-12">
                <LoadMask loading={loader} blur>
                    <Tabs
                        defaultActiveKey={tab}
                        activeKey={tab}
                        onChange={tab => setTab(tab)}
                        animated={{ tabPane: true, inkBar: true }}
                    >

                        <TabPane tab="EN CURSO" key="EN_CURSO">
                            <CardEmpty noShadow isMarginButtom>
                                <ComparativoFlujoCajaForm
                                    onSubmit={this.actualizar}
                                    updateData={updateData}
                                    item={item}
                                    editando={true}
                                    permisos={this.getPermissions}
                                    {...this.props}
                                />
                            </CardEmpty>
                        </TabPane>
                        <TabPane tab="PROYECCIONES ANUALES" key="PROYECCIONES_ANUALES">
                            <CardEmpty noShadow isMarginButtom>
                                <ComparativoFlujoCajaFormAnnual
                                    onSubmit={this.actualizar}
                                    updateData={updateData}
                                    item={item}
                                    editando={true}
                                    permisos={this.getPermissions}
                                    {...this.props}
                                />
                            </CardEmpty>
                        </TabPane>
                    </Tabs>


                </LoadMask>
            </div>
        )
    }
}

