import React, { Component } from 'react'
import Card from "Utils/Cards/Card"
import AdministrativosEnCurso from "./AdministrativosEnCursoList"
import Tabs, { TabPane } from "rc-tabs";
import CardEmpty from '../../../../Utils/Cards/CardEmpty';
import AdministrativosAnuales from '../Anuales/AdministrativosAnuales';

export default class AdministrativosResumen extends Component {
    componentDidMount() {
        const { page } = this.props;
        if (this.props.tab === 'EN_CURSO') {
            this.props.getDollar();
            this.props.getAdministrativos(page);
        } else {
            this.props.summary_annual_list();
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.tab !== prevProps.tab) {
            if (this.props.tab === 'EN_CURSO') {
                this.props.getDollar();
                this.props.getAdministrativos();
            } else {
                this.props.summary_annual_list();
            }
        }
    }

    actualizar = (data) => {
        this.props.edit_annual(data);
    };

    render() {
        const { setTab, tab, item } = this.props;
        return (
            <div className="mb-4 col-12">
                <Card noShadow
                    icon="Side_activos/Admin.svg"
                    titulo="PRESUPUESTO ADMINISTRATIVO"
                    subtitulo="RESUMEN"
                    noBorder
                >
                    <Tabs
                        defaultActiveKey={tab}
                        onChange={tab => setTab(tab)}
                        animated={{ tabPane: true, inkBar: true }}
                    >
                        <TabPane tab="EN CURSO" key="EN_CURSO">
                            <CardEmpty noShadow>
                                <div className="row col-md-12 p-0 mt-2 mb-4">
                                    <div className="col-md-6">
                                        <h4 className="mr-2 titulo_principal">PRESUPUESTO ADMINISTRATIVO</h4>
                                    </div>
                                </div>
                                <div className='mb-4'>
                                    <AdministrativosEnCurso {...this.props} dollar_exchange={item} />
                                </div>
                            </CardEmpty>
                        </TabPane>
                        <TabPane tab="PROYECCIONES ANUALES" key="PROYECCIONES_ANUALES">
                            <CardEmpty noShadow>
                                <AdministrativosAnuales
                                    {...this.props}
                                    onSubmit={this.actualizar}
                                    dollar_exchange={item}
                                />
                            </CardEmpty>
                        </TabPane>
                    </Tabs>

                </Card>
            </div>
        )
    }
}