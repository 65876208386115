import React, { useState, useEffect } from 'react';
import { Modal } from 'react-responsive-modal';
import { Field, reduxForm } from 'redux-form';
import { renderSwitch, renderFieldDisabled } from '../../../../../Utils/renderField/renderField';
import { api } from 'api';
import { months } from '../../../../../Utils/months';
import { TableHeaderColumn } from 'react-bootstrap-table';
import Grid from '../../../../../Utils/Grid';

const CategoriaModal = props => {
    const { open,
        closeModal,
        initialValues, }
        = props

    const [name, setName] = useState(initialValues.name || '');
    const [is_paking, setIsPaking] = useState(initialValues.is_paking || false);
    const [is_normal, setIsNormal] = useState(initialValues.is_normal || false);
    const [packings, setPacking] = useState(initialValues.recipe_row || []);
    const [is_mano_de_obra, setIsManoDeObra] = useState(initialValues.is_mano_de_obra || false);

    useEffect(() => {
        setName(initialValues.name || '');
        setIsPaking(initialValues.is_paking || false);
        setIsManoDeObra(initialValues.is_mano_de_obra || false);
        setIsNormal(initialValues.is_normal || false)
        setPacking(initialValues.packings || []);
        if (initialValues.is_paking) {
            handleRecipeChange(true);
        }
    }, [initialValues]);

    const fetchData = (endpoint, params) => {
        return api.get(endpoint, { params })
            .then((data) => {
                if (data) return data.results;
                return [];
            })
            .catch(() => {
                return [];
            });
    };

    const handleRecipeChange = async (event) => {
        setIsPaking(event);
        if (event) {
            setIsManoDeObra(false);
            const data = await fetchData('/packing', {});
            setPacking(data);
        } else {
            setRecipes([]);
        }
    };

    const handleNameChange = (event) => {
        const value = event.target.value;
        setName(value)
    }

    const handleManualChange = (event) => {
        setIsManoDeObra(event);
        if (event) {
            setIsPaking(!event);
        }
    };
    // #####################################################################
    const { loader, onSortChange, data_modal } = props;

    const formatearNumero = (dato) => {
        const formatoMoneda = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        const numero = Number(dato);
        return formatoMoneda.format(numero).replace('$', '$ ');
    };

    const meses = months;

    const columnas = meses.map((mes, index) => (
        <TableHeaderColumn
            key={index}
            dataField={mes}
            dataSort
            dataFormat={formatearNumero}
        >
            {index + 1}
        </TableHeaderColumn>
    ));

    const formatUnitMeasurement = (cell, row) => {
        if (cell) {
            // Redondear el valor a 7 decimales
            const formattedValue = parseFloat(cell).toFixed(7);
            return `${formattedValue} kg`;
        }
        return '--';
    };
    const formatDollar = (cell, row) => {
        if (cell && cell !== '--') {
            const formattedValue = parseFloat(cell).toFixed(7);
            return `$ ${formattedValue}`;
        }
        return '--';
    };
    const formatQuetzal = (cell, row) => {
        if (cell) {
            const formattedValue = parseFloat(cell).toFixed(7);
            return `Q ${formattedValue}`;
        }
        return '--';
    };
    const columnaClaseTotal = (cell, row) => {
        if (row.name === 'Total') {
            return 'celda-totales';
        }
        return '';
    };
    const formatPackageDollar = (cell) => {
        if (cell !== undefined && cell !== null) {
            const formattedValue = parseFloat(cell).toFixed(7);
            return `$ ${formattedValue}`;
        }
        return '--';
    };
    const packageColumns = packings.map((pkg) => (
        <TableHeaderColumn
            key={pkg.id}
            dataField={`packing_${pkg.id}`}
            dataSort
            dataFormat={formatPackageDollar}
            columnClassName={columnaClaseTotal}
        >
            {pkg.name.toUpperCase()}
        </TableHeaderColumn>
    ));


    return (
        <Modal
            open={open}
            onClose={closeModal}
            center
            classNames={{
                overlayAnimationIn: 'customEnterOverlayAnimation',
                overlayAnimationOut: 'customLeaveOverlayAnimation',
                modalAnimationIn: 'customEnterModalAnimation',
                modalAnimationOut: 'customLeaveModalAnimation',
                modal: 'customModalSizeLatex',
            }}
        >

            <div className="pb-4 titulo-modal d-flex justify-content-center">
                <div className="col-md-12 p-0 mt-2 text-center">
                    <h4>RESUMEN CATEGORÍA DE COSTO</h4>
                </div>

            </div>
            <div className="row justify-content-center">
                <div className="col-md-3">
                    <div className="form-group">
                        <label htmlFor="name">Nombre</label>
                        <Field
                            name="name"
                            type="text"
                            component={renderFieldDisabled}
                            placeholder="Nombre"
                            value={name}
                            onChange={handleNameChange}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <span className="finca-entre-rios">¿Es receta de empaque?</span>
                        <Field
                            name="is_paking"
                            label="Si"
                            disabled
                            component={renderSwitch}
                            type="checkbox"
                            onChange={handleRecipeChange}
                            checked={is_paking}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <span className="finca-entre-rios">¿Es mano de obra?</span>
                        <Field
                            name="is_mano_de_obra"
                            label="Si"
                            disabled
                            component={renderSwitch}
                            type="checkbox"
                            onChange={handleManualChange}
                            checked={is_mano_de_obra}
                        />
                    </div>
                </div>


            </div>
            {is_normal && <div>
                <Grid
                    hover
                    striped
                    data={data_modal.results}
                    loading={loader}
                    onSortChange={onSortChange}
                    pagination={false}
                    className="pb-3"

                >
                    <TableHeaderColumn
                        dataField="name"
                        dataSort
                        isKey
                        columnClassName={columnaClaseTotal}
                    >
                        RUBRO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="unit_measurement"
                        dataSort
                        dataFormat={formatUnitMeasurement}
                        columnClassName={columnaClaseTotal}
                    >
                        MEDIDA/kg
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cost_quetzal"
                        dataSort
                        dataFormat={formatQuetzal}
                        columnClassName={columnaClaseTotal}
                    >
                        COSTO Q.
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cost_dollar"
                        dataSort
                        dataFormat={formatDollar}
                        columnClassName={columnaClaseTotal}
                    >
                        COSTO $.
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cv"
                        dataSort
                        dataFormat={formatDollar}
                        columnClassName={columnaClaseTotal}
                    >
                        CV
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="srg10/20"
                        dataSort
                        dataFormat={formatDollar}
                        columnClassName={columnaClaseTotal}
                    >
                        SGR10/20
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="skim"
                        dataSort
                        dataFormat={formatDollar}
                        columnClassName={columnaClaseTotal}
                    >
                        SKIM
                    </TableHeaderColumn>



                </Grid>
                <br />
            </div>}
            {is_paking && (
                <div>
                    <Grid
                        hover
                        striped
                        data={data_modal.results}
                        loading={loader}
                        onSortChange={onSortChange}
                        pagination={false}
                        className="pb-3"
                    >
                        <TableHeaderColumn
                            dataField="name"
                            dataSort
                            isKey
                            columnClassName={columnaClaseTotal}
                        >
                            RUBRO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="unit_measurement"
                            dataSort
                            dataFormat={formatUnitMeasurement}
                            columnClassName={columnaClaseTotal}
                        >
                            MEDIDA/kg
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="cost_quetzal"
                            dataSort
                            dataFormat={formatQuetzal}
                            columnClassName={columnaClaseTotal}
                        >
                            COSTO Q.
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="cost_dollar"
                            dataSort
                            dataFormat={formatDollar}
                            columnClassName={columnaClaseTotal}
                        >
                            COSTO $.
                        </TableHeaderColumn>
                        {packageColumns}
                    </Grid>
                    <br />
                </div>
            )}
            {is_mano_de_obra &&
                <div>
                    <Grid
                        hover
                        striped
                        data={data_modal.results}
                        loading={loader}
                        onSortChange={onSortChange}
                        pagination={false}
                        className="pb-3"
                    >
                        <TableHeaderColumn
                            dataField="name"
                            dataSort
                            isKey
                        >
                            PRESUPUESTO
                        </TableHeaderColumn>
                        {columnas}
                    </Grid>
                    <br />
                </div>
            }
        </Modal>
    );
};


const ConnectedCategoriaModal = CategoriaModal;

export default reduxForm({
    form: 'CategoriaModal'
})(ConnectedCategoriaModal);
