import React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import Grid from '../../../../Utils/Grid';
import { months } from '../../../../Utils/months';
import { columnaClaseFija, columnaFijaTotales } from '../../../../../../utility/staticColumns';
const Listado = (props) => {
    const { data, loader, onSortChange, openModal, readShow } = props;

    const formatearNumero = (dato) => {
        const formatoMoneda = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        const numero = Number(dato);
        return formatoMoneda.format(numero).replace('$', '$ ');
    };
    const columnaClaseTotal = (cell, row) => {
        if (row.name === 'TOTALES' || row.name === 'Por kg. Seco') {
            return 'celda-totales ';
        }
        return '';
    };
    const meses = months;

    const columnas = meses.map((mes, index) => (
        <TableHeaderColumn
            key={index}
            dataField={mes}
            dataSort
            dataFormat={formatearNumero}
            columnClassName={columnaClaseTotal}
            width='130'
        >
            {index + 1}
        </TableHeaderColumn>
    ));





    return (
        <div>
            <Grid
                hover
                striped
                data={!data || !Array.isArray(data.results) ? [] : data}
                loading={loader}
                onSortChange={onSortChange}
                pagination={false}
                className="pb-3"
            >
                <TableHeaderColumn
                    dataField="name"
                    dataSort
                    isKey
                    className="table-fixed-cell-left-0"
                    columnClassName={columnaClaseFija}
                    tdStyle={{ whiteSpace: 'normal' }}
                    width='170'
                >
                    RUBRO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField='total'
                    dataSort
                    className="table-fixed-cell-left-1"
                    columnClassName={columnaFijaTotales}
                    dataFormat={formatearNumero}
                    width='130'
                >
                    TOTAL
                </TableHeaderColumn>
                {columnas}

            </Grid>
            <br />
        </div>
    )
};

export default Listado;
