const columnaClaseFija = (cell, row) => {
    let classes = 'table-fixed-cell-left-0';
    if (row.name === 'TOTALES'
        || row.name === 'Costo de produccion/kg'
        || row.name === 'Por kg. Seco'
        || row.name === 'Total'
        || row.name === 'Total en $'
        || row.name === 'TOTALES Q'
        || row.name === 'TOTALES $'
        || row.name === 'AMORTIZACIÓN CAPITAL/DEVOLUCIONES'
        || row.name === 'INTERESES'
        || row.name === 'TOTAL EROGADO POR MES'
        || row.name === 'TOTAL EROGADO POR AÑO'
    ) {
        classes += ' celda-totales';
    }
    return classes;
};

const columnaFijaTotales = (cell, row) => {
    let classes = 'table-fixed-cell-left-1';
    if (row.name === 'TOTALES'
        || row.name === 'Costo de produccion/kg'
        || row.name === 'Por kg. Seco'
        || row.name === 'TOTALES Q'
        || row.name === 'TOTALES $'
        || row.name === 'AMORTIZACIÓN CAPITAL/DEVOLUCIONES'
        || row.name === 'INTERESES'
        || row.name === 'TOTAL EROGADO POR MES'
        || row.name === 'TOTAL EROGADO POR AÑO'
    ) {
        classes += ' celda-totales';
    }
    return classes;
};

const formatearNumeroToneladas = (dato) => {
    if (dato === null || dato === undefined || isNaN(dato)) {
        return '';
    }
    const formatoMoneda = new Intl.NumberFormat('es-GT', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    // 1 tonelada tiene 1000 kg
    const kilos_for_tonelada = 1000 
    const toneladas = Number(dato) / kilos_for_tonelada;
    return formatoMoneda.format(toneladas) + ' TM';
}


export { columnaClaseFija, columnaFijaTotales,formatearNumeroToneladas };