import React, { useState, useEffect } from 'react';
import { Field, FieldArray } from 'redux-form';
import {
    renderFieldDisabled,
    renderDollarDisabled,
    renderQuetzalesDisabled
} from '../../../../../Utils/renderField/renderField';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { months } from '../../../../../Utils/months';

const TableRow = ({ system, index, fields }) => {
    return (
        <React.Fragment key={index}>
            <tr key={`system-${index}`}>
                <td className='first-column table-form-fixed-cell-left-0'>
                    <Field
                        name={`${system}.name`}
                        type="text"
                        component={renderFieldDisabled}
                        className="form-control"
                        placeholder='Nuevo'
                    />
                </td>
                <td className='table-form-fixed-cell-left-1' style={{width: '8%'}}>
                    <Field
                        name={`${system}.total`}
                        type="text"
                        disabled={true}
                        component={fields.get(index).is_dollar ? renderDollarDisabled : renderQuetzalesDisabled}
                        placeholder={fields.get(index).is_dollar ? '$ 0.00' : 'Q 0.00'}
                        className="form-control" />
                </td>
                {months.map((month) => (
                    <td key={month} style={{ width: "6.9%" }}>
                        <Field
                            name={`${system}.${month}`}
                            type="text"
                            component={fields.get(index).is_dollar ? renderDollarDisabled : renderQuetzalesDisabled}
                            className="form-control"
                            placeholder={fields.get(index).is_dollar ? '$ 0.00' : 'Q 0.00'}
                        />
                    </td>
                ))}
            </tr>
        </React.Fragment>
    );
};

const GenericTable = ({ fields, dollar_exchange, allValues }) => {

    const calculateTotalForMonth = (month, isDollar) => {
        const allFields = fields.getAll() || [];
        let total = allFields.reduce((total, system) => {
            let value = parseFloat(system[month]) || 0;

            if (system.is_dollar) {
                value *= dollar_exchange;
            }

            return total + value;
        }, 0);
        if (isDollar && dollar_exchange) {
            total /= dollar_exchange;
        }
        return total;
    };

    const [totals, setTotals] = useState({});

    useEffect(() => {
        const newTotals = months.reduce((acc, month) => {
            acc[month] = {
                totalQ: calculateTotalForMonth(month).toFixed(2),
                totalDollar: calculateTotalForMonth(month, true).toFixed(2)
            };
            return acc;
        }, {});
        setTotals(totals);
    }, [allValues, dollar_exchange]);
    const columnas = Array.from({ length: 12 }, (_, index) => (
        <th key={index}>{index + 1}</th>
    ));

    return (
        <div className=" col-sm-12 form-group np-r p-0">
            <div className='scroll-container'>
                <div className='scroll-content-shopping'>
                    <table className="table mb-0 text-center table-striped">
                        <thead>
                            <tr>
                                <th className='first-column table-form-fixed-cell-left-0'>CONCEPTO</th>
                                <th className='table-form-fixed-cell-left-1'>TOTAL</th>
                                {columnas}
                            </tr>
                        </thead>

                        <tbody style={{ backgroundColor: "#F0FFC7" }}>
                            {fields.map((system, index) => <TableRow key={index} system={system} index={index} fields={fields} />)}

                            <tr className="celda-totales">
                                <td className='table-form-fixed-cell-left-0'>TOTALES Q</td>
                                <td className='table-form-fixed-cell-left-1'>
                                    {new Intl.NumberFormat('es-GT', { style: 'currency', currency: 'GTQ' }).format(calculateTotalForMonth('total'))}
                                </td>
                                {months.map((month, idx) => (
                                    <td key={idx}>
                                        {new Intl.NumberFormat('es-GT', { style: 'currency', currency: 'GTQ' }).format(calculateTotalForMonth(month))}
                                    </td>
                                ))}
                            </tr>
                            <tr className="celda-totales">
                                <td className='table-form-fixed-cell-left-0'>TOTALES $</td>
                                <td className='table-form-fixed-cell-left-1'>
                                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(calculateTotalForMonth('total', true))}
                                </td>
                                {months.map((month, idx) => (
                                    <td key={idx}>
                                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(calculateTotalForMonth(month, true))}
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

const AdministrativosEnCursoTable = ({ fieldName, dollar_exchange, allValues, change }) => {
    return (
        <div>
            <FieldArray
                name={`${fieldName}`}
                dollar_exchange={dollar_exchange}
                component={GenericTable}
                allValues={allValues}
                change={change}
            />

        </div>
    );
}

const formName = 'AdministrativosForm';

const selector = formValueSelector(formName);

const mapStateToProps = (state) => {

    const categoryBudget = selector(state, 'category_budget') || [];
    const allValues = categoryBudget.map(item => item.category_table);
    return { allValues };

};

export default connect(mapStateToProps)(AdministrativosEnCursoTable);