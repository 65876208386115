import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
import { initialize as initializeForm } from 'redux-form';
import { api } from "api";
import Swal from 'sweetalert2';
const alerta = require('assets/img/icons/activo.png');

let url = 'costosMP';
let form = 'CostosMPForm';
// ------------------------------------
// Constants
// ------------------------------------
export const constants = {
    LOADER: 'COSTO_MP_LOADER',
    DATA_COMPRAS: 'COSTO_MP_COMPRAS',
    ITEM_DATA: 'COSTO_MP_ITEM',
    PAGE: 'COSTO_MP_PAGE',
    ORDERING: 'COSTO_MP_ORDERING',
    SEARCH: 'COSTO_MP_SEARCH',
    TAB_EN_CURSO: 'COSTO_MP_TAB_EN_CURSO',
    PAGE_EN_CURSO: 'PAGE_EN_CURSO',
    LATEX_TABLE: 'LATEX_TABLE',
    CHIPA_TABLE: 'CHIPA_TABLE',
    SET_STEP: 'COSTO_MP_SET_STEP'
}

// -----------------------------------
// Pure Actions
// -----------------------------------
const setLoader = loader => ({
    type: constants.LOADER,
    loader,
});
const setDataCompras = dataCompras => ({
    type: constants.DATA_COMPRAS,
    dataCompras,
});

const setItem = item => ({
    type: constants.ITEM_DATA,
    item,
});
const setStep = (step) => ({
    type: constants.SET_STEP,
    step,
});

const confirm_shopping_save = (dispatch) => {
    const SwalMod = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-primary btn-modal',
            content: '',
            title: 'darck',
            popup: 'custom-modal-size',
        },
        buttonsStyling: false,
        showCloseButton: true,
        focusConfirm: false,
        padding: "1em",
    });

    SwalMod.fire({
        title: 'FELICIDADES',
        text: 'Tu formulario se ha realizado de forma exitosa',
        imageUrl: alerta,
        imageAlt: 'Alerta',
        confirmButtonText: 'CONTINUAR',
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            dispatch(setStep(1))
            dispatch(getDataCompras())
        } else {
            dispatch(getDataCompras())
            dispatch(setStep(1))
        }
    });
}

// -----------------------------------
// Actions
// -----------------------------------
const getDataCompras = year => (dispatch) => {
    dispatch(setLoader(true));
    const params = { year: year };

    api.get(`${'shopping'}/get_shopping`, params)
        .then((response) => {
            dispatch(setDataCompras(response));

            // const latex_table = processDataForLatexTable(response, false);
            // const chipa_table = processDataForChipaTable(response, false);
            // dispatch({
            //     type: constants.LATEX_TABLE,
            //     latex_table
            // });
            // dispatch({
            //     type: constants.CHIPA_TABLE,
            //     chipa_table
            // });
            // const formData = {
            //     ...results,
            //     latex_table: latex_table,
            //     chipa_table: chipa_table
            // };
            // dispatch(initializeForm(form, formData));
        })

        .catch((error) => {
            console.error('Error fetching data:', error);
            NotificationManager.error((error && error.detail) ? error.detail : 'Error en lectura de datos', 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};


const processDataForLatexTable = (data, annual) => {
    const { results, drc_promedio_general_latex } = data

    const drc_latex_initial = annual ? drc_promedio_general_latex : results.drc_promedio_general_latex.generic_row[0] || []
    // var production_exportable = []
    const latex_table = [
        { name: 'Compra de látex (costo neto)', },
        { name: 'kILOS hUMEDOS', },
        { name: 'drc promedio general', data: drc_latex_initial, is_drc: true },
    ];
    latex_table.forEach(item => {
        item.total = 0;
        for (let mes in item.data) {
            item.total += item.data[mes];
        }
        Object.assign(item, item.data);
    });

    return latex_table;
}

const processDataForChipaTable = (data, annual) => {
    const { results, drc_promedio_general_chipa } = data
    const drc_chipa_initial = annual ? drc_promedio_general_chipa : results.drc_promedio_general_chipa.generic_row[0] || []

    const chipa_table = [
        { name: 'Compra de chispa (costo neto)', },
        { name: 'kILOS hUMEDOS', },
        { name: 'drc promedio general', data: drc_chipa_initial, is_drc: true },
        { name: 'kilos secos equivalentes', },
        { name: 'precio por kilo seco (sin iva)', },
        { name: 'Precio por kilo seco (CON IVA)', },
        { name: 'PAGO NETO POR COMPRAS SIN IVA', },
        { name: 'PAGO BRUTO POR COMPRAS CON IVA', },
        { name: 'IMPORTE DEL IVA PAGADO', },

    ];
    chipa_table.forEach(item => {
        item.total = 0;
        for (let mes in item.data) {
            item.total += item.data[mes];
        }
        Object.assign(item, item.data);
    });

    return chipa_table;
}

const getDollar = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`administrative/get_dollar_exchange`).then((response) => {
        dispatch(setItem(response.dollar_exchange));
        dispatch(initializeForm(form, response.results));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${url}/${id}`, data).then(() => {
        confirm_shopping_save(dispatch);
    }).catch(() => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error en la edición', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const edit_annual = (data) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}/shopping_annual_update`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push('/shopping/summary'));
    }).catch(() => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error en la edición', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

//  ----------------
// Shopping Resumen
// -----------------
const setTab = (tab) => (dispatch) => {
    dispatch(setLoader(true));
    dispatch({ type: constants.TAB_EN_CURSO, tab });
    dispatch(setLoader(false));
};

export const actions = {
    editar,
    getDataCompras,
    getDollar,
    setTab,
    processDataForLatexTable,
    setStep,
    edit_annual

};

// -----------------------------------
// Reducers
// -----------------------------------
const reducers = {
    [constants.LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [constants.DATA_COMPRAS]: (state, { dataCompras }) => {
        return {
            ...state,
            dataCompras,
        };
    },

    [constants.ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE_EN_CURSO]: (state, { page_activas }) => {
        return {
            ...state,
            page_activas,
        };
    },
    [constants.PAGE_ANUALES]: (state, { page_finalizadas }) => {
        return {
            ...state,
            page_finalizadas,
        };
    },
    [constants.TAB_EN_CURSO]: (state, { tab }) => {
        return {
            ...state,
            tab,
        };
    },
    [constants.LATEX_TABLE]: (state, { latex_table }) => {
        return {
            ...state,
            latex_table,
        };
    },
    [constants.CHIPA_TABLE]: (state, { chipa_table }) => {
        return {
            ...state,
            chipa_table,
        };
    },
    [constants.SET_STEP]: (state, { step }) => {
        return {
            ...state,
            step,
        };
    },

};
export const initialState = {
    loader: false,
    dataCompras: {
        results: [],
        count: 0,
    },
    latex_table: {},
    chipa_table: {},
    item: {},
    tab: 'EN_CURSO',
    step: 1,


};
export default handleActions(reducers, initialState)
