import React, { Component } from 'react'
import LoadMask from 'Utils/LoadMask/LoadMask';
import CardFormNoBorder from '../../../../Utils/Cards/CardFormNoBorder';
import Tabs, { TabPane } from 'rc-tabs';
import CostoMateriaPrimaForm from './CostoMateriaPrimaForm';

export default class CostoMateriaPrimaReport extends Component {

    state = {
        open: false,
    };
    componentDidMount() {
        this.props.getDollar()
        this.props.getDataCompras()
    }
    openModalCategory = () => {
        this.setState({ open: true });

    };
    closeModalCategory = () => {
        this.setState({ open: false });
    };

    actualizar = (data) => {
        this.props.editar(1, data);
    };

    getPermissions = () => {
        const { getMe } = this.props;
        getMe();
    }

    updateAnnual = (data) => {
        this.props.edit_annual(data);
    };

    render() {
        const { tab, setTab } = this.props;
        const { updateData, loader, item } = this.props;

        return (
            <div className="mb-4 col-12">
                <CardFormNoBorder
                    icon="Side_activos/finca.svg"
                    titulo="COSTO DE MATERIA PRIMA"
                    subtitulo="RESUMEN"
                    subtituloForm={"VER"}
                    col="12"
                    noShadow
                >

                </CardFormNoBorder>
                <Tabs
                    defaultActiveKey={tab}
                    onChange={tab => setTab(tab)}
                    animated={{ tabPane: true, inkBar: true }}
                >
                    <TabPane tab='COSTO MATERIA PRIMA' key="COSTO_MATERIA_PRIMA">
                        <LoadMask loading={loader} blur>
                            <CostoMateriaPrimaForm
                                onSubmit={this.actualizar}
                                updateData={updateData}
                                item={item}
                                editando={true}
                                permisos={this.getPermissions}
                                openModal={this.openModalCategory}
                                closeModal={this.closeModalCategory}
                                open={this.state.open}
                                {...this.props}
                            />
                        </LoadMask>
                    </TabPane>
                    <TabPane tab='COSTO REAL MATERIA PRIMA' key="COSTO_REAL_MATERIA_PRIMA">
                        <LoadMask loading={loader} blur>
                            <CostoMateriaPrimaForm
                                onSubmit={this.actualizar}
                                updateData={updateData}
                                item={item}
                                editando={true}
                                permisos={this.getPermissions}
                                openModal={this.openModalCategory}
                                closeModal={this.closeModalCategory}
                                open={this.state.open}
                                {...this.props}
                            />
                        </LoadMask>
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}

