import { connect } from 'react-redux';
import { actions } from '../../../../../../redux/modules/finca/finca';
import { getMe } from "../../../../../../redux/modules/cuenta/login";
import FincaEditSummary from './FincaEdit';

const mstp = state => {
    return {...state.finca}
};

const mdtp = {
    ...actions, getMe
};

export default connect(mstp, mdtp)(FincaEditSummary)
