import React from 'react';
import { Field } from 'redux-form';
import { renderDollar, renderField, renderNumberPercentage, renderFieldRadio, renderNumber, renderDatePicker, AsyncSelectField } from '../../../../../Utils/renderField/renderField';
import CardEmpty from '../../../../../Utils/Cards/CardEmpty';
import { api } from "api";

const TYPE_OF_DEBT = {
    LINE_OF_CREDIT: 10,
    PROMISSORY_NOTE: 20,
    BANK_CREDIT: 30,
    SALES_ADVANCE: 40,
};

const TYPE_OF_DEBT_LABELS = {
    10: 'LÍNEA DE CRÉDITO',
    20: 'PAGARÉ',
    30: 'CRÉDITO BANCARIO',
    40: 'ANTICIPO DE VENTAS'
};
const fetchData = (endpoint, params) => {
    return api.get(endpoint, params)
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};

const getCustomers = (search) => {
    return fetchData("customer", { search });
};

const ModalForm = ({ selectedDebtType, isEditMode }) => {

    return (
        <div className="col-12 p-0 mt-2">
            <div className="d-flex flex-wrap justify-content-between">
                <div className="form-group mr-3 flex-grow-1">
                    <label htmlFor="name" className="m-0">Nombre de deuda</label>
                    <Field
                        name="name"
                        component={renderField}
                        width="100%"
                        applyStyles={false}
                        isTextarea={false}
                    />
                </div>

                <div className="form-group mr-3 flex-grow-1">
                    {isEditMode ? (
                        <label>Tipo de deuda</label>
                    ) : (
                        <label>Seleccione el tipo de deuda</label>
                    )}
                    {isEditMode ? (
                        <p className='mr-3'>{TYPE_OF_DEBT_LABELS[selectedDebtType]}</p>
                    ) : (
                        Object.entries(TYPE_OF_DEBT).map(([key, value]) => (
                            <Field
                                key={value}
                                name="type_of_debt"
                                component={renderFieldRadio}
                                type="radio"
                                value={String(value)}
                                id={value}
                                label={TYPE_OF_DEBT_LABELS[value]}
                                parse={value => Number(value)}  // Convertimos a número antes de almacenarlo
                                format={value => String(value)}  // Convertimos a cadena antes de renderizarlo
                                checked={String(selectedDebtType) === String(value)}  // Comparación de cadenas
                            />
                        ))
                    )}
                </div>
            </div>

            <CardEmpty>
                {!selectedDebtType ? (
                    <div className="col-12 p-0 mt-2">
                        <div className="alert alert-warning" role="alert">
                            Seleccione tipo de crédito
                        </div>
                    </div>
                ) : (
                    selectedDebtType === TYPE_OF_DEBT.LINE_OF_CREDIT ? (
                        <div className="col-12 p-0 mt-2">
                            <div className="d-flex flex-wrap justify-content-between">
                                <div className="form-group mr-3 flex-grow-1">
                                    <label htmlFor="name" className="m-0">Porcentaje de interés</label>
                                    <Field
                                        name="debt_management_parameters.interest_rate"
                                        component={renderNumberPercentage}
                                        width="100%"
                                        style={{ fontWeight: "bold" }}
                                        placeholder='0.00%'
                                        applyStyles={false}
                                        isTextarea={false}
                                    />
                                </div>
                                <div className="form-group flex-grow-1">
                                    <label htmlFor="name" className="m-0">Días de plazo</label>
                                    <Field
                                        name="debt_management_parameters.days_of_term"
                                        component={renderNumber}
                                        width="100%"
                                        placeholder='0'
                                        applyStyles={false}
                                        isTextarea={false}
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-wrap justify-content-between">
                                <div className="form-group mr-3 flex-grow-1">
                                    <label htmlFor="name" className="m-0">Cuota autorizada</label>
                                    <Field
                                        name="debt_management_parameters.authorized_quota"
                                        component={renderDollar}
                                        width="100%"
                                        style={{ fontWeight: "bold" }}
                                        applyStyles={false}
                                        placeholder='$ 0.00'
                                        isTextarea={false}
                                    />
                                </div>
                                <div className="form-group flex-grow-1">
                                    <label htmlFor="name" className="m-0">Saldo inicial</label>
                                    <Field
                                        name="debt_management_parameters.opening_balance"
                                        component={renderDollar}
                                        width="100%"
                                        placeholder='$ 0.00'
                                        applyStyles={false}
                                        isTextarea={false}
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-wrap justify-content-between">
                                <div className="form-group mr-3 flex-grow-1">
                                    <label htmlFor="name" className="m-0">Otros gastos</label>
                                    <Field
                                        name="debt_management_parameters.other_expenses"
                                        component={renderDollar}
                                        width="100%"
                                        placeholder='$ 0.00'
                                        style={{ fontWeight: "bold" }}
                                        applyStyles={false}
                                        isTextarea={false}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : selectedDebtType === TYPE_OF_DEBT.SALES_ADVANCE ? (
                        <div className="col-12 p-0 mt-2">
                            <div className="d-flex flex-wrap justify-content-between">
                                <div className="form-group mr-3 flex-grow-1">
                                    <label htmlFor="name" className="m-0">Seleccione cliente</label>
                                    <Field
                                        name='debt_management_parameters.customer'
                                        component={AsyncSelectField}
                                        loadOptions={getCustomers}
                                        valueKey="id"
                                        labelKey="company"
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group flex-grow-1">
                                    <label htmlFor="name" className="m-0">Porcentaje de descuento</label>
                                    <Field
                                        name="debt_management_parameters.interest_rate"
                                        component={renderNumberPercentage}
                                        width="100%"
                                        placeholder='0.00%'
                                        style={{ fontWeight: "bold" }}
                                        applyStyles={false}
                                        isTextarea={false}
                                    />
                                </div>

                            </div>
                            <div className="d-flex flex-wrap justify-content-between">
                                <div className="form-group flex-grow-1">
                                    <label htmlFor="name" className="m-0">Días de plazo</label>
                                    <Field
                                        name="debt_management_parameters.days_of_term"
                                        component={renderNumber}
                                        width="100%"
                                        placeholder='0'
                                        applyStyles={false}
                                        isTextarea={false}
                                    />
                                </div>

                            </div>
                        </div>
                    ) : (
                        // Formulario compartido para PAGARÉ y CRÉDITO BANCARIO
                        <div className="col-12 p-0 mt-2">
                            <div className="d-flex flex-wrap justify-content-between">
                                <div className="form-group mr-3 flex-grow-1">
                                    <label htmlFor="name" className="m-0">Porcentaje de interés</label>
                                    <Field
                                        name="debt_management_parameters.interest_rate"
                                        component={renderNumberPercentage}
                                        width="100%"
                                        style={{ fontWeight: "bold" }}
                                        placeholder='0.00%'
                                        applyStyles={false}
                                        isTextarea={false}
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-wrap justify-content-between">
                                <div className="form-group mr-3 flex-grow-1">
                                    <label htmlFor="name" className="m-0">Inversión total</label>
                                    <Field
                                        name="debt_management_parameters.authorized_quota"
                                        component={renderDollar}
                                        width="100%"
                                        style={{ fontWeight: "bold" }}
                                        applyStyles={false}
                                        placeholder='$ 0.00'
                                        isTextarea={false}
                                    />
                                </div>
                                <div className="form-group mr-3 flex-grow-1">
                                    <label htmlFor="name" className="m-0">Otros gastos</label>
                                    <Field
                                        name="debt_management_parameters.other_expenses"
                                        component={renderDollar}
                                        width="100%"
                                        placeholder='$ 0.00'
                                        style={{ fontWeight: "bold" }}
                                        applyStyles={false}
                                        isTextarea={false}
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-wrap justify-content-between">

                                <div className="form-group mr-3 flex-grow-1">
                                    <label htmlFor="name" className="m-0">Fecha inicio</label>
                                    <Field
                                        name="debt_management_parameters.start_date"
                                        component={renderDatePicker}
                                    />
                                </div>
                                <div className="form-group mr-3 flex-grow-1">
                                    <label htmlFor="name" className="m-0">Fecha final</label>
                                    <Field
                                        name="debt_management_parameters.end_date"
                                        component={renderDatePicker}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                )}
            </CardEmpty>
        </div>
    );
};

export default ModalForm;
