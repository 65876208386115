import { connect } from 'react-redux';
import { actions } from '../../../../../../redux/modules/planta_latex/planta_latex';
import PlantaLatexList from "./PlantaLatex";


const ms2p = (state) => {
  return {
    ...state.planta_latex,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(PlantaLatexList);
