import { connect } from 'react-redux';
import {actions} from '../../../redux/modules/parameters/parameters' 
import Home from './Home';

const ms2p = (state) => {
  return {
    ...state.parameters,
    me: state.login.me,
  };
};

const md2p = { ...actions };

export default connect(ms2p,md2p)(Home);