import React from 'react';
import { Field, FieldArray } from 'redux-form';
import { renderDollar, renderNumberDisabled, renderNumberPercentage, renderToneladasDisabled } from '../../../../../Utils/renderField/renderField';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';

const calculateSalesData = (fields) => {
    if (!fields || fields.length === 0) return;

    let latexGrowthValues = [];
    let solidsPrice = [];
    let solidsPercentagePriceSkim = [];

    fields.forEach((item) => {
        const annualRows = item.annual_projection_row;

        if (annualRows && annualRows.length > 0) {
            const isLatex = item.name === 'LATEX';
            const isSkim = item.name === 'SKIM';
            const isSolids = item.name === 'SOLIDOS';

            if (isLatex) {
                latexGrowthValues = annualRows.map(row => row.growth_percentage);
            }

            if (isSolids) {
                solidsPrice = annualRows.map(row => row.sales_price);
                solidsPercentagePriceSkim =  annualRows.map(row => row.percentage_price_skim_on_solids_price);
            }

            const firstYear = annualRows[0];
            firstYear.value_kg = item.total;
            firstYear.value_dollar = firstYear.sales_price * firstYear.value_kg;

            for (let i = 0; i < annualRows.length; i++) {
                const currentYear = annualRows[i];
                // Debe aplicar unicamente para los años despues del presupuesto
                
                if (i > 0) {
                    const prevYear = annualRows[i - 1];
                    currentYear.value_kg = prevYear.value_kg * (1 + (currentYear.growth_percentage / 100));
                }
                currentYear.value_dollar = currentYear.sales_price * currentYear.value_kg;

                if (i === 0) {
                    continue; 
                }
                
                if (isSkim && latexGrowthValues.length > 0) {
                    currentYear.growth_percentage = latexGrowthValues[i];
                }

                if (isSkim && solidsPrice.length > 0 && solidsPercentagePriceSkim.length > 0) {

                    const percentagePriceSkimOnSolids = solidsPercentagePriceSkim[i] / 100;
                    currentYear.sales_price = solidsPrice[i] * percentagePriceSkimOnSolids;
                }
            }
        }
    });
};



const SalesBudgetTable = ({ fields, years }) => {
    if (!years || years.length === 0) {
        return <div>No hay datos de años disponibles</div>;
    }

    return (
        <div className="table-responsive">
            <table className="table table-bordered text-center sales-budget-table">
                <thead>
                    <tr className="table-header">
                        <th>Concepto</th>
                        {years.map(year => (
                            <th key={year} className="year-header">{year}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {fields.map((field, index) => {
                        const fieldData = fields.get(index);
                        return (
                            <React.Fragment key={index}>
                                <tr className="data-row">
                                    <td>{`Ventas ${fieldData && fieldData.name ? fieldData.name : ''} (kg)`}</td>
                                    {years.map((year, yearIndex) => (
                                        <td key={yearIndex}>
                                            <Field
                                                name={`${field}.annual_projection_row[${yearIndex}].value_kg`}
                                                component={renderToneladasDisabled}
                                                placeholder="0 Kg"
                                                suffix={" kg"}
                                                className="form-control"
                                            />
                                        </td>
                                    ))}
                                </tr>
                                <tr className="data-row">
                                    <td>{`Ventas ${fieldData && fieldData.name ? fieldData.name : ''} ($)`}</td>
                                    {years.map((year, yearIndex) => (
                                        <td key={yearIndex}>
                                            <Field
                                                name={`${field}.annual_projection_row[${yearIndex}].value_dollar`}
                                                component={renderNumberDisabled}
                                                placeholder="$0.00"
                                                prefix={"$ "}
                                                className="form-control"
                                            />
                                        </td>
                                    ))}
                                </tr>
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

const GrowthVariablesTable = ({ fields, years }) => {
    if (!years || years.length === 0) {
        return <div>No hay datos de años disponibles</div>;
    }

    return (
        <div className="growth-variables-section">
            {years.map((year, yearIndex) => {
                if (yearIndex === 0) return null;

                return (
                    <div key={year} className="growth-variable-entry mb-3">
                        <h5>{year}</h5>
                        <div className="row">
                            {fields.map((field, index) => {
                                const fieldData = fields.get(index);
                                const productName = fieldData && fieldData.name ? fieldData.name : '';
                                return (
                                    <div key={index} className="col-md-12 mb-3">
                                        <div className={`card border-secundary`} style={{ padding: '15px', marginBottom: '20px' }}>
                                            <div className="card-header bg-secundary text-black">
                                                {productName}
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-3 mb-2">
                                                        <label>{`Precio de venta`}</label>
                                                        <Field
                                                            disabled = {productName === 'SKIM' ? true : false}
                                                            name={`${field}.annual_projection_row[${yearIndex}].sales_price`}
                                                            component={renderDollar}
                                                            className="form-control growth-field"
                                                            placeholder="$0.00"
                                                        />
                                                    </div>
                                                    <div className="col-md-3 mb-2">
                                                        <label>{`Crecimiento en ventas`}</label>
                                                        <Field
                                                            disabled = {productName === 'SKIM' ? true : false}
                                                            name={`${field}.annual_projection_row[${yearIndex}].growth_percentage`}
                                                            component={renderNumberPercentage}
                                                            className="form-control growth-field"
                                                            placeholder="0%"
                                                        />
                                                    </div>
                                                    {productName !== 'SKIM' && (
                                                        <div className="col-md-3 mb-2">
                                                            <label>{`Margen objetivo`}</label>
                                                            <Field
                                                                name={`${field}.annual_projection_row[${yearIndex}].percentage_target_margin`}
                                                                component={renderDollar}
                                                                className="form-control growth-field"
                                                                placeholder="0%"
                                                            />
                                                        </div>
                                                    )}
                                                    {productName !== 'LATEX' && productName !== 'SKIM' && (
                                                        <div className="col-md-3 mb-2">
                                                            <label>{`Porcentaje de precio de SKIM sobre ${productName}:`}</label>
                                                            <Field
                                                                name={`${field}.annual_projection_row[${yearIndex}].percentage_price_skim_on_solids_price`}
                                                                component={renderNumberPercentage}
                                                                className="form-control growth-field"
                                                                placeholder="0%"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};



const SalesAnnualProjections = props => {
    const { data_summary, sales_data_state } = props;
    calculateSalesData(sales_data_state);
    if (!data_summary || !data_summary.results) {
        return <div>Cargando datos...</div>;
    }

    const { results } = data_summary;
    const sales_data = results.sales_data || [];
    const years = results.years || [];

    if (sales_data.length === 0 || years.length === 0) {
        return <div>Cargando datos de ventas...</div>;
    }

    const initializeSalesData = (fields) => {
        if (fields.length === 0) {
            sales_data.forEach((data) => {
                fields.push(data);
            });
        }
    };

    return (
        <div className="sales-annual-projections-container">
            <form onSubmit={props.handleSubmit}>
                <h4>Presupuesto de anualizado de ventas</h4>
                <FieldArray
                    name="sales_data"
                    component={SalesBudgetTable}
                    years={years}
                    rerenderOnEveryChange={true}
                    props={{ initializeSalesData }}

                />

                <h4>Variables de crecimiento</h4>
                <FieldArray
                    name="sales_data"
                    component={GrowthVariablesTable}
                    years={years}
                    rerenderOnEveryChange={true}
                    sales_data_state={sales_data_state}
                />

                <div className='buttons-box mt-5'>
                    <button type="submit"
                        className="btn btn-primary m-4 btn-modal"
                    >
                        GUARDAR
                    </button>
                </div>
            </form>
        </div>
    );
};


const formName = 'SalesDiversForm';

const selector = formValueSelector(formName);

const mapStateToProps = (state) => {
    const data_summary = state.sales.data_summary || {};
    const sales_data_state = selector(state, 'sales_data');

    return {
        data_summary,
        sales_data_state,
    };
};

export default connect(mapStateToProps)(
    reduxForm({
        form: formName,
    })(SalesAnnualProjections)
);
