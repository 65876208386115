import React from 'react';
import { Field } from 'redux-form';
import { renderDollar, renderDollarDisabled, renderNumberPercentage } from '../../Utils/renderField/renderField';
import { RenderPorcentaje } from '../../Utils/renderField/renderReadField';

const handleChange = (change, dataForm) => {

    const productLatexBasePrice = (parseFloat(dataForm.product_latex_bolsa) || 0) + (parseFloat(dataForm.product_latex_differential) || 0);
    change('product_latex_base_price', productLatexBasePrice);

    const productLatexBuyPrice = productLatexBasePrice - (parseFloat(dataForm.mar_obj_latex) || 0);
    change('product_latex_buy_price', productLatexBuyPrice);


    const productSolidoBasePrice = (parseFloat(dataForm.product_solido_bolsa) || 0) + (parseFloat(dataForm.product_solido_differential) || 0);
    change('product_solido_base_price', productSolidoBasePrice);

    const productSolidoBuyPrice = productSolidoBasePrice - (parseFloat(dataForm.mar_obj_solido) || 0);
    change('product_solido_buy_price', productSolidoBuyPrice);

    const productSkimBasePrice = (parseFloat(dataForm.product_solido_base_price) || 0) * ((parseFloat(dataForm.skim_price_percentage_on_solid) || 0) / 100);
    change('product_skim_base_price', productSkimBasePrice);

};

const TablePurchasePriceObjetive = props => {
    const { change, dataForm, tab } = props;
    if (tab === 'PRECIOS') {
        handleChange(change, dataForm);
    }

    return (
        <div className='container'>
            <div className='table-responsive'>
                <table className="table table-striped">
                    <thead className='head-table'>
                        <tr>
                            <th>PRODUCTO</th>
                            <th>BOLSA</th>
                            <th>DIFERENCIAL</th>
                            <th>PRECIO BASE</th>
                            <th>PRECIO COMPRA</th>
                        </tr>
                    </thead>
                    <tbody className='body-bable'>
                        <tr className="table-bordered border-secundary">
                            <td>LATEX</td>
                            <td>
                                <Field
                                    name={'product_latex_bolsa'}
                                    type="number"
                                    placeholder='$0.00'
                                    component={renderDollar}
                                />
                            </td>
                            <td>
                                <Field
                                    name={'product_latex_differential'}
                                    type="text"
                                    placeholder='$0.00'
                                    component={renderDollarDisabled}
                                />
                            </td>
                            <td>
                                <Field
                                    name={'product_latex_base_price'}
                                    type="text"
                                    placeholder='$0.00'
                                    component={renderDollarDisabled}
                                />
                            </td>
                            <td>
                                <Field
                                    name={'product_latex_buy_price'}
                                    type="text"
                                    placeholder='$0.00'
                                    component={renderDollarDisabled}
                                />
                            </td>
                        </tr>
                        <tr className="table-bordered border-secundary">
                            <td>SOLIDO</td>
                            <td>
                                <Field
                                    name={'product_solido_bolsa'}
                                    type="text"
                                    placeholder='$0.00'
                                    component={renderDollar}
                                />
                            </td>
                            <td>
                                <Field
                                    name={'product_solido_differential'}
                                    type="text"
                                    placeholder='$0.00'
                                    component={renderDollarDisabled}
                                />
                            </td>
                            <td>
                                <Field
                                    name={'product_solido_base_price'}
                                    type="text"
                                    placeholder='$0.00'
                                    component={renderDollarDisabled}
                                />
                            </td>
                            <td>
                                <Field
                                    name={'product_solido_buy_price'}
                                    type="text"
                                    placeholder='$0.00'
                                    component={renderDollarDisabled}
                                />
                            </td>
                        </tr>
                        <tr className="table-bordered border-secundary">
                            <td>SKIM</td>
                            <td>
                            </td>
                            <td>

                            </td>
                            <td>
                                <Field
                                    name={'product_skim_base_price'}
                                    type="text"
                                    placeholder='$0.00'
                                    component={renderDollarDisabled}
                                />
                            </td>
                            <td>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const PrecioVenta = props => {

    return (
        <div>
            <h2 className='titulo_parrafos'>Precio de venta, compra, márgenes y variables clave de costo</h2>
            <div className='fn-info'>
                <div className='row  col-md-12 p-0'>
                    <div className='col-md-4 p-0'>
                        <div className='col-md-12 col-sm-12'>
                            <span className='finca-entre-rios'>MARGEN OBJETIVO LATEX</span>
                        </div>
                        <div className='col-md-12  form-group'>
                            <Field name="mar_obj_latex" component={renderDollar} type="number" className="form-control" placeholder='$0.00' />
                        </div>
                    </div>
                    <div className='col-md-4 p-0'>
                        <div className='col-md-12 col-sm-12'>
                            <span className='finca-entre-rios'>MARGEN OBJETIVO SOLIDO</span>
                        </div>
                        <div className='col-md-12  form-group'>
                            <Field name="mar_obj_solido" component={renderDollar} type="number" className="form-control" placeholder='$0.00' />
                        </div>
                    </div>
                    <div className='col-md- p-0'>
                        <div className='col-md-12 col-sm-12'>
                            <span className='finca-entre-rios'>Porcentaje de precio de SKIM sobre SOLIDOS:</span>
                        </div>
                        <div className='col-md-12  form-group'>
                            <Field name="skim_price_percentage_on_solid" component={renderNumberPercentage} className="form-control" placeholder='$0.00' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='fn-info'>
                <div className='row col-md-12 p-0'>
                    <TablePurchasePriceObjetive {...props} />
                </div>
            </div>
        </div>
    );
}
export default PrecioVenta;