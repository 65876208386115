import {handleActions} from 'redux-actions';
import {createReducer} from "../baseReducer/baseReducer";
import { api } from 'api'; // Asegúrate de tener tu configuración de API
import { NotificationManager } from 'react-notifications';
import { initialize as initializeForm } from 'redux-form';


// ------------------------------------
// Constants
// ------------------------------------
const constants = {
    LOADER: `LOADER`,
    DATA: `DATA`,
};
const setLoader = loader => ({
    type: constants.LOADER,
    loader,
});

const setData = data => ({
    type: constants.DATA,
    data,
});

const readEdit = (id) => (dispatch) => {
    dispatch(setLoader(true));
    const params = {}
    params.id = id
    api.get(`customer/get_customer_edit`, params)
        .then((response) => {
            dispatch(initializeForm('EditarCustomerForm', response));
            dispatch(setData(response));
        })
        .catch((error) => {
            NotificationManager.error((error && error.detail) ? error.detail : 'Error al obtener datos de edición', 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};


export const { reducers, initialState, actions } = createReducer(
    "customers",
    "customer",
    "EditarCustomerForm",
    "/customers",
);

actions.readEdit = readEdit;

export default handleActions(reducers, initialState);
