import {handleActions} from 'redux-actions';
import {createReducer} from "../baseReducer/baseReducer";


// ------------------------------------
// Constants
// ------------------------------------

export const { reducers, initialState, actions } = createReducer(
    "ports",
    "port",
    "EditarPortForm",
    "/ports",
);

export default handleActions(reducers, initialState);
