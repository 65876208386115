import React, { useState, useEffect } from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import Grid from '../../../../Utils/Grid';
import { columnaClaseFija } from '../../../../../../utility/staticColumns';

const Listado = (props) => {
    const { data, loader, onSortChange, openModal, readEdit } = props;

    const [columns, setColumns] = useState([]);

    useEffect(() => {
        if (data && data.results && data.results.results) {
            const dynamicColumns = generateColumns(data.results.results[0]);
            setColumns(dynamicColumns);
        }
    }, [data]);

    const generateColumns = (sampleData) => {
        const dynamicColumns = [];
        for (const key in sampleData) {
            if (sampleData.hasOwnProperty(key) && key !== 'name') {
                dynamicColumns.push({
                    dataField: key,
                    text: key.toUpperCase(),
                    formatter: (cell, row) => formatCurrency(cell, row),
                });
            }
        }
        return dynamicColumns;
    };

    const formatCurrency = (value, row) => {
        const currencySymbol = (row.name === 'Total en $') ? '$' : 'Q';
        const formattedValue = parseFloat(value || 0).toFixed(2);
        return `${currencySymbol} ${formattedValue}`;
    };

    const columnaClaseTotal = (cell, row) => {
        if (row.name === 'Total' || row.name === 'Total en $') {
            return 'celda-totales';
        }
        return '';
    };

    const handleAgregar = () => {
        readEdit();
        openModal();
    };

    const customHeaderText = (text) => {
        const words = text.split('_');
        const lastWord = words.pop();
        return (
            <div>
                {words.join(' ')}
                <br />
                {lastWord}
            </div>
        );
    };

    return (
        <div>
            <div>
                <Grid
                    hover
                    striped
                    data={data.results}
                    loading={loader}
                    onSortChange={onSortChange}
                    pagination={false}
                    className="pb-3"
                >
                    <TableHeaderColumn
                        dataField="name"
                        dataSort
                        isKey
                        className="table-fixed-cell-left-0"
                        columnClassName={columnaClaseFija}
                        width='170'
                        tdStyle={{ whiteSpace: 'normal' }}
                    >
                        RUBRO
                    </TableHeaderColumn>
                    {columns.map((col) => (
                        <TableHeaderColumn
                            key={col.dataField}
                            dataField={col.dataField}
                            dataSort={col.sort}
                            dataFormat={col.formatter}
                            columnClassName={columnaClaseTotal}
                            width='125'
                        >
                            {customHeaderText(col.text)}
                        </TableHeaderColumn>
                    ))}
                </Grid>
            </div>

            <div className="mt-2">
                <div
                    type="button"
                    className="btn btn-light btn-lg btn-block d-flex justify-content-end agregar-tab-generica"
                    onClick={handleAgregar}
                >
                    CONFIGURAR
                </div>
            </div>
            <br />
        </div>
    );
};

export default Listado;
