const validate = (values,props) => {
    const errors = {};
    const message = 'Campo requerido';
    const messageInvalid = 'Valor no válido';
    const messageMax100 = 'No debe ser mayor a 100%';
    const messageNoNegatives = 'No debe ser negativo';
    const messageIvaNotEqualTo100 = 'La suma de IVA sujeto a retención y no retenido debe ser 100%';

    // Validaciones para el tab "PRODUCCION_FINCA"
    if (props.tab === 'PRODUCCION_FINCA') {
        if (!values.dollar_exchange) {
            errors.dollar_exchange = message;
        }
    }

    if (props.tab === 'PRECIOS') {
        if (!values.mar_obj_latex) {
            errors.mar_obj_latex = message;
        }
        if (!values.mar_obj_solido) {
            errors.mar_obj_solido = message;
        }
        if (!values.skim_price_percentage_on_solid) {
            errors.skim_price_percentage_on_solid = message;
        }
    }

    if (props.tab === 'VARIABLES') {
        if (!values.var_yield) {
            errors.var_yield = message;
        }
        if (!values.var_skim) {
            errors.var_skim = message;
        }
        if (!values.var_lost) {
            errors.var_lost = message;
        }
        if (!values.var_clot_wash) {
            errors.var_clot_wash = message;
        }
        if (!values.var_clot_traps) {
            errors.var_clot_traps = message;
        }
        if (!values.var_clot_centrifuged) {
            errors.var_clot_centrifuged = message;
        }
        if (!values.latex_makeup_price) {
            errors.latex_makeup_price = message;
        }
        if (!values.solid_makeup_price) {
            errors.solid_makeup_price = message;
        }
    }

    if (props.tab === 'CREDITO_FISCAL') {
        const taxCredit = values.parameter_tax_credit || {};
        const taxRequiredFields = [
            'special_invoice', 'normal_invoice', 'does_not_generate_retencion', 'if_it_generates_retention',
            'iva_subject_to_withholding', 'iva_not_withheld', 'exported_decree_29_89', 'special_taxpayers',
            'export_op_retention', 'local_op_retention'
        ];

        taxRequiredFields.forEach(field => {
            if (!taxCredit[field]) {
                errors.parameter_tax_credit = errors.parameter_tax_credit || {};
                errors.parameter_tax_credit[field] = message;
            }
        });

        const validatePercentageField = (fieldName, fieldValue) => {
            if (fieldValue < 0) {
                errors.parameter_tax_credit = errors.parameter_tax_credit || {};
                errors.parameter_tax_credit[fieldName] = messageNoNegatives;
            } else if (fieldValue > 100) {
                errors.parameter_tax_credit = errors.parameter_tax_credit || {};
                errors.parameter_tax_credit[fieldName] = messageMax100;
            }
        };

        taxRequiredFields.forEach(field => {
            if (taxCredit[field] !== undefined) {
                validatePercentageField(field, taxCredit[field]);
            }
        });

        const totalIva = (taxCredit.iva_subject_to_withholding || 0) + (taxCredit.iva_not_withheld || 0);
        if (totalIva !== 100) {
            errors.parameter_tax_credit = errors.parameter_tax_credit || {};
            errors.parameter_tax_credit.iva_subject_to_withholding = messageIvaNotEqualTo100;
            errors.parameter_tax_credit.iva_not_withheld = messageIvaNotEqualTo100;
        }
    }

    return errors;
};

export default validate;