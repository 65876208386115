import React, { Fragment} from 'react';


function CardEmpty(props) {
    return (
        <Fragment>
            <div className={`${props.noShadow ? 'card-empty' : 'card-tabla' }`}>
                <div className={`card-content ${props.noPadding ? '' : 'p-0 px-3 pt-3'}`}>
                    {props.children}
                    
                </div>
            </div>
            {props.isMarginButtom ? <br />: null}
        </Fragment>

    )
}

export default CardEmpty;
