import { connect } from 'react-redux';
import { actions } from '../../../../../../redux/modules/reporting/comparativo_flujo_caja/comparativo_flujo_caja';
import { getMe } from "../../../../../../redux/modules/cuenta/login";
import ComparativoFlujoCajaReport from './ComparativoFlujoCajaReport';

const mstp = state => {
    return { ...state.comparativo_flujo_caja }
};

const mdtp = {
    ...actions, getMe
};

export default connect(mstp, mdtp)(ComparativoFlujoCajaReport)
