import React, { Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators, combine } from 'validate-redux-form';
import { renderField } from 'Utils/renderField';
import CardEmpty from 'Utils/Cards/CardEmpty';
import Alerta from 'Utils/Alerta/Alerta';
import { Link } from "react-router-dom";

const ChangeForm = (props) => {
    const { handleSubmit } = props;
    return (
        <div className="reset-password__card-container">
            <CardEmpty noShadow  >
                <form name="loginForm" className="form-validate d-flex flex-column flex-wrap flex-sm-row
                 align-items-center mx-3 my-4 justify-content-center" onSubmit={handleSubmit}>
                    <img src={require('assets/img/logo.png')} alt="" className="logo-changepass m-3" />
                    <div className="d-flex flex-column">
                        <h2 className="mb-5 text-center">RECUPERAR CONTRASEÑA</h2>
                        <div className="d-flex flex-column align-items-center">
                            <Alerta texto="Ingresa E-mail registrado en el sistema." />
                            <div className="form-group has-feedback col-8 reset-password__form">
                                <label htmlFor="password">EMAIL</label>
                                <Field name="email" component={renderField} type="email" className="form-control" />
                            </div>

                        </div>

                        <div className="d-flex flex-wrap justify-content-center mb-5 mt-3">
                            <Link className="btn btn-outline-dark m-2" to="/login">CANCELAR</Link>
                            <button type="submit" className="btn btn-primary m-2">RECUPERAR</button>
                        </div>
                    </div>
                </form>
            </CardEmpty>
        </div>
    );
};

export default reduxForm({
    form: 'sendPass',
    validate: (data) => {
        return validate(data, {
            email: validators.exists()('Este campo es requerido'),
        });
    },
})(ChangeForm);
