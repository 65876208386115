import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
import { initialize as initializeForm } from 'redux-form';
import { api } from "api";
import { months } from '../../../common/components/Utils/months';

let url = 'sales'
// ------------------------------------
// Constants
// ------------------------------------
const LOADER = 'LOADER_SALES';
const DATA = 'DATA_SALES';
const DOLLAR = 'DOLLAR'
const PRODUCTION_EXPORTABLE_LATEX = 'PRODUCTION_EXPORTABLE_LATEX';
const PRODUCTION_EXPORTABLE_SOLIDOS = 'PRODUCTION_EXPORTABLE_SOLIDOS';
const SALES_TAB = 'SALES_TAB';
const SET_DATA_SUMMARY = 'SET_DATA_SUMMARY';
const SET_DATA_ANNUAL = 'SET_DATA_ANNUAL'


// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setDataResumen = data_summary => ({
    type: SET_DATA_SUMMARY,
    data_summary,
});

const setDataAnnual = data_annual => ({
    type: SET_DATA_ANNUAL,
    data_annual,
});

const setDollar = dollar => ({
    type: DOLLAR,
    dollar,
});


// Function for SALES

function processDataForProductionExTable(response) {

    const { kg_proyectados, perdida_por_centrifugado, skim, produces_latex } = response

    const kg_proyectados_data = kg_proyectados.kg_proyectados.generic_row[0]

    const meses = months


    if (produces_latex) {
        var perdida = perdida_por_centrifugado / 100
        var skim_data = skim / 100

        const calculate = (data, factor) => {
            return meses.reduce((obj, mes) => {
                obj[mes] = data[mes] * factor;
                return obj;
            }, {});
        }

        const calculatedLatex = calculate(kg_proyectados_data, 0.9 * perdida);
        const calculatedSkim = calculate(kg_proyectados_data, 0.9 * skim_data);
        const calculatedSolido = calculate(kg_proyectados_data, 0.1);

        const totalLatexandSkim = meses.reduce((obj, mes) => {
            obj[mes] = (calculatedLatex[mes] || 0) + (calculatedSkim[mes] || 0);
            return obj;
        }, {});

        const totalCalculated = meses.reduce((obj, mes) => {
            obj[mes] = (totalLatexandSkim[mes] || 0) + (calculatedSolido[mes] || 0);
            return obj;
        }, {});


        const production_exportable = [
            { name: 'Haciendo latex' },
            { name: 'Latex', data: totalLatexandSkim },
            { name: 'Chipa', data: calculatedSolido },
            { name: 'TOTALES', data: totalCalculated }
        ];

        production_exportable.forEach(item => {
            if (item.name === 'Haciendo latex') {
                return;
            }
            item.total = 0;
            for (let mes in item.data) {
                item.total += item.data[mes];
            }
            Object.assign(item, item.data);
        });
        return production_exportable
    } else {

        const chipaData = meses.reduce((obj, mes) => {
            if (kg_proyectados_data[mes]) {
                if (obj[mes]) {
                    obj[mes] += kg_proyectados_data[mes];
                } else {
                    obj[mes] = kg_proyectados_data[mes];
                }
            }
            return obj;
        }, {});


        const production_exportable = [
            { name: 'Haciendo solidos' },
            { name: 'Chipa', data: chipaData },
            { name: 'Total', data: chipaData }
        ];
        production_exportable.forEach(item => {
            if (item.name === 'Haciendo solidos') {
                return;
            }
            let total = 0;
            if (item.data) {
                for (let mes in item.data) {
                    total += item.data[mes];
                }
            }
            Object.assign(item, item.data);
            item.total = total;
        });
        return production_exportable
    }

}

// -----------------------------------
// Actions
// -----------------------------------


const listar = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${url}/get_all`).then((response) => {
        // data para produccion exportable
        const production_exportable = processDataForProductionExTable(response);
        dispatch(setData({
            response,
            production_exportable,
        }));
        dispatch(initializeForm('SalesDiversForm', response.sales_divers))
        dispatch(setDollar(response.dollar_exchange))

    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listar_resumen = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.get(`${url}/get_all_summary`).then((response) => {
        const production_exportable = processDataForProductionExTable(response);
        dispatch(setDataResumen({
            response,
            production_exportable
        }));
        dispatch(setDollar(response.dollar_exchange))

    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const summary_annual_list = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${url}/get_annual_sales_budget`).then((response) => {
        dispatch(setDataResumen(response));
        dispatch(initializeForm('SalesDiversForm', response.results));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const read = id => (dispatch) => {
    dispatch(setLoader(true));
    const params = {}
    params.id = id
    api.get(`${url}/get_sub_production_sales`, params).then((response) => {
        dispatch(setData(response.results));
        dispatch(initializeForm('SalesForm', response.results));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const sub_listar = id => (dispatch) => {
    dispatch(setLoader(true));
    const params = {}
    params.id = id
    api.get(`${url}/get_all_sub_production_sales`, params).then((response) => {
        dispatch(setData(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${url}/${id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push('/sales/configuration'));
    }).catch(() => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error en la edición', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar_divers = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${url}/${id}/sales_divers_update`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push('/sales/configuration'));
    }).catch(() => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error en la edición', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const edit_annual = (data) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}/sales_annual_update`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push('/sales/summary'));
    }).catch(() => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error en la edición', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

//  ----------------
// Administrativo Resumen
// -----------------

const setTab = (tab) => (dispatch) => {
    dispatch({ type: SALES_TAB, tab });
    dispatch(setLoader(true));
    if (tab === "EN_CURSO") {
        dispatch(listar_resumen())

    } else {
        dispatch(summary_annual_list())
    }
    dispatch(setLoader(false));
};

export const actions = {
    listar,
    editar,
    read,
    setTab,
    sub_listar,
    editar_divers,
    listar_resumen,
    summary_annual_list,
    edit_annual

};
// -----------------------------------
// Reducers
// -----------------------------------
const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DOLLAR]: (state, { dollar }) => {
        return {
            ...state,
            dollar,
        };
    },
    [PRODUCTION_EXPORTABLE_LATEX]: (state, { production_exportable_latex }) => {
        return {
            ...state,
            production_exportable_latex,
        };
    },
    [SALES_TAB]: (state, { tab }) => {
        return {
            ...state,
            tab,
        };
    },
    [SET_DATA_SUMMARY]: (state, { data_summary }) => {
        return {
            ...state,
            data_summary,
            dataResumenCargada: true
        };
    },
    [SET_DATA_ANNUAL]: (state, { data_annual }) => {
        return {
            ...state,
            data_annual,
            AnnualDataLoaded: true,
        };
    },
    [PRODUCTION_EXPORTABLE_SOLIDOS]: (state, { production_exportable_solido }) => {
        return {
            ...state,
            production_exportable_solido,
        };
    },

};
export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    data_summary: {
        results: [],
        count: 0,
    },
    tab: 'EN_CURSO',
    produce_finca: true,
    production_exportable_latex: {},
    production_exportable_solido: {},
    dollar: 0
};
export default handleActions(reducers, initialState)
