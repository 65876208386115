const validate = values => {
    const errors = {};
    const message = 'Campo requerido';
    const TYPE_OF_DEBT = {
        LINE_OF_CREDIT: 10,
        PROMISSORY_NOTE: 20,
        BANK_CREDIT: 30,
    };

    // Validación del nombre de deuda
    if (!values.name) {
        errors.name = message;
    }

    // Validación de tipo de deuda
    if (!values.type_of_debt) {
        errors.type_of_debt = message;
    }

    // Validaciones relacionadas con debt_management_parameters
    if (values.type_of_debt !== TYPE_OF_DEBT.LINE_OF_CREDIT) {
        // Inicializar errors.debt_management_parameters si no existe
        if (!errors.debt_management_parameters) {
            errors.debt_management_parameters = {};
        }

        // Validar start_date y end_date si debt_management_parameters existe
        if (!values.debt_management_parameters || !values.debt_management_parameters.start_date) {
            errors.debt_management_parameters.start_date = message;
        }
        if (!values.debt_management_parameters || !values.debt_management_parameters.end_date) {
            errors.debt_management_parameters.end_date = message;
        }
    }

    // Validación de interest_rate
    if (!values.debt_management_parameters || !values.debt_management_parameters.interest_rate) {
        if (!errors.debt_management_parameters) {
            errors.debt_management_parameters = {};
        }
        errors.debt_management_parameters.interest_rate = message;
    }

    // Validaciones adicionales para debt_management_parameters si existe
    if (values.debt_management_parameters) {
        const debtManagementErrors = {};

        // Validaciones comunes a todos los tipos de deuda
        if (!values.debt_management_parameters.interest_rate) {
            debtManagementErrors.interest_rate = message;
        }

        if (!values.debt_management_parameters.days_of_term) {
            debtManagementErrors.days_of_term = message;
        }

        if (!values.debt_management_parameters.authorized_quota) {
            debtManagementErrors.authorized_quota = message;
        }

        // Si encontramos errores en debt_management_parameters, los asignamos
        if (Object.keys(debtManagementErrors).length > 0) {
            errors.debt_management_parameters = {
                ...errors.debt_management_parameters,
                ...debtManagementErrors,
            };
        }
    }

    // Validaciones para category_budget si existe
    if (typeof values.category_budget !== 'undefined') {
        const categoryBudgetArrayErrors = [];
        values.category_budget.forEach((category, categoryIndex) => {
            const categoryErrors = {};

            if (category && category.category_table && category.category_table.length > 0) {
                const categoryTableArrayErrors = [];
                category.category_table.forEach((table, tableIndex) => {
                    const tableErrors = {};

                    if (table && table.generic_row && table.generic_row.length > 0) {
                        const genericRowArrayErrors = [];
                        table.generic_row.forEach((row, rowIndex) => {
                            const rowErrors = {};
                            if (!row || !row.name) {
                                rowErrors.name = "Campo requerido";
                                genericRowArrayErrors[rowIndex] = rowErrors;
                            }
                        });
                        if (genericRowArrayErrors.length) {
                            tableErrors.generic_row = genericRowArrayErrors;
                        }
                    }

                    if (Object.keys(tableErrors).length) {
                        categoryTableArrayErrors[tableIndex] = tableErrors;
                    }
                });
                if (categoryTableArrayErrors.length) {
                    categoryErrors.category_table = categoryTableArrayErrors;
                }
            }

            if (Object.keys(categoryErrors).length) {
                categoryBudgetArrayErrors[categoryIndex] = categoryErrors;
            }
        });
        if (categoryBudgetArrayErrors.length) {
            errors.category_budget = categoryBudgetArrayErrors;
        }
    }

    return errors;
};

export default validate;
