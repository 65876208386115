import { connect } from 'react-redux';
import { actions } from '../../../../../../redux/modules/sales/sales';
import SalesShow from "./SalesShow";


const ms2p = (state) => {
  return {
    ...state.sales,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(SalesShow);
