import React, { useEffect, useState } from 'react';
import { Field, FieldArray } from 'redux-form';
import { renderFieldDisabled, AsyncSelectField, SelectFieldAllFields, renderNumberSinFormatoDisabled, renderDollarDisabled } from '../../../../Utils/renderField/renderField';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { api } from "api";
import { months } from '../../../../Utils/months';


const TableRow = ({ system, index, fields, change }) => {
    const isNew = fields.get(index).isNew;


    const fetchData = (endpoint, params) => {
        return api.get(endpoint, params)
            .then((data) => {
                if (data) return data.results;
                return [];
            })
            .catch(() => {
                return [];
            });
    };

    const getCustomers = (search) => {
        return fetchData("customer", { search });
    };

    const getPort = (search) => {
        return fetchData("port", { search });
    };

    const getTransport = (search) => {
        return fetchData("transport", { search });
    };

    const [packingOptions, setPackingOptions] = useState([]);

    const filtroPacking = (id) => {
        api.get("packing/get_packing", { id })
            .then((data) => {
                if (data) {
                    const options = data.map(packing => ({
                        id: packing.id,
                        name: packing.name,
                        storage_capacity: packing.storage_capacity
                    }));
                    setPackingOptions(options);
                }
            })
            .catch((error) => {
                console.error("Error fetching packings:", error);
            });
    };

    // Recetea el estado de packing para que tenga el valor
    //  en 0 y asi no se sumen si no se tiene seleccionado ninguno
    const [firstRender, setFirstRender] = useState(true);

    useEffect(() => {
        if (!firstRender) {
            change(`${system}.packing`, null);
        } else {
            setFirstRender(false);
        }
    }, [packingOptions]);


    const calculateTotalForRow = () => {
        const systemData = fields.get(index);
        if (!systemData) return 0;

        const totalForRow = months.reduce((accumulatedTotal, month) => {
            const value = parseFloat(systemData[month]) || 0;
            accumulatedTotal += value;
            return accumulatedTotal;
        }, 0);

        return totalForRow;
    };

    return (
        <React.Fragment key={index}>
            <tr key={`system-${index}`}>
                <td className="sin-borde-top" style={{ width: "8%" }}>
                    <Field
                        name={isNew ? `${system}.customer` : `${system}.customer.company`}
                        component={isNew ? AsyncSelectField : renderFieldDisabled}
                        loadOptions={getCustomers}
                        valueKey="id"
                        labelKey="company"
                        className="form-control"
                    />
                </td>
                <td className="sin-borde-top" style={{ width: "8%" }}>
                    <Field
                        name={isNew ? `${system}.depure_port` : `${system}.depure_port.name`}
                        component={isNew ? AsyncSelectField : renderFieldDisabled}
                        loadOptions={getPort}
                        valueKey="id"
                        labelKey="name"
                        className="form-control"
                    />
                </td>
                <td className="sin-borde-top" style={{ width: "8%" }}>
                    <Field
                        name={isNew ? `${system}.type_of_transport` : `${system}.type_of_transport.name`}
                        component={isNew ? AsyncSelectField : renderFieldDisabled}
                        loadOptions={getTransport}
                        valueKey="id"
                        labelKey="name"
                        className="form-control"
                        onChange={e => filtroPacking(e ? e.id : -1)}
                    />
                </td>
                <td className="sin-borde-top" style={{ width: "8%" }}>
                    <Field
                        name={isNew ? `${system}.packing` : `${system}.packing.name`}
                        component={isNew ? SelectFieldAllFields : renderFieldDisabled}
                        options={packingOptions}
                        valueKey="id"
                        labelKey="name"
                        className="form-control"
                    />
                </td>
                <td className="text-center sin-borde-top" style={{ width: "4.66%" }}>
                    <Field
                        name={`${system}.differential`}
                        component={renderDollarDisabled}
                        className="form-control"
                        placeholder="0.0"
                    />
                </td>
                {months.map((month) => (
                    <td key={month} style={{ width: "4.66%" }}>
                        <Field
                            name={`${system}.${month}`}
                            type="text"
                            component={renderNumberSinFormatoDisabled}
                            className="form-control"
                            placeholder="0.0"
                        />
                    </td>
                ))}
                <td className="custom-cell text-center" >
                    {new Intl.NumberFormat('es-GT', { style: 'decimal' }).format(calculateTotalForRow())}
                </td>
            </tr>
        </React.Fragment>
    );
};

const GenericTable = ({ fields, change }) => {
    const months = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];

    const calculateTotalForMonth = (month) => {
        const allFields = fields.getAll() || [];
        const total = allFields.reduce((accumulatedTotal, system) => {

            const value = parseFloat(system[month]) || 0;
            accumulatedTotal += value
            return accumulatedTotal;
        }, 0);

        return total;
    };

    return (
        <div className=" col-sm-12 form-group np-r p-0">
            <div className='scroll-container'>
                <div className='scroll-content-sales'>
                    <div className="react-bs-container-header table-header-wrapper">
                        <table className="table mb-0 text-center">
                            <thead>
                                <tr>
                                    <th style={{ width: "8%" }}>CLIENTE</th>
                                    <th style={{ width: "8%" }}>PUERTO DE SALIDA</th>
                                    <th style={{ width: "8%" }}>TRANSPORTE</th>
                                    <th style={{ width: "8%" }}>EMPAQUE</th>
                                    <th style={{ width: "4.66%" }}>DIFERENCIAL</th>
                                    <th style={{ width: "4.66%" }}>1</th>
                                    <th style={{ width: "4.66%" }}>2</th>
                                    <th style={{ width: "4.66%" }}>3</th>
                                    <th style={{ width: "4.66%" }}>4</th>
                                    <th style={{ width: "4.66%" }}>5</th>
                                    <th style={{ width: "4.66%" }}>6</th>
                                    <th style={{ width: "4.66%" }}>7</th>
                                    <th style={{ width: "4.66%" }}>8</th>
                                    <th style={{ width: "4.66%" }}>9</th>
                                    <th style={{ width: "4.66%" }}>10</th>
                                    <th style={{ width: "4.66%" }}>11</th>
                                    <th style={{ width: "4.66%" }}>12</th>
                                    <th style={{ width: "4.66%" }}>TOTAL</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="tabla-con-borde">
                        <table className="table table-sm  m-0 table-striped">
                            <tbody style={{ backgroundColor: "#F0FFC7" }}>
                                {fields.map((system, index) => <TableRow key={index} system={system} index={index} fields={fields} change={change} />)}
                                <tr style={{ backgroundColor: "#F0FFC7" }}>
                                    <td className="custom-cell text-center"></td>
                                    <td className="custom-cell text-center"></td>
                                    <td className="custom-cell text-center"></td>
                                    <td className="custom-cell text-center"></td>
                                    <td className="custom-cell text-center"></td>
                                    {months.map((month, idx) => (
                                        <td key={idx} className="custom-cell text-center">
                                            {new Intl.NumberFormat('es-GT', { style: 'decimal' }).format(calculateTotalForMonth(month))}
                                        </td>
                                    ))}
                                    <td className="custom-cell text-center"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="mt-2">
            </div>
        </div>
    );
}

const SalesTable = ({ fieldName, allValues, change }) => {
    return (
        <div>
            <FieldArray
                name={`${fieldName}`}
                component={GenericTable}
                allValues={allValues}
                change={change}
            />
        </div>
    );
}

const formName = 'SalesTable';

const selector = formValueSelector(formName);

const mapStateToProps = (state) => {
    const row_sales = selector(state, 'generic_row_sales') || [];
    const allValues = row_sales.map(item => item);
    return { allValues };
};

export default connect(mapStateToProps)(SalesTable);