import React, { Component } from "react";
import Card from 'Utils/Cards/Card';
import Listado from "./Listado";
import CardFormNoBorder from '../../../../Utils/Cards/CardFormNoBorder';
import CardEmpty from '../../../../Utils/Cards/CardEmpty';
import ConnectedCategoriaModal from './Modal/CategoriaModal';
import KgSecos from "./KgSecos";

class SolidPlant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            initialValues: {}
        };
    }

    componentDidMount() {
        this.props.read();
    }

    openModal = () => {
        this.setState({
            open: true,
            initialValues: {}
        });
    };

    closeModal = () => {
        this.setState({
            open: false,
            initialValues: {}
        });

        this.props.initializeKGForm(this.props.data)
    };

    handleAddCategory = (data) => {
        if (data.id) {
            this.props.editar(data.id, data);
        } else {
            this.props.crear(data);
        }
        this.closeModal();
    };

    actualizar = (data) => {
        this.props.editar(data.data_kg[0].id, data);
    };

    render() {
        const { data, loader, readEdit, data_modal } = this.props;
        const { open, initialValues } = this.state;
        const { results } = data_modal;

        return (
            <div className="mb-4 col-12">
                <CardFormNoBorder
                    noShadow
                    icon="Side_activos/Admin.svg"
                    titulo="PLANTA SOLIDOS"
                    subtitulo="CONFIGURACIÓN"
                >

                </CardFormNoBorder>
                <CardEmpty noShadow isMarginButtom>
                    <div className="col-md-3 p-0 mt-2">
                        <div className="md-12">
                            <h6 className="mb-4"><strong>KILOGRAMOS SECOS</strong></h6>
                        </div>
                    </div>
                    <div className='mb-4'>
                        {!open &&
                            <KgSecos
                                onSubmit={this.actualizar}
                                {...this.props}
                            />}
                    </div>
                </CardEmpty>
                <CardEmpty noShadow>
                    <div className="row col-md-12 p-0">
                        <div className="col-md-6">
                            <h4 className="mr-2 titulo_principal">PLANTAS SOLIDOS</h4>
                        </div>
                    </div>
                    <Listado data={data} loader={loader} openModal={this.openModal} readEdit={readEdit} {...this.props} />
                </CardEmpty>


                <ConnectedCategoriaModal
                    open={open}
                    dollarExchange={data.dollar_exchange}
                    closeModal={this.closeModal}
                    handleAddCategory={this.handleAddCategory}
                    initialValues={results || initialValues}
                    change={this.props.change}
                    {...this.props}
                />
            </div>
        );
    }
}

export default SolidPlant;
