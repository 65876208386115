import React, { useEffect, useState } from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import Grid from '../../../../Utils/Grid';
import { columnaClaseFija } from '../../../../../../utility/staticColumns';

const ListadoCosto = (props) => {
    const { data, loader, onSortChange, openModal, readEdit } = props;
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        if (data && data.data_cost && data.data_cost && data.data_cost.results) {
            const dynamicColumns = generateColumns(data.data_cost.results[0]);
            setColumns(dynamicColumns);
        }
    }, [data]);

    const generateColumns = (sampleData) => {
        const dynamicColumns = [];
        for (const key in sampleData) {
            if (sampleData.hasOwnProperty(key) && key !== 'name') {
                dynamicColumns.push({
                    dataField: key,
                    text: key.toUpperCase(),
                    formatter: (cell, row) => formatCurrency(cell, row),
                });
            }
        }
        return dynamicColumns;
    };

    const formatCurrency = (value, row) => {
        const currencySymbol = '$';
        const formattedValue = parseFloat(value || 0).toFixed(3);
        return `${currencySymbol} ${formattedValue}`;
    };

    const columnaClaseTotal = (cell, row) => {
        if (row.name === 'Total' || row.name === 'Total en $') {
            return 'celda-totales'; // Esta es la clase CSS que necesitarás definir
        }
        return '';
    };

    const customHeaderText = (text) => {
        const words = text.split('_');
        const lastWord = words.pop();
        return (
            <div>
                {words.join(' ')}
                <br />
                {lastWord}
            </div>
        );
    };

    return (
        <div>
            <Grid
                hover
                striped
                data={!data.data_cost || !Array.isArray(data.data_cost.results) ? [] : data.data_cost}
                loading={loader}
                onSortChange={onSortChange}
                pagination={false}
                className="pb-3"
            >
                <TableHeaderColumn
                    dataField="name"
                    dataSort
                    isKey
                    className="table-fixed-cell-left-0"
                    columnClassName={columnaClaseFija}
                    width='120'
                >
                    RUBRO
                </TableHeaderColumn>
                {columns.map((col) => (
                    <TableHeaderColumn
                        key={col.dataField}
                        dataField={col.dataField}
                        dataSort={col.sort}
                        dataFormat={col.formatter}
                        columnClassName={columnaClaseTotal}
                        width='130'
                    >
                        {customHeaderText(col.text)}
                    </TableHeaderColumn>
                ))}
            </Grid>
            <br />
        </div>
    );
};

export default ListadoCosto;
