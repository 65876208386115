import React from 'react';
import CardEmpty from '../../../../Utils/Cards/CardEmpty';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import SolidosTable from './Tables/SolidosTable/SolidosTable';
import LatexTable from './Tables/LatexTable/LatexTable';
import SkimTable from './Tables/SkimTable/SkimTable';

let CostoVentasForm = props => {
    const { handleSubmit } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className='col-sm-12 p-0'>
                <CardEmpty
                    isMarginButtom
                    noShadow>
                    <div className="col-md-3 p-0 mt-2">
                        <div className="md-12">
                            <h6 className="mb-4"><strong>SOLIDOS</strong></h6>
                        </div>
                    </div>
                    <SolidosTable
                        {...props}
                    />
                </CardEmpty>
                <CardEmpty
                    isMarginButtom
                    noShadow>
                    <div className="col-md-3 p-0 mt-2">
                        <div className="md-12">
                            <h6 className="mb-4"><strong>LATEX en proceso</strong></h6>
                        </div>
                    </div>
                    <LatexTable
                        {...props}
                    />
                </CardEmpty>
                <CardEmpty
                    isMarginButtom
                    noShadow>
                    <div className="col-md-3 p-0 mt-2">
                        <div className="md-12">
                            <h6 className="mb-4"><strong>SKIM</strong></h6>
                        </div>
                    </div>
                    <SkimTable
                        {...props}
                    />
                </CardEmpty>
            </div>
        </form>
    )
};
const formName = 'CostoVentasForm';

const selector = formValueSelector(formName);

const mapStateToProps = (state) => {
    const admin_data_state = selector(state, 'projections');
    return {
        admin_data_state,
    };
};

export default connect(mapStateToProps)(
    reduxForm({
        form: formName,
    })(CostoVentasForm)
);
