import React from 'react';
import { Field, FieldArray } from 'redux-form';
import { renderNumberPercentage } from 'Utils/renderField/renderField';
import { reduxForm } from 'redux-form';

const AdministrativeProjectionTable = ({ fields, years }) => {
    if (!years || years.length === 0) {
        return <div>No hay datos de años disponibles</div>;
    }

    return (
        <div className="table-responsive">
            <table className="table table-bordered text-center">
                <thead>
                    <tr className="table-header">
                        <th>Concepto</th>
                        <th></th>
                        {years.slice(1).map((year, index) => (
                            <th key={`year-${year}-${index + 1}`}>{year}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Inflación (%)</td>
                        {fields.map((field, index) => (
                            <td key={index}>
                                {index > 0 && (
                                    <Field
                                        name={`${field}.inflation_percentage`}
                                        component={renderNumberPercentage}
                                        placeholder="0%"
                                        className="form-control"
                                    />
                                )}
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td>Mano de obra (%)</td>
                        {fields.map((field, index) => (
                            <td key={index}>
                                {index > 0 && (
                                    <Field
                                        name={`${field}.percentage_of_labor`}
                                        component={renderNumberPercentage}
                                        placeholder="0%"
                                        className="form-control"
                                    />
                                )}
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

const FincaAnnualProjections = props => {
    const { data } = props;

    if (!data || !data.projections) {
        return <div>Cargando datos...</div>;
    }

    const projections = data.projections || [];
    const years = data.years || [];

    if (projections.length === 0 || years.length === 0) {
        return <div>Cargando datos de proyecciones...</div>;
    }

    const initializeProjectionData = (fields) => {
        if (fields.length === 0) {
            projections.forEach((data) => {
                fields.push(data);
            });
        }
    };

    return (
        <div className="administrative-annual-projections-container">
            <h5 className='fn-info-sb'>PROYECCIONES ANUALES</h5>
            <br />
            <FieldArray
                name="projections"
                component={AdministrativeProjectionTable}
                years={years}
                rerenderOnEveryChange={true}
                props={{ initializeProjectionData }}
            />
        </div>
    );
};

export default FincaAnnualProjections
