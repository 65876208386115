import React from 'react';
import { Field, reduxForm, FieldArray } from 'redux-form';
import ShoppingTableAnnual from './ShoppingTableAnnual';
import { renderField, renderFieldDisabled } from "../../../../../Utils/renderField/renderField"
import CardEmpty from '../../../../../Utils/Cards/CardEmpty';
import { connect } from 'react-redux';

const renderTable = ({ fields, dollar_exchange, change, data_annual }) => (
    <div>
        {fields.map((row, index) => (
            <div key={index} className='col-sm-12 p-0'>
                <ShoppingTableAnnual fieldName={`${row}.generic_row`}  data_annual = { data_annual } dollar_exchange={dollar_exchange} change={change} />
            </div>
        ))}
    </div>
);

const renderCategory = ({ fields, dollar_exchange, change, data_annual }) => {
    return (
        <div>
            {fields.map((table, index) => (
                <div key={index} className='col-sm-12 p-0'>
                    <div className="mt-2">
                        <Field
                            name={`${table}.name`}
                            component={renderFieldDisabled}
                            width="50%"
                            applyStyles={false}
                            isTextarea={false}
                        />
                        <Field
                            name={`${table}.description`}
                            component={renderField}
                            style={{ display: 'none' }}
                            width="50%"
                        />
                    </div>
                    <FieldArray name={`${table}.category_table`} data_annual={ data_annual } dollar_exchange={dollar_exchange} change={change} component={renderTable} />
                </div>
            ))}
        </div>
    );
}

let ShoppingForm = props => {
    const { handleSubmit, item, data_annual } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className='col-sm-12 p-0'>
                <React.Fragment>
                    <CardEmpty isMarginButtom>
                        <div className="col-md-3 p-0 mt-2">
                            <div className="md-12">
                                <h5 className="mb-4">
                                    RUBROS -PRESUPUESTO DE COMPRAS
                                </h5>
                            </div>
                        </div>
                        <FieldArray
                            name="category_budget"
                            dollar_exchange={item}
                            component={renderCategory}
                            change={props.change}
                            data_annual = {data_annual} 
                        />
                    </CardEmpty>
                </React.Fragment>
            </div>
        </form>
    )
};

const mapStateToProps = (state) => {
    const formValues = state.form.ShoppingFormAnnual || {};
    return { formValues };
};

ShoppingForm = reduxForm({
    form: 'ShoppingFormAnnual'
})(ShoppingForm);


export default connect(mapStateToProps)(ShoppingForm);