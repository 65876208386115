import React from 'react';
import CardForm from '../../../../Utils/Cards/CardForm';
import { Field, reduxForm, FieldArray } from 'redux-form';
import CardPunteado from "../../../../Utils/Cards/CardPunteado";
import CreditLineTable from "./CreditLineTable";
import BankCreditTable from "./BankCreditTable"
import SalesAdvanceTable from './SalesAdvanceTable';
import { renderFieldDisabled, renderTypeOfDebtDisabled, renderPercentageDisabled } from "../../../../Utils/renderField/renderField";
import Swal from 'sweetalert2';
const alerta = require('assets/img/icons/alerta_roja.png');
import validate from './validate';
import editar_img from 'assets/img/icons/editar.svg';
import { connect } from 'react-redux';

const renderCategory = ({ fields, openModal, readEdit, initializeEmptyForm, data, eliminar, isEdition, isCreate }) => {

    const delete_category = (index, id) => {
        let message = '¿Estás seguro de confirmar todos los cambios?';
        const SwalMod = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-primary btn-modal',
                cancelButton: 'btn btn-outline-dark mr-5 btn-modal',
                content: '',
                title: 'darck',
                popup: 'custom-modal-size',
            },
            buttonsStyling: false,
            showCloseButton: true,
            focusConfirm: false,
            focusCancel: false,
            padding: "1em",
        });

        SwalMod.fire({
            title: 'ADVERTENCIA',
            html: 'Si eliminas la categoría perderás toda la información, <br/>' + message,
            imageUrl: alerta,
            imageAlt: 'Alerta',
            showCancelButton: true,
            confirmButtonText: 'CONTINUAR',
            cancelButtonText: 'CANCELAR',
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                fields.remove(index);
                eliminar(id)

            }
        });
    }

    const abrirModalYConsultar = (id) => {
        readEdit(id);
        openModal();
        isEdition()
    };
    const abrirModaVacio = () => {
        openModal();
        initializeEmptyForm(data);
        isCreate()
    };
    const TYPE_OF_DEBIT = {
        LINE_OF_CREDIT: 10,
        PROMISSORY_NOTE: 20,
        BANK_CREDIT: 30,
        SALES_ADVANCE: 40,

    };

    const TYPE_OF_DEBT_LABELS = {
        10: 'LÍNEA DE CRÉDITO',
        20: 'PAGARÉ',
        30: 'CRÉDITO BANCARIO',
        40: 'ANTICIPO DE VENTAS'
    };
    const tableComponents = {
        [TYPE_OF_DEBIT.LINE_OF_CREDIT]: CreditLineTable,
        [TYPE_OF_DEBIT.SALES_ADVANCE]: SalesAdvanceTable,
        [TYPE_OF_DEBIT.BANK_CREDIT]: BankCreditTable,
    };



    return (
        <div>
            {fields.map((table, index) => {
                const currentTableData = fields.get(index);
                const SelectedTableComponent = tableComponents[currentTableData.type_of_debt] || BankCreditTable;
                return (
                    <div key={index} className='col-sm-12 p-0'>
                        <hr />
                        <div className="mt-2">
                            <div className="col-12 p-0 mt-2">
                                <div className="d-flex flex-wrap justify-content-between">
                                    <div className="form-group mr-3 flex-grow-1">
                                        <label htmlFor="name" className="m-0">Tipo de crédito</label>
                                        <Field
                                            name={`${table}.type_of_debt`}
                                            component={renderTypeOfDebtDisabled}
                                            width="100%"
                                            labels={TYPE_OF_DEBT_LABELS}
                                            applyStyles={true}
                                            isTextarea={false}
                                        />
                                    </div>
                                    <div className="form-group mr-3 flex-grow-1">
                                        <label htmlFor="name" className="m-0">Nombre del presupuesto</label>
                                        <Field
                                            name={`${table}.name`}
                                            component={renderFieldDisabled}
                                            width="100%"
                                            applyStyles={false}
                                            isTextarea={false}
                                        />
                                    </div>
                                    {(currentTableData.type_of_debt === TYPE_OF_DEBIT.SALES_ADVANCE) ?
                                        <div className="form-group mr-3 flex-grow-1">
                                            <label htmlFor="name" className="m-0">Taza de descuento</label>
                                            <Field
                                                name={`${table}.debt_management_parameters.interest_rate`}
                                                component={renderPercentageDisabled}
                                                width="100%"
                                                style={{ fontWeight: "bold" }}
                                                applyStyles={false}
                                                isTextarea={false}
                                            />
                                        </div>
                                        :
                                        <div className="form-group mr-3 flex-grow-1">
                                            <label htmlFor="name" className="m-0">Porcentaje de interés</label>
                                            <Field
                                                name={`${table}.debt_management_parameters.interest_rate`}
                                                component={renderPercentageDisabled}
                                                width="100%"
                                                style={{ fontWeight: "bold" }}
                                                applyStyles={false}
                                                isTextarea={false}
                                            />
                                        </div>
                                    }
                                    {(currentTableData.type_of_debt === TYPE_OF_DEBIT.LINE_OF_CREDIT

                                    ) ? (
                                        <div className="form-group flex-grow-1">
                                            <label htmlFor="name" className="m-0">Días de plazo</label>
                                            <Field
                                                name={`${table}.debt_management_parameters.days_of_term`}
                                                component={renderFieldDisabled}
                                                width="100%"
                                                applyStyles={false}
                                                isTextarea={false}
                                            />
                                        </div>
                                    ) : (currentTableData.type_of_debt === TYPE_OF_DEBIT.SALES_ADVANCE) ?
                                        <div className="form-group flex-grow-1">
                                            <label htmlFor="name" className="m-0">Días de crédito</label>
                                            <Field
                                                name={`${table}.debt_management_parameters.days_of_term`}
                                                component={renderFieldDisabled}
                                                width="100%"
                                                applyStyles={false}
                                                isTextarea={false}
                                            />
                                        </div>
                                        : (
                                            <div className="form-group flex-grow-1">
                                                <label htmlFor="name" className="m-0">Plazo</label>
                                                <Field
                                                    name={`${table}.debt_management_parameters.time_of_term`}
                                                    component={renderFieldDisabled}
                                                    width="100%"
                                                    applyStyles={false}
                                                    isTextarea={false}
                                                />
                                            </div>
                                        )}
                                    <div className="d-flex justify-content-center">
                                        <button
                                            type='button'
                                            onClick={() => abrirModalYConsultar(currentTableData.id)}  // Aquí capturas el id dinámicamente
                                            style={{ border: 'none', background: 'none' }}
                                        >
                                            <img src={editar_img} alt="Editar" className="action_img" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div type="button" className="btn btn-light btn-lg btn-block d-flex justify-content-end mb-2 eliminar-tab-generica" onClick={() => delete_category(index, currentTableData.id)}>
                                ELIMINAR
                            </div>
                        </div>
                        <SelectedTableComponent fieldName={`${table}.debt_management_budget_row`} />
                        <br/>
                        <br/>
                    </div>
                );
            })}
            <div className='mb-4'>
                <CardPunteado>
                    <div className='p-4 mb-4'>
                        <button type="button" className="btn btn-categories " onClick={(e) => { e.preventDefault(); abrirModaVacio(); }}>
                            + AGREGAR
                        </button>
                    </div>
                </CardPunteado>
            </div>
        </div>
    );
}

let DebtManagementForm = props => {
    const { handleSubmit, readEdit, item, openModal, closeModal, change, eliminar, initializeEmptyForm, isEdition, isCreate } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className='col-sm-12 p-0'>
                <CardForm
                    icon="Side_activos/deuda.svg"
                    titulo="MANEJO DE DEUDA"
                    subtitulo="CONFIGURACIÓN"
                    subtituloForm={"EDITAR"}
                    col="12"
                    noShadow
                >
                    <FieldArray
                        name="debt_management_budget_detail"
                        dollar_exchange={item}
                        openModal={openModal}
                        closeModal={closeModal}
                        readEdit={readEdit}
                        component={renderCategory}
                        change={props.change}
                        initializeEmptyForm={initializeEmptyForm}
                        eliminar={eliminar}
                        isEdition={isEdition}
                        isCreate={isCreate}
                    />
                </CardForm>
                <div className='buttons-box mt-5'>
                    <button type="submit"
                        className="btn btn-primary m-4 btn-modal"
                    >
                        CONFIRMAR
                    </button>
                </div>
            </div>
        </form>
    );
};

const mapStateToProps = (state) => ({
    initialValues: (state.form && state.form.DebtManagementForm && state.form.DebtManagementForm.values) || {},
});

DebtManagementForm = connect(mapStateToProps)(
    reduxForm({
        form: 'DebtManagementForm',
        validate,
        enableReinitialize: true,
    })(DebtManagementForm)
);

export default DebtManagementForm;
