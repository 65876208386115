import React from 'react';
import { Field, FieldArray } from 'redux-form';
import { renderFieldDisabled, renderDollarDisabled, renderQuetzalesDisabled } from '../../../../../Utils/renderField/renderField';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

const applyGrowthToProjections = (categories, projections) => {
    return categories.map(category => ({
        ...category,
        category_table: category.category_table.map(table => ({
            ...table,
            generic_row: table.generic_row.map(genericRow => {
                projections.forEach((projection, index) => {
                    const currentYear = projection.year.toString();
                    const isInitialYear = index === 0;

                    if (genericRow[currentYear] !== undefined) {
                        if (!isInitialYear) {
                            const previousYear = projections[index - 1].year.toString();
                            const growthMultiplier = 1 + (projection.growth_percentage / 100);
                            let growthMultiplierLabor = 1; 
                            let growthMultiplierInflation = 1;
                            if (genericRow.is_mano_de_obra === true) {
                                growthMultiplierLabor = 1 + (projection.percentage_of_labor / 100);
                            }else {
                                growthMultiplierInflation = 1 + (projection.inflation_percentage / 100)
                            }
                            genericRow[currentYear] = genericRow[previousYear] * growthMultiplier * growthMultiplierLabor * growthMultiplierInflation;
                        }
                    }
                });
                return genericRow;
            })
        }))
    }));
};

const TableRow = ({ system, index, fields, data_annual }) => {
    const { year_list } = data_annual;
    if (!year_list || year_list.length === 0) {
        return <div>No hay datos disponibles para mostrar.</div>;
    }

    const years = year_list

    return (
        <React.Fragment key={index}>
            <tr key={`system-${index}`}>
                <td className="text-center sin-borde-top" style={{ width: "10%" }}>
                    <Field
                        name={`${system}.name`}
                        type="text"
                        component={renderFieldDisabled}
                        isTextarea={false}
                        className="form-control"
                        placeholder='Nuevo'
                    />
                </td>
                {years.map((year) => (
                    <td key={year} style={{ width: "7.5%" }}>
                        <Field
                            name={`${system}.${year}`}
                            type="text"
                            component={fields.get(index).is_dollar ? renderDollarDisabled : renderQuetzalesDisabled}
                            className="form-control"
                            placeholder={fields.get(index).is_dollar ? '$ 0.00' : 'Q 0.00'}
                        />
                    </td>
                ))}
            </tr>
        </React.Fragment>
    );
};

const GenericTable = ({ fields, dollar_exchange, data_annual }) => {
    const { year_list } = data_annual;
    if (!year_list || year_list.length === 0) {
        return <div>No hay datos disponibles para mostrar.</div>;
    }
    const years = year_list

    const calculateTotalForYear = (year, isDollar) => {
        const allFields = fields.getAll() || [];
        let total = allFields.reduce((total, system) => {
            let value = parseFloat(system[year]) || 0;

            if (system.is_dollar) {
                value *= dollar_exchange;
            }

            return total + value;
        }, 0);
        if (isDollar && dollar_exchange) {
            total /= dollar_exchange;
        }
        return total;
    };

    return (
        <div className=" col-sm-12 form-group np-r p-0">
            <div className='scroll-container'>
                <div className='scroll-content-annual'>
                    <div className="react-bs-container-header table-header-wrapper">
                        <table className="table mb-0 text-center">
                            <thead>
                                <tr>
                                    <th style={{ width: "10%" }}>CONCEPTO</th>
                                    {year_list.map((year, index) => (
                                        <th key={year} style={{ width: "7.5%" }}>{year}</th>
                                    )
                                    )}
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="tabla-con-borde">
                        <table className="table table-sm  m-0 table-striped">
                            <tbody style={{ backgroundColor: "#F0FFC7" }}>
                                {fields.map((system, index) => <TableRow
                                    key={index}
                                    system={system}
                                    index={index}
                                    fields={fields}
                                    dollar_exchange={dollar_exchange}
                                    data_annual={data_annual}
                                />)}

                                <tr style={{ backgroundColor: "#F0FFC7", borderBottom: '1px solid #035B2F', borderTop: '1px solid #035B2F' }}>
                                    <td className="custom-cell text-center">TOTALES Q</td>
                                    {years.map((year, idx) => (
                                        <td key={idx} className="custom-cell text-center">
                                            {new Intl.NumberFormat('es-GT', { style: 'currency', currency: 'GTQ' }).format(calculateTotalForYear(year))}
                                        </td>
                                    ))}
                                </tr>
                                <tr style={{ backgroundColor: "#F0FFC7" }}>
                                    <td className="custom-cell text-center">TOTALES $</td>
                                    {years.map((year, idx) => (
                                        <td key={idx} className="custom-cell text-center">
                                            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(calculateTotalForYear(year, true))}
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

const ShoppingTableAnnual = ({ fieldName, dollar_exchange, allValues, data_annual, projections }) => {

    applyGrowthToProjections(allValues, projections);
    return (
        <div>
            <FieldArray
                name={`${fieldName}`}
                dollar_exchange={dollar_exchange}
                component={GenericTable}
                allValues={allValues}
                data_annual={data_annual}
            />
        </div>
    );
}

const formName = 'ShoppingFormAnnual';

const selector = formValueSelector(formName);

const mapStateToProps = (state) => {

    const categoryBudget = selector(state, 'category_budget') || [];
    const projections = selector(state, 'projections') || [];
    const allValues = categoryBudget.map(item => item);
    return { allValues, projections };

};

export default connect(mapStateToProps)(ShoppingTableAnnual);